import { PDFImagesView } from 'components/v2/images-upload/PDFImagesView';
import React from 'react';
import { EPDPDFModel } from 'types/types';

import { PDF_H1, PDF_H2, PDF_P, PDF_PAGE, PDF_Table } from './Styles.styled';

const ManufacturerInformationPage: React.FunctionComponent<{
  e: EPDPDFModel;
}> = ({ e }) => {
  return (
    <PDF_PAGE>
      <PDF_H1>INFORMATION ABOUT EPD OWNER</PDF_H1>
      <PDF_Table>
        <thead>
          <tr>
            <th style={{ width: '30%' }}></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>EPD Owner</td>
            <td>{e.organizationInformation?.name}</td>
          </tr>
          <tr>
            <td>Contact person name</td>
            <td>{e.organizationInformation?.contactPersonName}</td>
          </tr>
          <tr>
            <td>Contact person e-mail</td>
            <td>{e.organizationInformation?.contactPersonEmail}</td>
          </tr>
          <tr>
            <td>Organization address</td>
            <td>
              {e.countries?.find((country: any) => country.id === e.organizationInformation?.address?.countryId)?.name}
              <br />
              {e.organizationInformation?.address?.city}
              <br />
              {e.organizationInformation?.address?.zipCode}
              <br />
              {e.organizationInformation?.address?.addressLine}
              {e.organizationInformation?.address?.addressLineSecond && (
                <>
                  <br /> {e.organizationInformation?.address?.addressLineSecond}
                </>
              )}
            </td>
          </tr>
        </tbody>
      </PDF_Table>

      <PDF_H2>Description of the organisation of the EPD owner</PDF_H2>
      <PDF_P>{e.organizationInformation?.description}</PDF_P>
      <PDFImagesView images={e.organizationInformation?.organizationImages} />
    </PDF_PAGE>
  );
};

export default ManufacturerInformationPage;

import { Panel } from 'primereact/panel';
import { useTranslation } from 'react-i18next';
import { EPDStatus, VersionStateType } from 'services/EpdClient';
import styled from 'styled-components';
import { ExpandablePanelContainer } from 'styles/v2/Styles.styled';

type TProps = {
  status?: EPDStatus;
  statusMessage?: string;
  requiredActionMessage?: string;
};

const StatusBox = ({ status, statusMessage, requiredActionMessage }: TProps) => {
  const { t } = useTranslation();

  //ToDo: implement new statuses for EPD
  // const getEPDVersionStatus = (status: VersionStateType | undefined) => {
  //   {
  //     switch (status) {
  //       case VersionStateType.Draft:
  //         return 'Reserved';
  //       case VersionStateType.DeclinedVerification:
  //         return 'Declined';
  //       case VersionStateType.UnderReview:
  //         return 'Sent for verification';
  //       case VersionStateType.LicenseeAdminVerification:
  //       case VersionStateType.RegisteredEditorial:
  //         return 'Approved';
  //       default:
  //         return status;
  //     }
  //   }
  // };

  const headerTemplate = (options: any, status: EPDStatus | undefined) => {
    const toggleIcon = options.collapsed ? 'pi pi-chevron-down' : 'pi pi-chevron-up';
    const className = `${options.className} justify-content-space-between w-full`;

    return (
      <div
        className={className}
        style={{
          borderRadius: options.collapsed ? '4px' : undefined,
          paddingRight: 0,
          height: '3rem',
        }}
      >
        <StatusLabel style={options.collapsed ? { whiteSpace: 'nowrap' } : {}}>EPD status: {status}</StatusLabel>
        <button className={options.togglerClassName} onClick={options.onTogglerClick} style={{ margin: '0 0.5rem' }}>
          <span className={toggleIcon} style={{ fontSize: '0.75rem', margin: '0.25rem', fontWeight: 600 }}></span>
        </button>
      </div>
    );
  };

  return (
    <ExpandablePanelContainer style={{ boxShadow: 'none' }}>
      <StatusBoxPanel headerTemplate={(options) => headerTemplate(options, status)} toggleable collapsed>
        <div style={{ marginTop: '-2rem' }}>
          <p>{t(`epdWizard.epdState.statusMessage.${statusMessage || 'status0'}`)}</p>
          <StatusLabel>{t('epdWizard.epdState.action.title')}</StatusLabel>
          <p>{t(`epdWizard.epdState.actionMessage.${requiredActionMessage || 'requiredAction0'}`)}</p>
        </div>
      </StatusBoxPanel>
    </ExpandablePanelContainer>
  );
};

const StatusLabel = styled.label`
  flex-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 185px;
  padding: 8px 0;
`;

const StatusBoxPanel = styled(Panel)`
  border-radius: 4px;
  border: 0;
  color: ${(props) => props.theme.colors.primaryBlack70};
  ${StatusLabel} {
    ${(props) => props.theme.typography.headerCaptionSemiBold};
    color: ${(props) => props.theme.colors.primaryGreen};
  }

  .p-panel-header {
    border: 0;
    background-color: ${(props) => props.theme.colors.secondaryLightGreen30};
  }
  .p-panel-content {
    border: 0;
    background-color: ${(props) => props.theme.colors.secondaryLightGreen30};
    ${(props) => props.theme.typography.bodyInput};
  }

  p {
    margin-top: 4px;
  }
`;

export default StatusBox;

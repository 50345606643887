import ModuleGridPdf from 'components/v2/compiler/grids/scenarios/ModuleGridPdf';
import React from 'react';
import { useEpdScenarios, useEpdSystemBoundaryGrid } from 'services/api/queries';
import { ScenarioItemModel } from 'types/types';
import { PDF_H1, PDF_PAGE, PDF_Table } from './Styles.styled';

const ScenariosPage: React.FunctionComponent<{
  e: any;
}> = ({ e }) => {
  const epdVersionId = e.generalInformation.epdVersionId;
  const scenarios = useEpdScenarios(epdVersionId).data || [];
  const declaredModules = useEpdSystemBoundaryGrid(epdVersionId).data;

  return (
    <PDF_PAGE>
      {scenarios.map((scenario: ScenarioItemModel, index: number) => (
          <React.Fragment key={index}>
            {scenario.isDefaultScenario && (
              <>
                <PDF_H1>Scenarios</PDF_H1>
                <PDF_Table>
                  <thead>
                    <tr>
                      <th style={{ width: '30%' }}></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Name of the default scenario</td>
                      <td>{scenario.name}</td>
                    </tr>
                    <tr>
                      <td>Description of the default scenario</td>
                      <td>{scenario.description}</td>
                    </tr>
                  </tbody>
                </PDF_Table>
              </>
            )}
            {!scenario.isDefaultScenario && (
              <>
                <PDF_H1 style={{ pageBreakBefore: 'always' }}>Additional Scenarios</PDF_H1>
                <br />
                <PDF_Table>
                  <thead>
                    <tr>
                      <th style={{ width: '30%' }}></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Name of the additional scenario</td>
                      <td>{scenario.name}</td>
                    </tr>
                    <tr>
                      <td>Description of the additional scenario</td>
                      <td>{scenario.name}</td>
                    </tr>
                    <tr>
                      <td>Alternative functional unit</td>
                      <td>{e.organizationInformation?.contactPersonName}</td>
                    </tr>
                  </tbody>
                </PDF_Table>
              </>
            )}
            {declaredModules?.declaredModules?.a4 && (
              <>
                <br />
                <br />
                <PDF_H1>Module A4: Transport to the building site</PDF_H1>
                <br />
                <ModuleGridPdf
                  epdVersionId={epdVersionId}
                  scenarioId={scenario?.id}
                  module="A4"
                  moduleName="Module A4 information"
                />
              </>
            )}
            {declaredModules?.declaredModules.a5 && (
              <>
                <br />
                <br />
                <PDF_H1>Module A5: Installation in the building</PDF_H1>
                <br />
                <ModuleGridPdf
                  epdVersionId={epdVersionId}
                  scenarioId={scenario?.id}
                  module="A5"
                  moduleName="Module A5 information"
                />
              </>
            )}
            {declaredModules?.declaredModules.b1 && (
              <>
                <PDF_H1 style={{ pageBreakBefore: 'always' }}>Module B1: Use</PDF_H1>
                <br />
                <ModuleGridPdf
                  epdVersionId={epdVersionId}
                  scenarioId={scenario?.id}
                  module="B1"
                  moduleName="Module B1 information"
                />
              </>
            )}
            {declaredModules?.declaredModules.b2 && (
              <>
                <br />
                <br />
                <PDF_H1>Module B2: Maintenance</PDF_H1>
                <br />
                <ModuleGridPdf
                  epdVersionId={epdVersionId}
                  scenarioId={scenario?.id}
                  module="B2"
                  moduleName="Module B2 information"
                />
              </>
            )}
            {declaredModules?.declaredModules.b3 && (
              <>
                <br />
                <br />
                <PDF_H1>Module B3: Repair</PDF_H1>
                <br />
                <ModuleGridPdf
                  epdVersionId={epdVersionId}
                  scenarioId={scenario?.id}
                  module="B3"
                  moduleName="Module B3 information"
                />
              </>
            )}
            {declaredModules?.declaredModules.b4 && (
              <>
                <PDF_H1 style={{ pageBreakBefore: 'always' }}>Module B4: Replacement</PDF_H1>
                <br />
                <ModuleGridPdf
                  epdVersionId={epdVersionId}
                  scenarioId={scenario?.id}
                  module="B4"
                  moduleName="Module B4 information"
                />
              </>
            )}
            {declaredModules?.declaredModules.b5 && (
              <>
                <br />
                <br />
                <PDF_H1>Module B5: Refurbishment</PDF_H1>
                <br />
                <ModuleGridPdf
                  epdVersionId={epdVersionId}
                  scenarioId={scenario?.id}
                  module="B5"
                  moduleName="Module B5 information"
                />
              </>
            )}
            {scenario?.enabledRSL && (
              <>
                <PDF_H1 style={{ pageBreakBefore: 'always' }}>Reference service life</PDF_H1>
                <br />
                <ModuleGridPdf
                  epdVersionId={epdVersionId}
                  scenarioId={scenario?.id}
                  module="RSL"
                  moduleName="Reference service life information"
                />
              </>
            )}
            {declaredModules?.declaredModules.b6 && (
              <>
                <br />
                <br />
                <PDF_H1>Module B6: Operational energy use</PDF_H1>
                <br />
                <ModuleGridPdf
                  epdVersionId={epdVersionId}
                  scenarioId={scenario?.id}
                  module="B6"
                  moduleName="Module B6 information"
                />
              </>
            )}
            {declaredModules?.declaredModules.b7 && (
              <>
                <PDF_H1 style={{ pageBreakBefore: 'always' }}>Module B7: Operational water use</PDF_H1>
                <br />
                <ModuleGridPdf
                  epdVersionId={epdVersionId}
                  scenarioId={scenario?.id}
                  module="B7"
                  moduleName="Module B7 information"
                />
              </>
            )}
            {(declaredModules?.declaredModules.c1 ||
              declaredModules?.declaredModules.c2 ||
              declaredModules?.declaredModules.c3 ||
              declaredModules?.declaredModules.c4) && (
              <>
                <br />
                <br />
                <PDF_H1>Module C: End-of-life</PDF_H1>
                <br />
                <ModuleGridPdf
                  epdVersionId={epdVersionId}
                  scenarioId={scenario?.id}
                  module="C"
                  moduleName="Module C information"
                />
              </>
            )}
            {declaredModules?.declaredModules?.d && (
              <>
                <br />
                <br />
                <PDF_H1>Module D: Beyond product life cycle</PDF_H1>
                <br />
                <ModuleGridPdf
                  epdVersionId={epdVersionId}
                  scenarioId={scenario?.id}
                  module="D"
                  moduleName="Module D information"
                />
              </>
            )}
          </React.Fragment>
      ))}
    </PDF_PAGE>
  );
};

export default ScenariosPage;

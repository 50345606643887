import { DataTableValueArray } from 'primereact/datatable';

import LcaResultsGridPdf from './LcaResultsGridPdf';
import { LcaResultsGridColumnsNoCategoryPdf } from './LcaResultsGridColumns';

type TProps = {
  rows: DataTableValueArray | undefined;
};

const LcaResultsGridWasteConstructionPdf = ({ rows }: TProps) => {
  const notes = [
    {
      note: 'Acronyms',
      description: 'HWD = Hazardous waste disposed; NHWD = Non-hazardous waste disposed; RWD = Radioactive waste disposed.',
    },
  ];

  return <LcaResultsGridPdf value={rows} columns={LcaResultsGridColumnsNoCategoryPdf} notes={notes} />;
};

export default LcaResultsGridWasteConstructionPdf;

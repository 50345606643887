import { Column } from 'primereact/column';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { DataTableStyled, PlaceHolder } from '../StyledGrid';
import { ElectricityEnum } from 'services/EpdClient';
import { useElectricity } from 'services/api/queries';
import { ElectricityDataModel, EnergySourceModel } from 'types/types';
import { HeaderCaptionSemiBold } from 'styles/v2/Styles.styled';

type TRow = {
  key: number;
  field: ElectricityEnum;
  fieldName: string;
  source: string | undefined;
  value: string | number | EnergySourceModel[] | null | undefined;
};

type TProps = {
  epdVersionId: string;
};

const ElectricityGridPdf = ({ epdVersionId }: TProps) => {
  const electricityTable = useRef<any>(null);
  const { t } = useTranslation();

  const electricityData = useElectricity(epdVersionId).data;

  // ToDo: refactor this.
  // Primereact Datatable doesn't support both rowspan and colspan column at the same time for content rows.
  // Rowspan is configured in Datatable, that why colspan is set on every rendering of the Electricity table
  useEffect(() => {
    if (electricityTable.current) {
      const table = electricityTable.current.getTable();
      const tbody = table.getElementsByTagName('tbody');
      const rows = tbody[0].getElementsByTagName('tr');
      if (rows.length === 13) {
        setColSpan(rows, 0);
        setColSpan(rows, rows.length - 1);
      }
    }
  });

  const setColSpan = (rows: any, rowIndex: number) => {
    const cells = rows[rowIndex].getElementsByTagName('td');
    cells[1].style = 'display: none';
    cells[cells.length - 1].colSpan = 2;
  };

  const getColumnsData = (data: ElectricityDataModel | undefined) => {
    let index = 0;
    const columns = [
      {
        key: index,
        field: ElectricityEnum.ElectricityMix,
        fieldName: 'Electricity mix',
        value: data?.electricityMix,
      },
    ] as TRow[];

    electricityData?.energySourceModel?.forEach((item) => {
      columns.push({
        key: ++index,
        field: ElectricityEnum.EnergySource,
        fieldName: 'Energy sources',
        source: t(`epdEnergySource.${item.source}`),
        value: item.value?.toString(),
      });
    });

    columns.push({
      key: ++index,
      field: ElectricityEnum.ClimateImpact,
      fieldName: 'Climate impact (GWP-GHG):',
      value: data?.climateImpact,
    } as TRow);

    return columns;
  };


  const header = (
    <div className="flex flex-wrap align-items-center justify-content-between gap-2">
      <HeaderCaptionSemiBold className="white-space-nowrap">
        Electricity used in the manufacturing process in A3
      </HeaderCaptionSemiBold>
    </div>
  );

  const valueBodyTemplate = (rowData: TRow) => {
    switch (rowData.field) {
      case ElectricityEnum.EnergySource:
        const sourceValue = rowData.value as number | undefined;
        return sourceValue ? `${sourceValue}%` : <PlaceHolder>Type value %</PlaceHolder>;
      case ElectricityEnum.ClimateImpact:
        const numberValue = rowData.value as number | undefined;
        return numberValue ? `${numberValue} kg CO2 eq./kW` : <PlaceHolder>Type value, kg CO2 eq./kW</PlaceHolder>;
      default:
        const stringValue = rowData.value as string | undefined;
        return stringValue && stringValue?.length > 0 ? stringValue : <PlaceHolder>Type value</PlaceHolder>;
    }
  };

  return (
    <DataTableStyled
      header={header}
      value={getColumnsData(electricityData)}
      dataKey="key"
      showGridlines
      rowGroupMode="rowspan"
      groupRowsBy="fieldName"
      style={{ marginTop: '16px' }}
      ref={electricityTable}
    >
      <Column field="fieldName" headerStyle={{ display: 'none' }} style={{ verticalAlign: 'top' }} />
      <Column field="source" headerStyle={{ display: 'none' }} style={{ width: '36%' }} />
      <Column
        field="value"
        body={valueBodyTemplate}
        headerStyle={{ display: 'none' }}
        style={{ width: '36%' }}
      />
    </DataTableStyled>
  );
};

export default ElectricityGridPdf;

import React from 'react';
import { EPDModel } from 'services/EpdClient';
import { useUpdateEpdGeneralInformation } from 'services/api/mutations';
import { useEpdProductInformation } from 'services/api/queries';
import styled from 'styled-components';
import {
  ExpandablePanel,
  ExpandablePanelContainer,
  ExpandablePanelHeaderName,
  FieldPanelFullWidth,
  HeaderCaptionSemiBold,
  StyledInput,
} from 'styles/v2/Styles.styled';
import { EPDGeneralInformationModel, EPDProductInformationModel, Option } from 'types/types';

import CompilerSelectComponent from '../epd-compiler-inputs/CompilerSelectComponent';
import WizardTextAreaComponent from '../epd-wizard-inputs/WizardTextArea';
import WizardTextInputComponent from '../epd-wizard-inputs/WizardTextInput';
import TooltipHelpIcon from '../icons/TooltipHelpIcon';
import BestProductComponentsGrid from './grids/product-content/BestProductComponentsGrid';
import BestProductPackagingMaterialsGrid from './grids/product-content/BestProductPackagingMaterialsGrid';
import BestProductPanelContentGrid from './grids/product-content/BestProductPanelContentGrid';
import DangerMaterialsGrid from './grids/product-content/DangerMaterialsGrid';
import PackagingMaterialsGrid from './grids/product-content/PackagingMaterialsGrid';
import PanelContentGrid from './grids/product-content/PanelContentGrid';
import ProductComponentsGrid from './grids/product-content/ProductComponentsGrid';

const ContentDeclarationTab: React.FunctionComponent<{
  epd?: EPDModel;
  epdVersionId: string;
  generalInformation: EPDGeneralInformationModel;
  validationState: any;
}> = ({ epd, epdVersionId, generalInformation, validationState }) => {
  const hasDangerMaterialsLookup = [
    { value: false, label: 'No' },
    { value: true, label: 'Yes' },
  ];

  const { errors, fieldsState } = validationState || {};
  const updateMutation = useUpdateEpdGeneralInformation(epdVersionId);
  const productInformation = useEpdProductInformation(epdVersionId).data;
  const isPanelContentEnabled = epd?.cpcr?.name?.includes('c-PCR-014');

  const productsLookup =
    productInformation?.map((product: EPDProductInformationModel) => {
      return {
        value: product.id,
        label: product.productName,
      } as Option;
    }) || [];

  const selectedBestProduct = productsLookup.find((x) => x.value === generalInformation?.bestProductId);

  const hasDangerMaterials = hasDangerMaterialsLookup.find((x) => x.value == generalInformation?.hasDangerMaterials);

  const onChangeEpdGeneralInformation = async (propertyName: string, val: any) => {
    updateMutation.mutate({ epdId: epd?.id, versionId: epdVersionId, propertyName: propertyName, newValue: val });
  };

  const headerTemplate = (options: any, header: string, tooltip?: string, tooltipPosition?: any | undefined) => {
    const toggleIcon = options.collapsed ? 'pi pi-chevron-down' : 'pi pi-chevron-up';

    return (
      <div className={options.className}>
        <ExpandablePanelHeaderName>
          <HeaderCaptionSemiBold>{header}</HeaderCaptionSemiBold>
          {tooltip && <TooltipHelpIcon content={tooltip} position={tooltipPosition} />}
          <button className={options.togglerClassName} onClick={options.onTogglerClick} style={{ margin: '0 0.5rem' }}>
            <span className={toggleIcon}></span>
          </button>
        </ExpandablePanelHeaderName>
      </div>
    );
  };

  return (
    <>
      <FieldPanelFullWidth>
        {generalInformation?.epdClassification && generalInformation?.epdClassification !== 1 && (
          <WizardTextAreaComponent
            label="Content declaration of multiple products"
            name="multipleProductsContentDeclaration"
            value={generalInformation?.multipleProductsContentDeclaration}
            onChanged={onChangeEpdGeneralInformation}
            placeholder="Type here"
            required={true}
            state={fieldsState}
            tooltip="In EPDs of multiple products or sector EPDs, it shall be described in the content declaration section what the content declaration represents."
          />
        )}
        <ProductComponentsGrid epdVersionId={epd?.versionId!} errors={errors?.productComponents} />
        <PackagingMaterialsGrid epdVersionId={epd?.versionId!} errors={errors?.packagingMaterials} />
        {isPanelContentEnabled && <PanelContentGrid epdVersionId={epd?.versionId!} />}
      </FieldPanelFullWidth>
      <FieldPanelFullWidth>
        <CompilerSelectComponent
          label="The product contains substances in the list of SVHC that constitute more than 0.1% of its weight"
          tooltip="The product contains substances in the list of SVHC that constitute more than 0.1% of its weight"
          name="hasDangerMaterials"
          options={hasDangerMaterialsLookup}
          value={hasDangerMaterialsLookup.find((x) => x.value == generalInformation?.hasDangerMaterials)}
          onChanged={onChangeEpdGeneralInformation}
          placeholder="Select..."
          required={true}
          error={errors?.generalInformation?.hasDangerMaterials}
        />
        {generalInformation.hasDangerMaterials && (
          <DangerMaterialsGrid epdVersionId={epd?.versionId!} errors={errors?.dangerMaterials} />
        )}
      </FieldPanelFullWidth>
      {generalInformation?.epdClassification && generalInformation?.epdClassification !== 1 && (
        <ExpandablePanelMarginContainer>
          <ExpandablePanelMultiContainer
            headerTemplate={(options) => headerTemplate(options, 'Content declaration of scenario “best-case product”')}
            toggleable
            collapsed
          >
            <FieldPanelFullWidth>
              <CompilerSelectComponent
                label="Best-case product"
                tooltip="The “best-case product” is defined as the product with the lowest GWP-GHG results."
                name="bestProduct"
                options={productsLookup}
                value={selectedBestProduct}
                onChanged={onChangeEpdGeneralInformation}
                placeholder="Select..."
                isClearable
              />
            </FieldPanelFullWidth>
            {selectedBestProduct && (
              <FieldPanelFullWidth>
                <BestProductComponentsGrid epdVersionId={epd?.versionId!} productId={selectedBestProduct.value} />
                <BestProductPackagingMaterialsGrid epdVersionId={epd?.versionId!} productId={selectedBestProduct.value} />
                {isPanelContentEnabled && (
                  <BestProductPanelContentGrid epdVersionId={epd?.versionId!} productId={selectedBestProduct.value} />
                )}
                <WizardTextInputComponent
                  label="The product contains substances in the list of SVHC that constitute more than 0.1% of its weight"
                  tooltip="The product contains substances in the list of SVHC that constitute more than 0.1% of its weight"
                  name="hasDangerMaterialsContainBestProduct"
                  disabled
                  value={hasDangerMaterials?.label}
                />
                {generalInformation.hasDangerMaterials && (
                  <DangerMaterialsGrid epdVersionId={epd?.versionId!} errors={errors} />
                )}
              </FieldPanelFullWidth>
            )}
          </ExpandablePanelMultiContainer>
        </ExpandablePanelMarginContainer>
      )}
    </>
  );
};

const ExpandablePanelMarginContainer = styled(ExpandablePanelContainer)`
  box-shadow: none;
`;

const ExpandablePanelMultiContainer = styled(ExpandablePanel)`
  .p-panel-content {
    padding: 0;
    border: 0;
    background-color: transparent;
    width: 100%;
    display: grid;
  }
  ${StyledInput} {
    color: ${(props) => props.theme.colors.primaryBlack};
  }
  && textarea {
    color: ${(props) => props.theme.colors.primaryBlack};
  }
`;

export default ContentDeclarationTab;

import { Column, ColumnEvent } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import React, { useState } from 'react';
import {
  useCreateEpdProductPanelContent,
  useDeleteProductPanelContent,
  useUpdateEpdProductPanelContent,
} from 'services/api/mutations';
import { useEpdBestProductPanelContent } from 'services/api/queries';
import { HeaderCaptionSemiBold } from 'styles/v2/Styles.styled';
import { ProductPanelContent } from 'types/types';

import { cellNumberEditor, cellTextEditor } from '../GridCellEditors';
import { DataTableStyled, GridButton } from '../StyledGrid';

const BestProductPanelContentGrid: React.FunctionComponent<{ epdVersionId: string; productId: string | null }> = ({
  epdVersionId,
  productId,
}) => {
  const [selectedRows, setSelectedRows] = useState<ProductPanelContent[] | null>(null);

  const rows = useEpdBestProductPanelContent(epdVersionId, productId).data;
  const createMutation = useCreateEpdProductPanelContent(productId);
  const updateMutation = useUpdateEpdProductPanelContent(epdVersionId);
  const deleteMutation = useDeleteProductPanelContent(productId);

  const handleAddRow = () => {
    createMutation.mutate({ epdVersionId, weight: 0, productId: productId });
  };

  const handleDeleteSelected = async () => {
    if (!selectedRows) return;
    await Promise.all(selectedRows.map((x) => deleteMutation.mutateAsync(x.id!)));
    setSelectedRows(null);
  };

  const header = (
    <div className="flex flex-wrap align-items-center justify-content-between gap-2">
      <HeaderCaptionSemiBold className="white-space-nowrap">Panel content</HeaderCaptionSemiBold>
      <span>
        <GridButton title="Add" icon="pi pi-plus" onClick={handleAddRow} />{' '}
        <GridButton
          title="Delete"
          icon="pi pi-trash"
          onClick={handleDeleteSelected}
          disabled={!selectedRows || selectedRows.length === 0 || deleteMutation.isPending}
        />
      </span>
    </div>
  );

  const columnTotal = (columnName: keyof ProductPanelContent) => {
    return () =>
      rows?.reduce((a, b: ProductPanelContent) => {
        return a + (b[columnName] as number);
      }, 0);
  };

  const footerGroup = (
    <ColumnGroup>
      <Row>
        <Column className="p-row-total" />
        <Column className="p-row-total" footer="Total" />
        <Column className="p-row-total" footer={columnTotal('weight')} />
        <Column className="p-row-total" footer={columnTotal('weightPercent')} />
        <Column className="p-row-total" footer={columnTotal('biogenicCarbonWeight')} />
      </Row>
      <Row style={{ borderTop: 'none' }}>
        <Column className="p-row-note" />
        <Column
          className="p-row-note"
          footer={
            <div>
              Note<sup>1</sup>
            </div>
          }
        />
        <Column className="p-row-note" footer={'1 kg biogenic carbon is equivalent to 44/12 kg of CO2'} colSpan={3} />
      </Row>
    </ColumnGroup>
  );

  const onCellEditComplete = (e: ColumnEvent) => {
    let { rowData, newValue, field, originalEvent: event } = e;
    rowData[field] = newValue;
    updateMutation.mutate(rowData);
  };

  return (
    <DataTableStyled
      header={header}
      footerColumnGroup={footerGroup}
      value={rows}
      selectionMode="checkbox"
      selection={selectedRows!}
      onSelectionChange={(e) => setSelectedRows(e.value as ProductPanelContent[])}
      dataKey="id"
      tableStyle={{ minWidth: '49rem' }}
      showGridlines
      editMode="cell"
    >
      <Column selectionMode="multiple" headerStyle={{ width: '3rem' }} />
      <Column
        field="name"
        header="Material name"
        editor={(options) => cellTextEditor(options)}
        onCellEditComplete={onCellEditComplete}
      />
      <Column
        field="weight"
        header="Weight, kg"
        editor={(options) => cellNumberEditor(options)}
        onCellEditComplete={onCellEditComplete}
      />
      <Column
        field="weightPercent"
        header="Weight-% (versus the product)"
        editor={(options) => cellNumberEditor(options)}
        onCellEditComplete={onCellEditComplete}
      />
      <Column
        field="biogenicCarbonWeight"
        header={
          <span>
            Biogenic material<sup>1</sup>, kg C/declared unit or product<sup>2</sup>
          </span>
        }
        editor={(options) => cellNumberEditor(options)}
        onCellEditComplete={onCellEditComplete}
      ></Column>
    </DataTableStyled>
  );
};

export default BestProductPanelContentGrid;

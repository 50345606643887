import React, { useState } from 'react';
import { EPDModel } from 'services/EpdClient';
import { FieldPanelFullWidth, FieldPanelHeader } from 'styles/v2/Styles.styled';
import { EPDGeneralInformationModel } from 'types/types';

import { HelpBox } from '../help-boxes';

const Step6: React.FunctionComponent<{
  epd: EPDModel;
  generalInformation: EPDGeneralInformationModel;
  onChangeEpd: any;
}> = ({ epd, generalInformation, onChangeEpd }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <FieldPanelFullWidth style={{ marginTop: 0 }}>
      <FieldPanelHeader>Generated EPD</FieldPanelHeader>
      <HelpBox style={{ marginTop: '16px' }}>
        After the review and publication will be completed by the Secretariat, you will be able to see and download the
        generated EPD document in PDF format in this section.
      </HelpBox>
    </FieldPanelFullWidth>
  );
};

export default Step6;

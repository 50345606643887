import { DataTableValueArray } from 'primereact/datatable';

import LcaResultsGrid from './LcaResultsGrid';
import { LcaResultsGridColumnsNoCategory } from './LcaResultsGridColumns';

type TProps = {
  rows: DataTableValueArray | undefined;
};

const LcaResultsGridWasteConstruction = ({ rows }: TProps) => {
  const notes = [
    {
      note: 'Acronyms',
      description: 'HWD = Hazardous waste disposed; NHWD = Non-hazardous waste disposed; RWD = Radioactive waste disposed.',
    },
  ];

  return <LcaResultsGrid value={rows} columns={LcaResultsGridColumnsNoCategory} notes={notes} />;
};

export default LcaResultsGridWasteConstruction;

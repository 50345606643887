import React from 'react'
import { PDF_H1, PDF_P, PDF_PAGE } from './Styles.styled';

const ReferencePage : React.FunctionComponent<{
    e: any;
  }> = ({ e }) => {
  return (
    <PDF_PAGE>
      <PDF_H1>References</PDF_H1>
      <PDF_P dangerouslySetInnerHTML={{ __html: e.additionalInformation?.references ?? '' }} />
    </PDF_PAGE>
  )
}

export default ReferencePage

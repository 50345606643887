import { PDFImagesView } from 'components/v2/images-upload/PDFImagesView';
import React from 'react';
import { useEpdSystemBoundaryData } from 'services/api/queries';

import { PDF_H1, PDF_P, PDF_PAGE } from './Styles.styled';

const ProcessFlowDescriptionPage: React.FunctionComponent<{
  e: any;
}> = ({ e }) => {
  const boundaryData = useEpdSystemBoundaryData(e.generalInformation.epdVersionId).data;
  return (
    <PDF_PAGE>
      <PDF_H1>Description of the process flow </PDF_H1>
      <PDF_P>{boundaryData?.processFlowDescription}</PDF_P>
      {boundaryData?.processFlowImages && boundaryData?.processFlowImages.length > 0 && (
        <PDFImagesView images={boundaryData.processFlowImages} />
      )}
    </PDF_PAGE>
  );
};

export default ProcessFlowDescriptionPage;

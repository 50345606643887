import { Column, ColumnBodyOptions, ColumnEvent } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import React, { useState } from 'react';
import {
  useCreateEpdProductComponent,
  useDeleteProductComponent,
  useUpdateEpdProductComponent,
} from 'services/api/mutations';
import { useEpdProductComponents } from 'services/api/queries';
import { ProductComponent } from 'types/types';

import { cellNumberEditor, cellTextEditor } from '../GridCellEditors';
import { cellTotalTemplate, headerTemplate, mandatoryBodyTemplate, onCellEdit, validatedCellTemplate } from '../GridService';
import { DataTableStyled } from '../StyledGrid';

const ProductComponentsGrid: React.FunctionComponent<{ epdVersionId: string; errors?: any }> = ({
  epdVersionId,
  errors,
}) => {
  const [selectedRows, setSelectedRows] = useState<ProductComponent[] | null>(null);

  const rows = useEpdProductComponents(epdVersionId!).data;
  const createMutation = useCreateEpdProductComponent(null);
  const updateMutation = useUpdateEpdProductComponent(epdVersionId);
  const deleteMutation = useDeleteProductComponent(null);

  const handleAddRow = () => {
    createMutation.mutate({ epdVersionId, weight: 0 });
  };

  const handleDeleteSelected = async () => {
    if (!selectedRows) return;
    await Promise.all(selectedRows.map((x) => deleteMutation.mutateAsync(x.id!)));
    setSelectedRows(null);
  };

  const onCellEditComplete = (e: ColumnEvent) => onCellEdit(e, updateMutation);

  const cellTemplate = (value: ProductComponent, options: ColumnBodyOptions) =>
    validatedCellTemplate((value as any)?.[options?.field], errors?.[`row[${options?.rowIndex}]`]?.[options?.field]);

  const mandatoryCellTemplate = (value: ProductComponent, options: ColumnBodyOptions) =>
    mandatoryBodyTemplate((value as any)?.[options?.field], errors?.[`row[${options?.rowIndex}]`]?.[options?.field]);

  const isTableFilledIn = rows?.every((r) => r.weight) && rows?.every((r) => r.name);

  const isTableDisabled = !selectedRows?.length || deleteMutation.isPending;

  const header = headerTemplate(
    isTableFilledIn,
    'Product content',
    handleAddRow,
    handleDeleteSelected,
    isTableDisabled,
    errors?.general || (errors && Object.keys(errors).length > 0 && 'Has errors')
  );

  const footerGroup = (
    <ColumnGroup>
      <Row>
        <Column className="p-row-total" />
        <Column className="p-row-total" footer="Total" />
        <Column className="p-row-total" footer={cellTotalTemplate('weight', rows, errors)} />
        <Column className="p-row-total" footer={cellTotalTemplate('finalWeightPercent', rows, errors)} />
        <Column className="p-row-total" footer={cellTotalTemplate('biogenicWeightPercent', rows, errors)} />
        <Column className="p-row-total" footer={cellTotalTemplate('biogenicCarbonWeight', rows, errors)} />
      </Row>
      <Row style={{ borderTop: 'none' }}>
        <Column className="p-row-note" />
        <Column
          className="p-row-note"
          footer={
            <div>
              Note<sup>1</sup>
            </div>
          }
        />
        <Column className="p-row-note" footer={'1 kg biogenic carbon is equivalent to 44/12 kg of CO2'} colSpan={4} />
      </Row>
      <Row style={{ borderTop: 'none' }}>
        <Column className="p-row-note" />
        <Column
          className="p-row-note"
          footer={
            <div>
              Note<sup>2</sup>
            </div>
          }
        />
        <Column
          className="p-row-note"
          footer={
            <div>
              The value will be changed accordingly to the selected unit in Step 3:
              <br /> Option 1: if choosing declared unit - Biogenic material<sup>1</sup>, <b>kg C/declared unit</b>
              <br /> Option 2: if choosing functional unit - Biogenic material<sup>2</sup>, <b>kg C/product</b>
            </div>
          }
          colSpan={4}
        />
      </Row>
    </ColumnGroup>
  );

  return (
    <DataTableStyled
      header={header}
      footerColumnGroup={footerGroup}
      value={rows}
      selectionMode="checkbox"
      selection={selectedRows!}
      onSelectionChange={(e) => setSelectedRows(e.value as ProductComponent[])}
      dataKey="id"
      tableStyle={{ minWidth: '49rem' }}
      showGridlines
      editMode="cell"
    >
      <Column selectionMode="multiple" headerStyle={{ width: '3rem' }} />
      <Column
        field="name"
        header="Content name"
        editor={(options) => cellTextEditor(options)}
        onCellEditComplete={onCellEditComplete}
        body={mandatoryCellTemplate}
      />
      <Column
        field="weight"
        header="Weight, kg"
        editor={(options) => cellNumberEditor(options)}
        onCellEditComplete={onCellEditComplete}
        body={mandatoryCellTemplate}
      />
      <Column
        field="finalWeightPercent"
        header="Post-consumer recycled material, weight-% of product"
        editor={(options) => cellNumberEditor(options)}
        onCellEditComplete={onCellEditComplete}
        body={cellTemplate}
      />
      <Column
        field="biogenicWeightPercent"
        header="Biogenic material, weight-% of product"
        editor={(options) => cellNumberEditor(options)}
        onCellEditComplete={onCellEditComplete}
        body={cellTemplate}
      />
      <Column
        field="biogenicCarbonWeight"
        header={
          <span>
            Biogenic material<sup>1</sup>, kg C/declared unit or product<sup>2</sup>
          </span>
        }
        editor={(options) => cellNumberEditor(options)}
        onCellEditComplete={onCellEditComplete}
      ></Column>
    </DataTableStyled>
  );
};

export default ProductComponentsGrid;

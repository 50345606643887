import { ReactComponent as AddImageIcon } from 'assets/images/icons/plus.svg';
import React from 'react';
import { EPDModel } from 'services/EpdClient';
import {
  useCopyProductInformation,
  useCreateEpdProductInformation,
  useDeleteProductInformation,
  useUpdateEpdProductInformation,
} from 'services/api/mutations';
import { useEpdProductInformation } from 'services/api/queries';
import styled from 'styled-components';
import {
  ExpandablePanel,
  ExpandablePanelContainer,
  ExpandablePanelHeaderName,
  HeaderCaptionSemiBold,
  IconsSection,
  StyledInput,
} from 'styles/v2/Styles.styled';
import { EPDProductInformationModel } from 'types/types';
import { StyledCopyIcon } from '../epd-compiler-inputs/common-icons';
import { IconButton } from '../buttons';
import { confirmDelete } from '../confirm-dialog/ConfirmDelete';
import ProductDataSection from './sections/productInformation/ProductDataSection';

const ProductInformationTab: React.FC<{
  epd?: EPDModel;
  epdVersionId: string;
  epdClassification: number | string | undefined;
  validationState: any;
}> = ({ epd, epdVersionId, epdClassification, validationState }) => {
  const productInformation = useEpdProductInformation(epdVersionId).data;
  const updateMutation = useUpdateEpdProductInformation(epdVersionId);
  const createMutation = useCreateEpdProductInformation();
  const deleteMutation = useDeleteProductInformation();
  const copyProductMutation = useCopyProductInformation();

  const confirmDeleteProduct = (product: EPDProductInformationModel) => {
    const productName = product.productName ?? 'Unnamed product';
    confirmDelete({
      caption: `Are you sure you want to delete product ${productName}?`,
      onConfirm: () => deleteMutation.mutate(product.id as string),
    });
  };

  const onChangeEpdProductInformation = async (propertyName: string, val: any, productId: string, versionId: string) => {
    updateMutation.mutate({ productId: productId, versionId: versionId, propertyName: propertyName, newValue: val });
  };

  const addProduct = () => {
    createMutation.mutate({ epdVersionId });
  };

  const handleCopyProduct = (productId: string | undefined) => {
    if (productId) {
      copyProductMutation.mutate({ epdVersionId, id: productId });
    }
  };

  const productErrors = (validationState: any, index: any) => {
    return validationState?.errors?.[`products[${index}]`];
  };

  const headerTemplate = (options: any, product: EPDProductInformationModel) => {
    const toggleIcon = options.collapsed ? 'pi pi-chevron-down' : 'pi pi-chevron-up';

    return (
      <div className={options.className}>
        <ExpandablePanelHeaderName>
          <HeaderCaptionSemiBold>{!product.productName ? `Unnamed product` : product.productName}</HeaderCaptionSemiBold>
          <button className={options.togglerClassName} onClick={options.onTogglerClick} style={{ margin: '0 0.5rem' }}>
            <span className={toggleIcon}></span>
          </button>
        </ExpandablePanelHeaderName>
        <IconsSection>
          <DeleteProductIconButton icon="pi pi-trash" text onClick={() => confirmDeleteProduct(product)} />
          <StyledCopyIcon onClick={() => handleCopyProduct(product.id)} />
        </IconsSection>
      </div>
    );
  };

  return (
    <>
      {epdClassification && epdClassification !== 1 ? (
        <>
          {productInformation?.map((product: EPDProductInformationModel, index: number) => (
            <ExpandablePanelMarginContainer key={product?.id}>
              <ExpandablePanelMultiContainer
                key={product?.id}
                headerTemplate={(options) => headerTemplate(options, product)}
                toggleable
                collapsed
              >
                <ProductDataSection
                  product={product}
                  epdId={epd?.id!}
                  epdVersionId={epdVersionId}
                  isReadOnly={false}
                  onChange={onChangeEpdProductInformation}
                  errors={productErrors(validationState, index)}
                />
              </ExpandablePanelMultiContainer>
            </ExpandablePanelMarginContainer>
          ))}
          <AddButtonBox onClick={addProduct}>
            <AddImageIcon style={{ marginRight: '0.625rem' }} />
            Add product
          </AddButtonBox>
        </>
      ) : (
        <ExpandablePanelMultiContainer>
          <ProductDataSection
            product={productInformation ? productInformation[0] : undefined}
            epdId={epd?.id!}
            epdVersionId={epdVersionId}
            isReadOnly={false}
            onChange={onChangeEpdProductInformation}
            errors={productErrors(validationState, 0)}
          />
        </ExpandablePanelMultiContainer>
      )}
    </>
  );
};

const AddButtonBox = styled.div`
  ${(props) => props.theme.typography.buttonButtonSmall}
  width: 100%;
  border-radius: 4px;
  padding: 20px;
  border: 1px dashed ${(props) => props.theme.colors.primaryOrange01};
  margin-top: 16px;
  justify-content: start;
  align-items: center;
  display: flex;
  max-height: 70px;
  min-height: 70px;
  background: ${(props) => props.theme.colors.primaryOrange01};
  color: ${(props) => props.theme.colors.primaryOrange};
  cursor: pointer;
`;

const ExpandablePanelMarginContainer = styled(ExpandablePanelContainer)`
  box-shadow: none;
`;

const ExpandablePanelMultiContainer = styled(ExpandablePanel)`
  .p-panel-content {
    padding: 0;
    border: 0;
    background-color: transparent;
    width: 100%;
    display: grid;
  }
  ${StyledInput} {
    color: ${(props) => props.theme.colors.primaryBlack};
  }
  && textarea {
    color: ${(props) => props.theme.colors.primaryBlack};
  }
`;

export const DeleteProductIconButton = styled(IconButton)`
  width: 16px !important;
  height: 16px !important;
  &.p-button.p-button-text {
    color: ${(props) => props.theme.colors.primaryBlack};
  }
  &.p-button.p-button-text:hover {
    background: transparent;
  }
`;

export default ProductInformationTab;

import { CompanyContext } from 'contexts/CompanyContextProvider';
import React, { useContext } from 'react';
import { EPDModel } from 'services/EpdClient';
import { useUpdateEpdAdditionalInformation } from 'services/api/mutations';
import { useEpdAdditionalInformation } from 'services/api/queries';
import { FieldContainer, FieldPanelFullWidth } from 'styles/v2/Styles.styled';

import WizardHTMLEditor from '../epd-wizard-inputs/WizardHTMLEditor';

const AdditionalInformationTab: React.FunctionComponent<{
  epd: EPDModel | any;
  epdVersionId: string;
  onChangeEpd: any;
  isReadOnly: boolean;
}> = ({ epd, epdVersionId, onChangeEpd }) => {
  const { companyId, company } = useContext(CompanyContext);

  const additionalInformation = useEpdAdditionalInformation(epdVersionId).data;

  const updateMutation = useUpdateEpdAdditionalInformation(epdVersionId);

  const onChangeEpdAdditionalInformation = async (propertyName: string, val: any) => {
    updateMutation.mutate({ epdId: epd?.id, versionId: epdVersionId, propertyName: propertyName, newValue: val });
  };

  return (
    <>
      {additionalInformation !== undefined && (
        <FieldPanelFullWidth>
          <FieldContainer>
            <WizardHTMLEditor
              name="additional.references"
              value={additionalInformation?.references}
              label="References"
              tooltip="A list of references shall be included, including references to the GPI (including version number) and relevant standards and PCRs (registration number, name and version)."
              tooltipPosition="right"
              onChanged={onChangeEpdAdditionalInformation}
              stripPastedStyles={true}
            />
          </FieldContainer>
          <FieldContainer>
            <WizardHTMLEditor
              name="additional.environmental"
              value={additionalInformation?.environmental}
              label="Additional environmental information"
              tooltip="An EPD may include additional environmental information not derived from the LCA. The additional environmental information may cover various aspects of specific relevance for the product."
              tooltipPosition="right"
              onChanged={onChangeEpdAdditionalInformation}
              stripPastedStyles={true}
            />
          </FieldContainer>
          <FieldContainer>
            <WizardHTMLEditor
              name="additional.conversion"
              value={additionalInformation?.conversion}
              label="Conversion factors"
              tooltip="Conversion factors may be included in an EPD for the purposes of converting the declared results of a product group to results for specific products within the group, or converting the declared results to results for another declared/functional unit. Conversion factors can, however, not be included for the purpose of converting the declared results into results for products not covered by the EPD."
              tooltipPosition="right"
              onChanged={onChangeEpdAdditionalInformation}
              stripPastedStyles={true}
            />
          </FieldContainer>
          <FieldContainer>
            <WizardHTMLEditor
              name="additional.dangerous"
              value={additionalInformation?.dangerous}
              label="Dangerous substance to indoor air, soil, and water during the use stage"
              tooltip="Emissions to indoor air, soil, and water according to the horizontal standards on measurement of release of regulated dangerous substances from construction products using harmonised testing methods according to the provisions of the respective Technical Committees for European product standards, when available."
              tooltipPosition="right"
              onChanged={onChangeEpdAdditionalInformation}
              stripPastedStyles={true}
            />
          </FieldContainer>
          <FieldContainer>
            <WizardHTMLEditor
              name="additional.economic"
              value={additionalInformation?.economic}
              label="Economic and social information"
              tooltip="The EPD may also include other relevant social and economic information as additional and voluntary information. This may be product information or a description of an organization's overall work on social or economic sustainability, such as activities related to supply chain management or social responsibility."
              tooltipPosition="right"
              onChanged={onChangeEpdAdditionalInformation}
              stripPastedStyles={true}
            />
          </FieldContainer>
        </FieldPanelFullWidth>
      )}
    </>
  );
};

export default AdditionalInformationTab;

import { DataTableValueArray } from 'primereact/datatable';

import LcaResultsGrid from './LcaResultsGrid';
import { LcaResultsGridColumns } from './LcaResultsGridColumns';

type TProps = {
  rows: DataTableValueArray | undefined;
};

const LcaResultsGridAdditionalConstruction = ({ rows }: TProps) => {
  const notes = [
    {
      note: 'Acronyms',
      description:
        'PM = Potential incidence of disease due to particulate matter emissions; IRP = Potential human exposure efficiency relative to U235; ETP-fw = Potential comparative toxic unit for ecosystems; HTP-c = Potential comparative toxic unit for humans; HTP-nc = Potential comparative toxic unit for humans; SQP = Potential soil quality index.',
    },
    {
      note: (
        <>
          Disclaimer<sup>1</sup>
        </>
      ),
      description:
        'This impact category deals mainly with the eventual impact of low dose ionizing radiation on human health of the nuclear fuel cycle. It does not consider effects due to possible nuclear accidents, occupational exposure nor due to radioactive waste disposal in underground facilities. Potential ionizing radiation from the soil, from radon and from some construction materials is also not measured by this indicator.',
    },
    {
      note: (
        <>
          Disclaimer<sup>2</sup>
        </>
      ),
      description:
        'The results of this environmental impact indicator shall be used with care as the uncertainties of these results are high or as there is limited experience with the indicator.',
    },
  ];

  return <LcaResultsGrid value={rows} columns={LcaResultsGridColumns} notes={notes} />;
};

export default LcaResultsGridAdditionalConstruction;

import SystemBoundaryGridPdf from 'components/v2/compiler/grids/system-boundary/SystemBoundaryGridPdf';
import React from 'react';
import { EPD_DICTIONARY_CODES } from 'services/api/constants';
import { useDictionaryValues, useEpdSystemBoundaryData } from 'services/api/queries';

import { PDF_H1, PDF_PAGE, PDF_Table } from './Styles.styled';

const SystemBoundaryPage: React.FunctionComponent<{
  e: any;
}> = ({ e }) => {
  const dictionariesQueryData = useDictionaryValues().data;
  const systemBoundaryDescriptionLookup =
    dictionariesQueryData!
      .filter((x) => x.dictionaryCode === EPD_DICTIONARY_CODES.SYSTEM_BOUNDARY_DESCRIPTION)
      .map((x) => {
        return {
          value: x.key,
          label: x.value,
        };
      }) || [];

  const systemBoundaryHasExcludedModulesLookup = [
    { value: true, label: 'Yes, there is the excluded module(s)' },
    { value: false, label: 'No, there is no excluded module(s)' },
  ];

  const boundaryData = useEpdSystemBoundaryData(e.generalInformation.epdVersionId).data;
  return (
    <PDF_PAGE className="landscape">
      <PDF_H1>System boundary</PDF_H1>
      <PDF_Table>
        <thead>
          <tr>
            <th style={{ width: '30%' }}></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Description of the System boundary</td>
            <td>{systemBoundaryDescriptionLookup.find((x: any) => x.value == boundaryData?.description)?.label}</td>
          </tr>
          <tr>
            <td>Excluded modules</td>
            <td>{systemBoundaryHasExcludedModulesLookup.find((x) => x.value == boundaryData?.hasExcludedModules)?.label}</td>
          </tr>
          {boundaryData?.hasExcludedModules && (
            <tr>
              <td>Justification for omission of modules</td>
              <td>{boundaryData?.excludedModulesJustification}</td>
            </tr>
          )}
        </tbody>
      </PDF_Table>
      <br />
      <SystemBoundaryGridPdf epdVersionId={e.generalInformation.epdVersionId} />
    </PDF_PAGE>
  );
};

export default SystemBoundaryPage;

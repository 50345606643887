import React from 'react';
import {
  ExpandablePanel,
  ExpandablePanelContainer,
  ExpandablePanelHeaderName,
  ExpandablePanelModule,
  FieldPanelFullWidth,
  FieldPanelInput,
  HeaderCaptionSemiBold,
  IconsSection,
  StyledInput,
} from 'styles/v2/Styles.styled';
import { ScenarioItemModel } from 'types/types';
import styled from 'styled-components';
import WizardTextAreaComponent from '../epd-wizard-inputs/WizardTextArea';
import WizardTextInputComponent from '../epd-wizard-inputs/WizardTextInput';
import ModuleGrid from './grids/scenarios/ModuleGrid';
import {
  useCopyEpdScenario,
  useCreateEpdScenario,
  useDeleteEpdScenario,
  useUpdateEpdScenario,
} from 'services/api/mutations';
import { useEpdScenarioUnits, useEpdScenarios, useEpdSystemBoundaryGrid } from 'services/api/queries';
import { ReactComponent as AddImageIcon } from 'assets/images/icons/plus.svg';
import { IconButton } from '../buttons';
import { confirmDelete } from '../confirm-dialog/ConfirmDelete';
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';
import SmartLabel from '../labels/SmartLabel';
import TooltipHelpIcon from '../icons/TooltipHelpIcon';
import { StyledCopyIcon } from '../epd-compiler-inputs/common-icons';
import TooltipErrorIcon from '../icons/TooltipErrorIcon';

const ScenariosTab: React.FunctionComponent<{
  epdVersionId: string;
  validationState?: any;
}> = ({ epdVersionId, validationState }) => {
  const scenarios = useEpdScenarios(epdVersionId!).data;
  const units = useEpdScenarioUnits().data;
  const declaredModules = useEpdSystemBoundaryGrid(epdVersionId!).data;
  const createScenarioMutation = useCreateEpdScenario();
  const updateScenarioMutation = useUpdateEpdScenario();
  const deleteScenarioMutation = useDeleteEpdScenario();
  const copyScenarioMutation = useCopyEpdScenario();
  const scenariosErrors = (index: number) => {
    return validationState?.errors?.scenarios?.[`row[${index}]`];
  };

  const handleScenarioUpdate = (field: string, value: string, id: string) => {
    const updateScenario = scenarios?.find((item) => item.id === id);
    const newData = { ...updateScenario, [field]: value } as ScenarioItemModel;

    updateScenarioMutation.mutate(newData);
  };

  const handleCreateEpdScenario = () => {
    createScenarioMutation.mutate({ id: '00000000-0000-0000-0000-000000000000', epdVersionId, isDefaultScenario: false });
  };

  const confirmDeleteScenario = (scenario: ScenarioItemModel) => {
    const scenarioName = scenario.name ?? 'Unnamed scenario';
    confirmDelete({
      caption: `Are you sure you want to delete scenario ${scenarioName}?`,
      onConfirm: () => deleteScenarioMutation.mutate(scenario.id),
    });
  };

  const handleCopyEpdScenario = (scenarioId: string) => {
    copyScenarioMutation.mutate({ epdVersionId, id: scenarioId });
  };

  const headerTemplate = (options: any, scenario: ScenarioItemModel, index: number) => {
    const toggleIcon = options.collapsed ? 'pi pi-chevron-down' : 'pi pi-chevron-up';

    return (
      <div className={options.className}>
        <ExpandablePanelHeaderName>
          <HeaderCaptionSemiBold>{scenario.name ?? `Unnamed scenario ${index}`}</HeaderCaptionSemiBold>
          <button className={options.togglerClassName} onClick={options.onTogglerClick} style={{ margin: '0 0.5rem' }}>
            <span className={toggleIcon}></span>
          </button>
        </ExpandablePanelHeaderName>
        <IconsSection>
          {scenario.isDefaultScenario ? (
            <ScenarioDefaultBadge>Default scenario</ScenarioDefaultBadge>
          ) : (
            <DeleteScenarioIconButton
              icon="pi pi-trash"
              text
              onClick={() => {
                confirmDeleteScenario(scenario);
              }}
            />
          )}
          <StyledCopyIcon onClick={() => handleCopyEpdScenario(scenario.id)} />
        </IconsSection>
      </div>
    );
  };

  const headerModuleTemplate = (
    options: any,
    header: string,
    tooltip?: string,
    tooltipPosition?: any | undefined,
    required?: boolean,
    fieldNames?: string[],
    errors?: any,
    isFilledIn?: boolean
  ) => {
    const toggleIcon = options.collapsed ? 'pi pi-chevron-down' : 'pi pi-chevron-up';

    function noErrorsAtBlock(): boolean | undefined {
      return !errors || !fieldNames?.some((field) => field in errors);
    }

    return (
      <div className={options.className}>
        <ExpandablePanelHeaderName>
          <HeaderCaptionSemiBold>{header}</HeaderCaptionSemiBold>
          {tooltip && <TooltipHelpIcon content={tooltip} position={tooltipPosition} />}
          {required && !noErrorsAtBlock() && <TooltipErrorIcon />}
          <button className={options.togglerClassName} onClick={options.onTogglerClick} style={{ margin: '0 0.5rem' }}>
            <span className={toggleIcon}></span>
          </button>
        </ExpandablePanelHeaderName>
      </div>
    );
  };

  return (
    <>
      {scenarios?.map((scenario: ScenarioItemModel, index: number) => (
        <ExpandablePanelMarginContainer key={index}>
          <ExpandablePanelMultiContainer
            key={scenario.id}
            headerTemplate={(options) => headerTemplate(options, scenario, index + 1)}
            toggleable
            collapsed
          >
            <FieldPanelFullWidth>
              <FieldPanelInput>
                <WizardTextInputComponent
                  label={scenario?.isDefaultScenario ? 'Default scenario name' : 'Additional scenario name'}
                  placeholder={`Unnamed scenario ${index + 1}`}
                  tooltip={
                    scenario?.isDefaultScenario
                      ? 'A descriptive name for the scenario.'
                      : 'An additional scenario for modules A4-C4, or alternative modelling approach (e.g., alternative GWP-biogenic results).'
                  }
                  name="name"
                  value={scenario.name}
                  maxLength={90}
                  required
                  error={scenariosErrors(index)?.name}
                  onChanged={(field: string, value: string) => handleScenarioUpdate(field, value, scenario.id)}
                />
              </FieldPanelInput>
              <FieldPanelInput>
                <WizardTextAreaComponent
                  label={
                    scenario?.isDefaultScenario
                      ? 'Description of the default scenario'
                      : 'Description of the additional scenario'
                  }
                  placeholder="Type here"
                  name="description"
                  tooltip={scenario?.isDefaultScenario ? 'A summary of the scenario, including key assumptions.' : ''}
                  value={scenario.description}
                  required
                  error={scenariosErrors(index)?.descriptionOfTheScenario}
                  onChanged={(field: string, value: string) => handleScenarioUpdate(field, value, scenario.id)}
                />
              </FieldPanelInput>
            </FieldPanelFullWidth>
            {declaredModules?.declaredModules?.a4 && (
              <ExpandablePanelModule
                headerTemplate={(options) =>
                  headerModuleTemplate(
                    options,
                    'Module A4: Transport to the building site',
                    undefined,
                    undefined,
                    true,
                    ['a4', 'a4Desc'],
                    scenariosErrors(index)
                  )
                }
                toggleable
              >
                <FieldPanelFullWidth>
                  <ModuleGrid
                    epdVersionId={epdVersionId}
                    scenarioId={scenario.id}
                    module="A4"
                    moduleName="Module A4 information"
                    unitOptions={units}
                    errors={scenariosErrors(index)?.a4Desc}
                  />
                </FieldPanelFullWidth>
              </ExpandablePanelModule>
            )}
            {declaredModules?.declaredModules?.a5 && (
              <ExpandablePanelModule
                headerTemplate={(options) =>
                  headerModuleTemplate(
                    options,
                    'Module A5: Installation in the building',
                    undefined,
                    undefined,
                    true,
                    ['a5', 'a5Desc'],
                    scenariosErrors(index)
                  )
                }
                toggleable
              >
                <FieldPanelFullWidth>
                  <ModuleGrid
                    epdVersionId={epdVersionId}
                    scenarioId={scenario.id}
                    module="A5"
                    moduleName="Module A5 information"
                    unitOptions={units}
                    errors={scenariosErrors(index)?.a5Desc}
                  />
                </FieldPanelFullWidth>
              </ExpandablePanelModule>
            )}
            {declaredModules?.declaredModules?.b1 && (
              <ExpandablePanelModule
                headerTemplate={(options) =>
                  headerModuleTemplate(options, 'Module B1: Use', undefined, undefined, true, ['b1', 'b1Desc'], scenariosErrors(index))
                }
                toggleable
              >
                <FieldPanelFullWidth>
                  <ModuleGrid
                    epdVersionId={epdVersionId}
                    scenarioId={scenario.id}
                    module="B1"
                    moduleName="Module B1 information"
                    unitOptions={units}
                    errors={scenariosErrors(index)?.b1Desc}
                  />
                </FieldPanelFullWidth>
              </ExpandablePanelModule>
            )}
            {declaredModules?.declaredModules?.b2 && (
              <ExpandablePanelModule
                headerTemplate={(options) =>
                  headerModuleTemplate(
                    options,
                    'Module B2: Maintenance',
                    undefined,
                    undefined,
                    true,
                    ['b2', 'b2Desc'],
                    scenariosErrors(index)
                  )
                }
                toggleable
              >
                <FieldPanelFullWidth>
                  <ModuleGrid
                    epdVersionId={epdVersionId}
                    scenarioId={scenario.id}
                    module="B2"
                    moduleName="Module B2 information"
                    unitOptions={units}
                    errors={scenariosErrors(index)?.b2Desc}
                  />
                </FieldPanelFullWidth>
              </ExpandablePanelModule>
            )}
            {declaredModules?.declaredModules?.b3 && (
              <ExpandablePanelModule
                headerTemplate={(options) =>
                  headerModuleTemplate(
                    options,
                    'Module B3: Repair',
                    undefined,
                    undefined,
                    true,
                    ['b3', 'b3Desc'],
                    scenariosErrors(index)
                  )
                }
                toggleable
              >
                <FieldPanelFullWidth>
                  <ModuleGrid
                    epdVersionId={epdVersionId}
                    scenarioId={scenario.id}
                    module="B3"
                    moduleName="Module B3 information"
                    unitOptions={units}
                    errors={scenariosErrors(index)?.b3Desc}
                  />
                </FieldPanelFullWidth>
              </ExpandablePanelModule>
            )}
            {declaredModules?.declaredModules?.b4 && (
              <ExpandablePanelModule
                headerTemplate={(options) =>
                  headerModuleTemplate(
                    options,
                    'Module B4: Replacement',
                    undefined,
                    undefined,
                    true,
                    ['b4', 'b4Desc'],
                    scenariosErrors(index)
                  )
                }
                toggleable
              >
                <FieldPanelFullWidth>
                  <ModuleGrid
                    epdVersionId={epdVersionId}
                    scenarioId={scenario.id}
                    module="B4"
                    moduleName="Module B4 information"
                    unitOptions={units}
                    errors={scenariosErrors(index)?.b4Desc}
                  />
                </FieldPanelFullWidth>
              </ExpandablePanelModule>
            )}
            {declaredModules?.declaredModules?.b5 && (
              <ExpandablePanelModule
                headerTemplate={(options) =>
                  headerModuleTemplate(
                    options,
                    'Module B5: Refurbishment',
                    undefined,
                    undefined,
                    true,
                    ['b5', 'b5Desc'],
                    scenariosErrors(index)
                  )
                }
                toggleable
              >
                <FieldPanelFullWidth>
                  <ModuleGrid
                    epdVersionId={epdVersionId}
                    scenarioId={scenario.id}
                    module="B5"
                    moduleName="Module B5 information"
                    unitOptions={units}
                    errors={scenariosErrors(index)?.b5Desc}
                  />
                </FieldPanelFullWidth>
              </ExpandablePanelModule>
            )}
            {declaredModules?.declaredModules?.b6 && (
              <ExpandablePanelModule
                headerTemplate={(options) =>
                  headerModuleTemplate(
                    options,
                    'Module B6: Operational energy use',
                    undefined,
                    undefined,
                    true,
                    ['b6', 'b6Desc'],
                    scenariosErrors(index)
                  )
                }
                toggleable
              >
                <FieldPanelFullWidth>
                  <ModuleGrid
                    epdVersionId={epdVersionId}
                    scenarioId={scenario.id}
                    module="B6"
                    moduleName="Module B6 information"
                    unitOptions={units}
                    errors={scenariosErrors(index)?.b6Desc}
                  />
                </FieldPanelFullWidth>
              </ExpandablePanelModule>
            )}
            {declaredModules?.declaredModules?.b7 && (
              <ExpandablePanelModule
                headerTemplate={(options) =>
                  headerModuleTemplate(
                    options,
                    'Module B7: Operational water use',
                    undefined,
                    undefined,
                    true,
                    ['b7', 'b7Desc'],
                    scenariosErrors(index)
                  )
                }
                toggleable
              >
                <FieldPanelFullWidth>
                  <ModuleGrid
                    epdVersionId={epdVersionId}
                    scenarioId={scenario.id}
                    module="B7"
                    moduleName="Module B7 information"
                    unitOptions={units}
                    errors={scenariosErrors(index)?.b7Desc}
                  />
                </FieldPanelFullWidth>
              </ExpandablePanelModule>
            )}

            {(declaredModules?.declaredModules?.c1 ||
              declaredModules?.declaredModules?.c2 ||
              declaredModules?.declaredModules?.c3 ||
              declaredModules?.declaredModules?.c4) && (
              <ExpandablePanelModule
                headerTemplate={(options) =>
                  headerModuleTemplate(
                    options,
                    'Module C: End-of-life',
                    undefined,
                    undefined,
                    true,
                    ['c', 'cDesc'],
                    scenariosErrors(index)
                  )
                }
                toggleable
              >
                <FieldPanelFullWidth>
                  <ModuleGrid
                    epdVersionId={epdVersionId}
                    scenarioId={scenario.id}
                    module="C1"
                    moduleName="Module C information"
                    unitOptions={units}
                    errors={scenariosErrors(index)?.cDesc}
                  />
                </FieldPanelFullWidth>
              </ExpandablePanelModule>
            )}

            <ExpandablePanelModule
              headerTemplate={(options) =>
                headerModuleTemplate(
                  options,
                  'Reference service life',
                  'The RSL of a product category is the reference time to which the performance of all products of a product category shall be related.',
                  'top | right',
                  true,
                  ['rsl', 'rslDesc'],
                  scenariosErrors(index)
                )
              }
              toggleable
            >
              <FieldPanelFullWidth>
                <FieldPanelInput>
                  <SmartLabel label="Is RSL included in the EPD" error={scenariosErrors(index)?.enabledRSL} />
                  <Dropdown
                    style={{ width: '100%', minWidth: '1rem' }}
                    placeholder="Select"
                    value={scenario.enabledRSL}
                    optionLabel="label"
                    optionValue="value"
                    options={[
                      { label: 'Yes, it is included', value: true },
                      { label: 'No, it is not included', value: false },
                    ]}
                    onChange={(e: DropdownChangeEvent) => {
                      scenario.enabledRSL = e.value;
                      handleScenarioUpdate('enabledRSL', e.value, scenario.id);
                    }}
                    showClear
                  />
                </FieldPanelInput>
                {scenario.enabledRSL && (
                  <ModuleGrid
                    epdVersionId={epdVersionId}
                    scenarioId={scenario.id}
                    module="RSL"
                    moduleName="Reference service life information"
                    unitOptions={units}
                    errors={scenariosErrors(index)?.rslDesc}
                  />
                )}
              </FieldPanelFullWidth>
            </ExpandablePanelModule>

            {declaredModules?.declaredModules?.d && (
              <ExpandablePanelModule
                headerTemplate={(options) =>
                  headerModuleTemplate(
                    options,
                    'Module D: Beyond product life cycle',
                    undefined,
                    undefined,
                    true,
                    ['d', 'dDesc'],
                    scenariosErrors(index)
                  )
                }
                toggleable
              >
                <FieldPanelFullWidth>
                  <ModuleGrid
                    epdVersionId={epdVersionId}
                    scenarioId={scenario.id}
                    module="D"
                    moduleName="Module D information"
                    unitOptions={units}
                    errors={scenariosErrors(index)?.dDesc}
                  />
                </FieldPanelFullWidth>
              </ExpandablePanelModule>
            )}
          </ExpandablePanelMultiContainer>
        </ExpandablePanelMarginContainer>
      ))}
      <AddButtonBox onClick={handleCreateEpdScenario}>
        <AddImageIcon style={{ marginRight: '0.625rem' }} />
        Add additional scenario
      </AddButtonBox>
    </>
  );
};

const ScenarioDefaultBadge = styled.div`
  ${(props) => props.theme.typography.headerCaptionMedium};
  white-space: nowrap;
  height: 22px;
  padding: 0.25rem 0.75rem;
  border-radius: 2px;
  color: ${(props) => props.theme.colors.statusLabelGreenText};
  background-color: ${(props) => props.theme.colors.statusLabelGreenBg};
  cursor: grab;
`;

const ExpandablePanelMarginContainer = styled(ExpandablePanelContainer)`
  box-shadow: none;
`;

const ExpandablePanelMultiContainer = styled(ExpandablePanel)`
  .p-panel-content {
    padding: 0;
    border: 0;
    background-color: transparent;
    width: 100%;
    display: grid;
  }
  ${StyledInput} {
    color: ${(props) => props.theme.colors.primaryBlack};
  }
  && textarea {
    color: ${(props) => props.theme.colors.primaryBlack};
  }
`;

const AddButtonBox = styled.div`
  ${(props) => props.theme.typography.buttonButtonSmall}
  width: 100%;
  border-radius: 4px;
  padding: 20px;
  border: 1px dashed ${(props) => props.theme.colors.primaryOrange01};
  margin-top: 16px;
  justify-content: start;
  align-items: center;
  display: flex;
  max-height: 70px;
  min-height: 70px;
  background: ${(props) => props.theme.colors.primaryOrange01};
  color: ${(props) => props.theme.colors.primaryOrange};
  cursor: pointer;
`;

const DeleteScenarioIconButton = styled(IconButton)`
  width: 16px !important;
  height: 16px !important;
  &.p-button.p-button-text {
    color: ${(props) => props.theme.colors.primaryBlack};
  }
  &.p-button.p-button-text:hover {
    background: transparent;
  }
`;

export default ScenariosTab;

import { REMOVE_FILE } from 'constants/constants';
import { CompanyContext } from 'contexts/CompanyContextProvider';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { EPDModel, FileLoadingModel } from 'services/EpdClient';
import EpdService from 'services/EpdService';
import { useUpdateEpdData } from 'services/api/mutations';
import {
  useEpd,
  useEpdActionsStates,
  useEpdFormat,
  useEpdGeneralInformation,
  useServerValidationQuery,
} from 'services/api/queries';
import { EPDGeneralInformationModel } from 'types/types';

import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import Step5 from './Step5';
import Step6 from './Step6';

const useCompilerLogic = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { companyAccountId, company } = React.useContext(CompanyContext);
  const [isExplanationVisible, setIsExplanationVisible] = useState<boolean>(true);
  const [activeStepIndex, setActiveStepIndex] = useState<number>(1);
  const helpButtonRef = useRef<HTMLButtonElement | undefined>(null);

  const [isUiBlocked, setIsUiBlocked] = useState<boolean>(false);
  const [isUpdatingEpd, setIsUpdatingEpd] = useState<boolean>(false);
  const [declinePublicationText, setDeclinePublicationText] = useState<string>('');
  const [fileLoading, setFileLoading] = useState<FileLoadingModel[]>([]);
  const { id } = useParams<any>();

  const epdQuery = useEpd(id);
  const epdFormatQuery = useEpdFormat(id);
  const actionsStates = useEpdActionsStates(id).data;
  const epdGeneralInformationQuery = useEpdGeneralInformation(epdQuery.data?.versionId!);
  const serverValidationQuery = useServerValidationQuery(epdQuery.data?.versionId!);
  const serverValidationResult = serverValidationQuery?.data;

  const updateEpdData = useUpdateEpdData(id);

  const onChangeEpd = async (propertyName: string, val: any) => {
    addEpdProperty(epdQuery.data?.id!, epdQuery.data?.versionId!, propertyName, val);
  };

  const addEpdProperty = async (epdId: string, versionId: string, propertyName: string, val: any) => {
    try {
      setIsUpdatingEpd(true);

      if (propertyName === REMOVE_FILE) {
        await EpdService.removeEpdFile(val, versionId);
      } else {
        updateEpdData.mutate({
          epdId: epdId,
          versionId: epdQuery.data?.versionId,
          propertyName: propertyName,
          newValue: val,
        });
      }
    } catch {
    } finally {
      setIsUpdatingEpd(false);
    }
  };

  const changeOrAddFileLoading = (type: string, newLoad: boolean, prev: FileLoadingModel[]) => {
    if (prev.find((model) => model.fileType === type)) {
      return prev.map((model) =>
        model.fileType === type
          ? { ...model, loadingsCount: newLoad ? model.loadingsCount + 1 : model.loadingsCount - 1 }
          : model
      );
    } else {
      return [...prev, { fileType: type, loadingsCount: 1 }];
    }
  };

  type StepDefinition = {
    label: string;
    name: string;
    description: string;
    id: number;
    render: () => JSX.Element;
    isValid: (item: any) => boolean | undefined;
  };

  function noErrorsAtStep1(errors: any): boolean | undefined {
    const fieldNames: string[] = [
      'title',
      'generalInformation_DeclaredStandards',
      'companyLogoImages',
      'productImages',
      'useAdvice',
      'subtype',
      'epdClassification',
      'productToDeclare',
        'organizationInformation',
        'epdDevelopers',
    ];

    return !errors || !fieldNames.some((field) => field in errors);
  }

  function noErrorsAtStep2(errors: any): boolean | undefined {
    if (!errors) {
      return true;
    }
    const fieldNames: string[] = [
      'productComponents',
      'packagingMaterials',
      'generalInformation_HasDangerMaterials',
      'dangerMaterials',
      'products',
    ];
    var hasDangerMaterialsErrors = JSON.stringify(errors).includes('"hasDangerMaterials');
    if (hasDangerMaterialsErrors) {
      return false;
    }
    return !fieldNames.some((field) => field in errors);
  }
  function noErrorsAtStep3(errors: any): boolean | undefined {
    if (!errors) {
      return true;
    }
    const fieldNames: string[] = [
      'productFlowName',
      'productFlowUnit',
      'productFlowValue',
      'conversionFactorUnit',
      'conversionFactorValue',
      'database',
      'databaseVersion',
      'lcaSoftwareVersion',
      'lcaSoftware',
      'characterisationMethods',
      'technologyDescription',
      'softwareVersion',
      'softwareName',
      'lcaBasedDescription',
      'scrapInputs',
      'scrapInputs',
      'declaredModules',
      'declaredModulesGeography',
    ];

    var hasDatabaseVersionErrors = JSON.stringify(errors).includes('"databaseVersion');
    var hasGeneralErrors = JSON.stringify(errors).includes('"general"');
    var hasDeclaredModulesErrors = JSON.stringify(errors).includes('"declaredModulesErrors"');
    var hasDeclaredModulesGeographyErrors = JSON.stringify(errors).includes('"declaredModulesGeography"');
    var hasSystemBoundaryErrors = JSON.stringify(errors).includes('"systemBoundary"');
    var hasScenariosErrors = JSON.stringify(errors).includes('"scenarios"');

    if (
      hasDatabaseVersionErrors ||
      hasGeneralErrors ||
      hasDeclaredModulesErrors ||
      hasDeclaredModulesGeographyErrors ||
      hasSystemBoundaryErrors ||
      hasScenariosErrors
    ) {
      return false;
    }

    return !fieldNames.some((field) => field in errors);
  }
  function noErrorsAtStep4(errors: any): boolean | undefined {
    const fieldNames: string[] = ['verificationReports', 'validityDate', 'approvalDate'];
    return !errors || !fieldNames.some((field) => field in errors);
  }
  function noErrorsAtStep5(errors: any): boolean | undefined {
    const fieldNames: string[] = ['registrationDate', 'publicVersionDate'];
    return !errors || !fieldNames.some((field) => field in errors);
  }
  function noErrorsAtStep6(errors: any): boolean | undefined {
    return undefined;
  }

  const steps: StepDefinition[] = [
    {
      label: 'Step 1',
      name: 'General information',
      description: 'Fill-in the EPD initial data',
      id: 1,
      render: () => {
        return (
          <Step1
            epd={epdQuery.data as EPDModel}
            generalInformation={epdGeneralInformationQuery.data as EPDGeneralInformationModel}
            onChangeEpd={onChangeEpd} /*fileLoading={fileLoading}*/
            validationState={serverValidationQuery.data}
          />
        );
      },
      isValid: (errors) => noErrorsAtStep1(errors),
    },
    {
      label: 'Step 2',
      name: 'Product declaration',
      description: 'Describe your EPD subject',
      id: 2,
      render: () => {
        return (
          <Step2
            epd={epdQuery.data}
            onChangeEpd={onChangeEpd}
            fileLoading={fileLoading}
            generalInformation={epdGeneralInformationQuery.data as EPDGeneralInformationModel}
            validationState={serverValidationQuery.data}
          />
        );
      },
      isValid: (errors) => noErrorsAtStep2(errors),
    },
    {
      label: 'Step 3',
      name: 'LCA information',
      description: 'Describe your LCA output',
      id: 3,
      render: () => {
        return (
          <Step3
            epd={epdQuery.data}
            onChangeEpd={onChangeEpd}
            fileLoading={fileLoading}
            validationState={serverValidationQuery.data}
          />
        );
      },
      isValid: (errors) => noErrorsAtStep3(errors),
    },
    {
      label: 'Step 4',
      name: 'EPD verification',
      description: 'Get the third-party approval',
      id: 4,
      render: () => {
        return (
          <Step4
            epdId={epdQuery.data?.id!}
            epdVersionId={epdQuery.data?.versionId!}
            validationState={serverValidationQuery.data}
          />
        );
      },
      isValid: (errors) => noErrorsAtStep4(errors),
    },
    {
      label: 'Step 5',
      name: 'EPD publication',
      description: 'Submit the EPD for publication',
      id: 5,
      render: () => {
        return <Step5 epd={epdQuery.data} onChangeEpd={onChangeEpd} validationState={serverValidationQuery.data} />;
      },
      isValid: (errors) => noErrorsAtStep5(errors),
    },
    {
      label: 'Step 6',
      name: 'Generated EPD',
      description: 'Get your EPD in PDF format',
      id: 6,
      render: () => {
        return (
          <Step6
            epd={epdQuery.data as EPDModel}
            generalInformation={epdGeneralInformationQuery.data as EPDGeneralInformationModel}
            onChangeEpd={onChangeEpd}
          />
        );
      },
      isValid: (errors) => noErrorsAtStep6(errors),
    },
  ];

  return {
    companyAccountId,
    company,
    t,
    history,
    steps,
    activeStepIndex,
    setActiveStepIndex,
    helpButtonRef,

    isExplanationVisible,
    setIsExplanationVisible,
    declinePublicationText,
    setDeclinePublicationText,
    isUiBlocked,
    setIsUiBlocked,
    isUpdatingEpd,
    epdQuery,
    epdGeneralInformationQuery,
    epdFormatQuery,
    actionsStates,
    serverValidationResult,
  };
};

export default useCompilerLogic;

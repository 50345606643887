import { ImagesUpload } from 'components/v2/images-upload';
import {
  HeaderCaptionSemiBold,
  FieldPanelFullWidth,
  ExpandablePanelHeaderName,
  ExpandablePanelModule,
} from 'styles/v2/Styles.styled';
import EPDService from 'services/EpdService';
import TooltipErrorIcon from 'components/v2/icons/TooltipErrorIcon';
import { EPDProductInformationModel } from 'types/types';
import { useAddProductInformationFileMutation, useRemoveProductInformationFileMutation } from 'services/api/mutations';
import { addEpdFile } from 'services/api/mutations';
import WizardTextAreaComponent from 'components/v2/epd-wizard-inputs/WizardTextArea';
import WizardTextInputComponent from 'components/v2/epd-wizard-inputs/WizardTextInput';
import SmartLabel from 'components/v2/labels/SmartLabel';

const ProductDetailsSection: React.FunctionComponent<{
  product?: EPDProductInformationModel;
  epdId: string;
  epdVersionId: string;
  onChange: any;
  isReadOnly: boolean;
  errors?: any;
}> = ({ product, epdId, epdVersionId, onChange, errors }) => {
  const addProductInformationFileMutation = useAddProductInformationFileMutation(epdVersionId);
  const removeProductInformationFileMutation = useRemoveProductInformationFileMutation(epdVersionId);
  const productDetailsErrors = errors?.productDeclaration;

  const onAddSystemBoundaryFile = async (propertyName: string, file: File) => {
    await addEpdFile({ epdId, propertyName, file, epdProductInformationId: product?.id }, addProductInformationFileMutation);
  };

  const onRemoveSystemBoundaryFile = async (fileId: string) => {
    removeProductInformationFileMutation.mutate(fileId);
  };

  const headerModuleTemplate = (options: any, header: string, required?: boolean) => {
    const toggleIcon = options.collapsed ? 'pi pi-chevron-down' : 'pi pi-chevron-up';
    const isFilledIn =
      !!product?.productDescription && !!product?.productIdentification && !!product.productName ? true : false;

    return (
      <div className={options.className}>
        <ExpandablePanelHeaderName>
          <HeaderCaptionSemiBold>{header}</HeaderCaptionSemiBold>

          {required && !isFilledIn && <TooltipErrorIcon />}
          <button className={options.togglerClassName} onClick={options.onTogglerClick} style={{ margin: '0 0.5rem' }}>
            <span className={toggleIcon}></span>
          </button>
        </ExpandablePanelHeaderName>
      </div>
    );
  };

  return (
    <ExpandablePanelModule headerTemplate={(options) => headerModuleTemplate(options, 'Product details', true)} toggleable>
      <FieldPanelFullWidth>
        <WizardTextInputComponent
          label="Product name"
          tooltip="Name of the product included in the EPD."
          onChanged={onChange}
          required
          placeholder="Unnamed"
          name="productName"
          value={product?.productName}
          error={productDetailsErrors?.name}
        />
        <WizardTextAreaComponent
          label="Product identification"
          required
          placeholder="Type here"
          tooltip="Product identification by an unambiguous identification of the product by standards, concessions, or other means."
          value={product?.productIdentification}
          onChanged={onChange}
          name="productIdentification"
          error={productDetailsErrors?.productIdentification}
        />
        <WizardTextAreaComponent
          label="Product description"
          required
          placeholder="Type here"
          tooltip="Description of the product(s) included in the EPD."
          value={product?.productDescription}
          onChanged={onChange}
          name="productDescription"
          error={productDetailsErrors?.productDescription}
        />
        <WizardTextAreaComponent
          label="Product information from external sources"
          placeholder="Type here"
          tooltip="References to any relevant websites for more information or explanatory materials."
          value={product?.productInformationExternal}
          onChanged={onChange}
          name="productInformationExternal"
        />
        <div>
          <SmartLabel
            label={'Product images shown on EPD'}
            required
            hasValue={!!product?.productDetailsImages?.length}
            tooltip="Product images shown on www.environdec.com and in the EPD."
            error={productDetailsErrors?.productInformationsToFiles}
          />
          <ImagesUpload
            name={'ProductDetailsImages'}
            images={product?.productDetailsImages}
            onUpload={onAddSystemBoundaryFile}
            onReorder={(imgIds: string[]) => EPDService.arrangeEpdFilesByModifyDate(imgIds)}
            onRemove={onRemoveSystemBoundaryFile}
          />
        </div>
      </FieldPanelFullWidth>
    </ExpandablePanelModule>
  );
};

export default ProductDetailsSection;

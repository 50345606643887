import { DataTableValueArray } from 'primereact/datatable';

import { LcaResultsGridColumnsPdf } from './LcaResultsGridColumns';
import LcaResultsGridPdf from './LcaResultsGridPdf';

type TProps = {
  rows: DataTableValueArray | undefined;
};

const LcaResultsGridCoreConstructionPdf = ({ rows }: TProps) => {
  const notes = [
    {
      note: 'Acronyms',
      description:
        'GWP-fossil = Global Warming Potential fossil fuels; GWP-biogenic = Global Warming Potential biogenic; GWP-luluc = Global Warming Potential land use and land use change; ODP = Depletion potential of the stratospheric ozone layer; AP = Acidification potential, Accumulated Exceedance; EP-freshwater = Eutrophication potential, fraction of nutrients reaching freshwater end compartment; EP-marine = Eutrophication potential, fraction of nutrients reaching marine end compartment; EP-terrestrial = Eutrophication potential, Accumulated Exceedance; POCP = Formation potential of tropospheric ozone; ADP-minerals&metals = Abiotic depletion potential for non-fossil resources; ADP-fossil = Abiotic depletion for fossil resources potential; WDP = Water (user) deprivation potential, deprivation-weighted water consumption',
    },
    {
      note: (
        <>
          Disclaimer<sup>1</sup>
        </>
      ),
      description:
        'The results of this environmental impact indicator shall be used with care as the uncertainties of these results are high or as there is limited experience with the indicator',
    },
  ];

  return <LcaResultsGridPdf value={rows} columns={LcaResultsGridColumnsPdf} notes={notes} />;
};

export default LcaResultsGridCoreConstructionPdf;

import { CompanyContext } from 'contexts/CompanyContextProvider';
import React, { useContext } from 'react';
import { EPDModel, FileLoadingModel, Subtype } from 'services/EpdClient';
import EPDService from 'services/EpdService';
import { EPD_DICTIONARY_CODES } from 'services/api/constants';
import { createTag } from 'services/api/epdApi';
import {
  addEpdFile,
  useAddCoverPageFile,
  useRemoveCoverPageFile,
  useUpdateCoverPageMutation,
  useUpdateEpdGeneralInformation,
  useChangeEPDDefinition,
} from 'services/api/mutations';
import {
  useDictionaryValues,
  useEpdCollectionsLookup,
  useEpdCoverPageInformation,
  useTagSource,
  useEpdGeneralInformation,
  useEPDDefinitionInformation,
} from 'services/api/queries';
import styled from 'styled-components';
import { ExpandablePanelHeaderName, FieldPanelFullWidth, FieldPanelHeader } from 'styles/v2/Styles.styled';
import { EPDGeneralInformationModel, Option } from 'types/types';

import CompilerMultiSelect from '../epd-compiler-inputs/CompilerMultiSelect';
import CompilerSelectControlledComponent from '../epd-compiler-inputs/CompilerSelectControlledComponent';
import WizardCreatableSelectControlledComponent from '../epd-wizard-inputs/WizardCreatableSelectControlled';
import WizardTextAreaComponent from '../epd-wizard-inputs/WizardTextArea';
import WizardTextInputComponent from '../epd-wizard-inputs/WizardTextInput';
import { ExpandableHelpBox } from '../help-boxes';
import TooltipHelpIcon from '../icons/TooltipHelpIcon';
import { ImagesUpload } from '../images-upload';
import SmartLabel from '../labels/SmartLabel';

const DefinitionTab: React.FunctionComponent<{
  epdId: string;
  epdVersionId: string;
  validationState: any;
}> = ({ epdId, epdVersionId, validationState }) => {
  const subtypeOptions: Option[] = (Object.keys(Subtype) as Array<keyof typeof Subtype>).map((key) => ({
    label: Subtype[key].toString(),
    value: Subtype[key].toString(),
  }));

  const epdDefinitionInformation = useEPDDefinitionInformation(epdVersionId).data;
  const coverPageInformation = useEpdCoverPageInformation(epdVersionId).data;
  const updateCoverPageMutation = useUpdateCoverPageMutation(epdVersionId);
  const addCoverPageFileMutation = useAddCoverPageFile(epdVersionId);
  const deleteCoverPageFileMutation = useRemoveCoverPageFile(epdVersionId);
  const tagSource = useTagSource().data;
  const updateEPDDefinitionProperty = useChangeEPDDefinition(epdVersionId);

  const { companyId } = useContext(CompanyContext);
  const tagsValue = epdDefinitionInformation?.tags || [];

  const handleCreateTag = async (inputValue: string) => {
    const newOption = await createTag({ id: null, name: inputValue });
    onChangeEPDDefinitionProperty('Tags', [...tagsValue, { label: newOption.name, value: newOption.id } as Option]);
  };

  const onChangeCoverPageData = async (propertyName: string, val: any) => {
    updateCoverPageMutation.mutate({
      epdId: epdId,
      versionId: epdVersionId,
      propertyName: propertyName,
      newValue: val,
    });
  };

  const onAddCoverPageFile = async (propertyName: string, file: File) => {
    await addEpdFile({ epdId: epdId, propertyName, file }, addCoverPageFileMutation);
  };

  const onRemoveCoverPageFile = async (fileId: string) => {
    deleteCoverPageFileMutation.mutate(fileId);
  };

  const dictionariesQuery = useDictionaryValues();
  const epdCollectionsLookup = useEpdCollectionsLookup(companyId ?? '').data;

  const getOptionsFromDictionary = (code: string) =>
    dictionariesQuery.data
      ?.filter((x) => x.dictionaryCode === code)
      .map((x) => {
        return {
          value: x.key.toString(),
          label: x.value,
        };
      }) || [];

  const epdClassificationOptions = getOptionsFromDictionary(EPD_DICTIONARY_CODES.EPD_CLASSIFICATION);

  const productTypeOptions = getOptionsFromDictionary(EPD_DICTIONARY_CODES.PRODUCT_TYPE);

  const conformityStandardsOptions = getOptionsFromDictionary(EPD_DICTIONARY_CODES.CONFORMITY_STANDARDS);

  const selectedProductTypeOriginal = !isNaN(Number(epdDefinitionInformation?.productType))
    ? productTypeOptions.find((x) => x.value == epdDefinitionInformation?.productType)
    : productTypeOptions.find((x) => x.label == epdDefinitionInformation?.productType);

  const selectedProductType = selectedProductTypeOriginal
    ? ({
        value: selectedProductTypeOriginal?.value.toString(),
        label: selectedProductTypeOriginal?.label,
      } as Option)
    : null;

  const selectedStandards = epdDefinitionInformation?.declaredStandards
    ? Array.isArray(epdDefinitionInformation?.declaredStandards)
      ? epdDefinitionInformation?.declaredStandards?.map((x) => conformityStandardsOptions.find((y) => y.value == x))
      : epdDefinitionInformation?.declaredStandards
          ?.split(',')
          .map((x) => conformityStandardsOptions.find((y) => y.value == x))
    : []; 

  const onChangeEPDDefinitionProperty = async (propertyName: string, val: any) => {
    updateEPDDefinitionProperty.mutate({
      epdId: epdId,
      versionId: epdVersionId,
      propertyName: propertyName,
      newValue: val,
    });
  };

  const headerHelpBoxTemplate = (options: any, header: string, tooltip?: string, tooltipPosition?: any | undefined) => {
    const toggleIcon = options.collapsed ? 'pi pi-chevron-down' : 'pi pi-chevron-up';

    return (
      <div className={options.className}>
        <ExpandablePanelHeaderName>
          <HelpBoldText>{header}</HelpBoldText>
          {tooltip && <TooltipHelpIcon content={tooltip} position={tooltipPosition} />}
        </ExpandablePanelHeaderName>
        <button className={options.togglerClassName} onClick={options.onTogglerClick} style={{ margin: '0 0.5rem' }}>
          <span className={toggleIcon}></span>
        </button>
      </div>
    );
  };
  
  return (
    <>
      <FieldPanelFullWidth>
        <FieldPanelHeader style={{ display: 'flex', alignItems: 'center' }}>
          EPD cover page <TooltipHelpIcon content={'EPD cover page'} />
        </FieldPanelHeader>
        <WizardTextInputComponent
          label="EPD name"
          tooltip="Add the product name which will be stated as EPD name for both - in the EPD portal and in in the front cover page of the EPD document."
          tooltipPosition={'right'}
          required
          value={epdDefinitionInformation?.declarationName}
          onChanged={onChangeEPDDefinitionProperty}
          name="title"
          maxLength={150}
          error={validationState?.errors?.title}
        />

        <CompilerSelectControlledComponent
          label="Statement of conformity with ISO"
          tooltip="Select the standards with which the EPD is compliant."
          name="declaredStandards"
          options={conformityStandardsOptions}
          required
          value={selectedStandards}
          onChanged={onChangeEPDDefinitionProperty}
          placeholder="Select..."
          isMulti
          error={validationState?.errors?.generalInformation_DeclaredStandards}
        />
        <div>
          <SmartLabel
            label={'Organization logo'}
            required
            hasValue={coverPageInformation?.companyLogoImages && coverPageInformation?.companyLogoImages.length > 0}
            error={validationState?.errors?.companyLogoImages}
          />
          <ImagesUpload
            name={'CompanyLogoImages'}
            single
            images={coverPageInformation?.companyLogoImages}
            onUpload={onAddCoverPageFile}
            onReorder={(imgIds: string[]) => EPDService.arrangeEpdFilesByModifyDate(imgIds)}
            onRemove={onRemoveCoverPageFile}
          />
        </div>
        <div>
          <SmartLabel
            label={'Product image for cover page'}
            required
            hasValue={coverPageInformation?.productImages && coverPageInformation?.productImages.length > 0}
            tooltip="Product image which will present your product in the front cover page of the EPD document."
            error={validationState?.errors?.productImages}
          />
          <ImagesUpload
            name={'ProductImages'}
            single
            images={coverPageInformation?.productImages}
            onUpload={onAddCoverPageFile}
            onReorder={(imgIds: string[]) => EPDService.arrangeEpdFilesByModifyDate(imgIds)}
            onRemove={onRemoveCoverPageFile}
          />
        </div>

        <WizardTextAreaComponent
          label="Use advice for the EPD"
          placeholder="Type here"
          tooltip="Specification on how the EPD is to be used."
          name="useAdvice"
          value={epdDefinitionInformation?.useAdvice ?? ''}
          onChanged={onChangeEPDDefinitionProperty}
          required
          error={validationState?.errors?.useAdvice}
        />

        <CompilerSelectControlledComponent
          isClearable={false}
          required
          label="Subtype"
          tooltip="Indicates the type of data set regarding its representativeness."
          tooltipPosition="top | right"
          options={subtypeOptions}
          name="subtype"
          value={subtypeOptions.find((x) => x.value == epdDefinitionInformation?.subtype?.toString() || '')}
          onChanged={onChangeEPDDefinitionProperty}
          error={validationState?.errors?.subtype}
        />
        <ExpandableHelpBox
          headerTemplate={(options) => headerHelpBoxTemplate(options, 'Explanation hint for subtype.')}
          toggleable
        >
          <span>
            Select a subtype that indicates the representativeness of EPD. One of the following predefined data types shall
            be chosen:
            <li>Specific (single product).</li>
            <li>Average (multiple products).</li>
            <li>Representative (Sector EPD).</li>
            <li>Template (product not yet on the market).</li>
          </span>
        </ExpandableHelpBox>
      </FieldPanelFullWidth>

      <FieldPanelFullWidth>
        <FieldPanelHeader style={{ display: 'flex', alignItems: 'center' }}>EPD general information</FieldPanelHeader>
        <WizardTextInputComponent
          label="UUID of data set"
          name="uuid"
          tooltip="Automatically generated universally unique identifier of this data."
          disabled
          value={epdId}
        />
        <WizardTextInputComponent
          label="EPD Id"
          name="registrationNumber"
          tooltip="Automatically generated unique identifier for this EPD."
          disabled
          value={`EPD-IES-${epdDefinitionInformation?.registrationNumber}:${String(
            epdDefinitionInformation?.versionNumber ?? 1
          ).padStart(3, '0')}`}
        />
        <CompilerMultiSelect
          label="Add to collection"
          tooltip="With the EPD Collection pages you can organize and attractively present your valid EPD documents in a hierarchical, but flexible organizational structure to your stakeholders via our EPD Library."
          name="collections"
          options={epdCollectionsLookup}
          value={epdDefinitionInformation?.collections}
          onChanged={onChangeEPDDefinitionProperty}
          placeholder="Select..."
          disabled={!(epdCollectionsLookup && epdCollectionsLookup.length > 0)}
        />
        <CompilerSelectControlledComponent
          label="Single or multiple products"
          tooltip="Choose an option based on whether the EPD covers a single product or multiple products, as well as how the results of the product group are presented. If one of the options on multiple products is selected list the products that are included in the EPD."
          name="epdClassification"
          options={epdClassificationOptions}
          value={epdClassificationOptions.find((x) => x.value == epdDefinitionInformation?.epdClassification)}
          onChanged={onChangeEPDDefinitionProperty}
          placeholder="Select..."
          isClearable={false}
          error={validationState?.errors?.epdClassification}
        />
        <CompilerSelectControlledComponent
          label="Product to declare"
          tooltip="EPD can declare goods or services, choose between the two options."
          name="productType"
          options={productTypeOptions}
          required
          value={selectedProductType}
          onChanged={onChangeEPDDefinitionProperty}
          placeholder="Select..."
          isClearable={false}
          error={validationState?.errors?.productToDeclare}
        />
        <WizardCreatableSelectControlledComponent
          label="Keywords"
          tooltip="With the keywords you can help to find your your valid EPD documents in a quick way in our EPD library on environdec.com."
          components={{ DropdownIndicator: null }}
          value={tagsValue || []}
          name="tags"
          isMulti
          isClearable
          placeholder="Type here to add keyword..."
          options={tagSource?.map((tag) => {
            return { value: tag.id, label: tag.name };
          })}
          onCreateOption={handleCreateTag}
          onChanged={onChangeEPDDefinitionProperty}
        />
      </FieldPanelFullWidth>
    </>
  );
};

const HelpBoldText = styled.span`
  font-weight: 600;
`;

export default DefinitionTab;

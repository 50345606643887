import WizardTextInputControlledComponent from 'components/v2/epd-wizard-inputs/WizardTextInputControlled';
import { DownloadButton, PcrListItem, PcrListItemHeader } from 'components/v2/pcr-selector/styled';
import { ProcessStatus } from 'constants/constants';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PCRModel } from 'services/EpdClient';
import FileService from 'services/FileService';
import { A } from 'styles/Styles.styled';
import { RadioButtonStyled } from 'styles/v2/Styles.styled';

interface TProps {
  cpcrList: PCRModel[];
  cpcr: PCRModel | undefined;
  status: ProcessStatus;
  onPcrSelect: any;
}

const CPcrSelectorDetails = ({ cpcrList, cpcr, status, onPcrSelect }: TProps) => {
  const { t } = useTranslation();

  const [filteredCpcrList, setFilteredCpcrList] = useState<PCRModel[]>(cpcrList);

  const handleSearch = (event: any) => {
    const searchTerm = event.target.value.toLowerCase();
    if (!searchTerm) {
      setFilteredCpcrList(cpcrList);
      return;
    }
    setFilteredCpcrList((prev) =>
      cpcrList.filter(
        (item) =>
          item.name?.toLowerCase().includes(searchTerm) ||
          item.shortName?.toLowerCase().includes(searchTerm) ||
          item.fullName?.toLowerCase().includes(searchTerm) ||
          item.cpcName?.toLowerCase().includes(searchTerm) ||
          item.combinedName?.toLowerCase().includes(searchTerm)
      )
    );
  };

  return (
    <>
      <WizardTextInputControlledComponent
        label="C-PCR name"
        tooltip="C-PCR name"
        tooltipPosition={'right'}
        value={undefined}
        placeholder="Type a C-PCR name for a quick search"
        onChange={handleSearch}
        onChanged={() => {}}
        name="title"
        maxLength={150}
      />
      <div style={{ margin: '0.5rem 0 1rem 0' }}>
        {!!filteredCpcrList.length &&
          filteredCpcrList.map((item) => (
            <PcrListItem key={item.id} className={cpcr?.id === item.id ? 'selected' : ''}>
              <PcrListItemHeader>
                <span style={{ fontWeight: 'bold', marginRight: '0.5rem' }}>{item.fullName}</span>
                <RadioButtonStyled
                  inputId={item.id}
                  name="pcrItem"
                  checked={cpcr?.id === item.id}
                  onChange={() => onPcrSelect(item)}
                />
              </PcrListItemHeader>
              {item.cpCs && (
                <div>
                  {item.cpCs.map((cpc) => (
                    <div style={{ padding: '0.5rem 0 0 0 ' }} key={cpc.id}>
                      <em>{cpc.code}</em> <span>{cpc.description}</span>
                    </div>
                  ))}
                </div>
              )}
              {item.documents &&
                item.documents?.length > 0 &&
                item?.documents?.map(
                  (file, index) =>
                    file && (
                      <div key={index}>
                        <div style={{ padding: '0.5rem 0 0 0 ' }}>
                          <span>{file?.name || t('epdWizard.wizardStep1.pcrMenu.placeholderImage')}</span>
                        </div>
                        <A target="_blank" href={FileService.getImageUrl(file?.id || '')}>
                          <DownloadButton label="Download file" icon="pi pi-download" />
                        </A>
                      </div>
                    )
                )}
            </PcrListItem>
          ))}
      </div>
    </>
  );
};

export default CPcrSelectorDetails;

import { LcaResultsAdditionalGridColumns } from './LcaResultsGridColumns';
import LcaResultsGridExtended from './LcaResultsGridExtended';

type TProps = {
  epdVersionId: string;
};

const LcaResultsGridAdditionalVoluntary = ({ epdVersionId }: TProps) => {
  const notes = [
    {
      note: 'Acronyms',
      description: '',
      placeholder: 'Acronym1 description; Acronym2  - description; etc.',
    },
  ];

  return <LcaResultsGridExtended epdVersionId={epdVersionId} columns={LcaResultsAdditionalGridColumns} notes={notes} />;
};

export default LcaResultsGridAdditionalVoluntary;

import CompilerSelectControlledComponent from 'components/v2/epd-compiler-inputs/CompilerSelectControlledComponent';
import WizardTextInputControlledComponent from 'components/v2/epd-wizard-inputs/WizardTextInputControlled';
import { toaster } from 'components/v2/toast';
import { Command, ProcessStatus, Role } from 'constants/constants';
import { CompanyContext } from 'contexts/CompanyContextProvider';
import { Dialog } from 'primereact/dialog';
import { useContext, useEffect, useState } from 'react';
import CompanyService from 'services/CompanyService';
import { CountryModel, VerificationType } from 'services/EpdClient';
import { useConnectEpdCertificationBody, useRequestCollaboration } from 'services/api/mutations';
import styled from 'styled-components';
import { Option } from 'types/types';
import { EPDVerifierCompanyModel, EPDVerifierModel } from 'types/types';

import CertificationBodiesList from './CertificationBodiesList';

type TProps = {
  epdId?: string | undefined;
  epdVersionId?: string | undefined;
  processCertificateIssuedBy?: EPDVerifierModel | undefined;
  onHide: any;
  maximizable?: boolean;
  isOpened: boolean;
};

const CertificationBodySelectorDialog = ({
  epdId,
  epdVersionId,
  processCertificateIssuedBy,
  onHide,
  maximizable,
  isOpened,
  ...props
}: TProps) => {
  const { companyId } = useContext(CompanyContext);
  const [countries, setCountries] = useState<Option[]>([]);
  const [companyBodies, setCompanyBodies] = useState<EPDVerifierCompanyModel[]>([]);
  const [filteredCompanyBodies, setFilteredCompanyBodies] = useState<EPDVerifierCompanyModel[]>([]);
  const [selectedCountry, setSelectedCountry] = useState<Option | undefined>();
  const [selectedBody, setSelectedBody] = useState<EPDVerifierCompanyModel | undefined>();
  const [inquiryStatus, setInquiryStatus] = useState<[ProcessStatus, Command]>([ProcessStatus.None, Command.None]);

  const requestCollaborationMutation = useRequestCollaboration(epdVersionId!);
  const connectBodyMutation = useConnectEpdCertificationBody(epdVersionId!);

  const onChangeBodyCountry = async (propertyName: string, val: any) => {
    if (!countries.length) return;
    setSelectedCountry(countries.find((c) => c.value === val));
  };

  const onRequestCollaboration = async (membershipId: string) => {
    try {
      setInquiryStatus([ProcessStatus.Fetching, Command.Inquiry]);
      await requestCollaborationMutation.mutateAsync({
        epdVersionId,
        roleId: Role.EPDVerifier,
        companyId: membershipId,
        verificationType: VerificationType.EPDProcessCertification,
      });
      setInquiryStatus([ProcessStatus.Success, Command.Inquiry]);
      toaster({
        severity: 'success',
        summary: 'Collaboration e-mail request was sent!',
        details: 'Please wait for a confirmation respond.',
      });
      setFilteredCompanyBodies((prev) =>
        prev.map((b) =>
          b.id === selectedBody?.id
            ? { ...b, successOperationText: 'The collaboration request was sent, you can submit a new request in 24 hours.' }
            : b
        )
      );
    } catch {
      setInquiryStatus([ProcessStatus.Error, Command.Inquiry]);
      setFilteredCompanyBodies((prev) =>
        prev.map((b) => (b.id === selectedBody?.id ? { ...b, errorText: 'Something went wrong.' } : b))
      );
    }
  };

  const onAddBody = async (bodyId: string) => {
    try {
      setInquiryStatus([ProcessStatus.Fetching, Command.Connect]);
      await connectBodyMutation.mutateAsync({ epdId, epdVersionId, bodyId });
      setInquiryStatus([ProcessStatus.Success, Command.Connect]);
      toaster({
        severity: 'success',
        summary: 'The body was added to the EPD!',
        details: 'Now the EPD body has an access to the EPD.',
      });
    } catch {
      setInquiryStatus([ProcessStatus.Error, Command.Connect]);
      setFilteredCompanyBodies((prev) =>
        prev.map((b) => (b.id === selectedBody?.id ? { ...b, errorText: 'Something went wrong.' } : b))
      );
    }
  };

  useEffect(() => {
    const fetchCountries = async () => {
      const result = await CompanyService.getCountries(Role.EPDVerifier);
      const countryOptions = result.map((country: CountryModel) => {
        return { value: country.id, label: country.name } as Option;
      });
      setCountries(countryOptions);
    };
    fetchCountries();
  }, [companyId]);

  useEffect(() => {
    const fetchCertificationBodies = async () => {
      let result = await CompanyService.getCompanyCertificationBodies(
        companyId,
        selectedCountry?.value,
        epdVersionId,
        VerificationType.EPDProcessCertification
      );
      setCompanyBodies(result);
      setFilteredCompanyBodies(result);
    };

    fetchCertificationBodies();
  }, [companyId, selectedCountry, isOpened]);

  const handleSearchBodies = (event: any) => {
    const searchTerm = event.target.value.toLowerCase();
    if (!searchTerm) {
      setFilteredCompanyBodies(companyBodies);
      return;
    }
    setFilteredCompanyBodies((prev) =>
      companyBodies.filter(
        (item) => item.name?.toLowerCase().includes(searchTerm) || item.email?.toLowerCase().includes(searchTerm)
      )
    );
  };

  return (
    <Dialog
      header="Accredited certification body"
      visible={isOpened}
      style={{ width: '40vw' }}
      contentStyle={!!companyBodies.length ? {} : { overflowY: 'initial' }}
      onHide={() => {
        setSelectedBody(undefined);
        setSelectedCountry(undefined);
        onHide();
      }}
      maximizable={maximizable}
      {...props}
    >
      <DialogContentContainer>
        <InformationContainer>
          To get in touch with one or several certification bodies please click on the button “Request collaboration”. An
          e-mail will be sent to the certification body and they will reach out to respond to your query. Once you will get
          the body’s collaboration response, you can “Add body” to your EPD.
        </InformationContainer>
        <DialogContentRow>
          <CompilerSelectControlledComponent
            label="Country"
            tooltipPosition={'right'}
            name="bodyCountry"
            options={countries}
            value={selectedCountry}
            onChanged={onChangeBodyCountry}
            placeholder="Select"
            isClearable={true}
          />
        </DialogContentRow>
        <DialogContentRow>
          <WizardTextInputControlledComponent
            label="Verifier name or e-mail"
            tooltipPosition={'right'}
            value={undefined}
            placeholder="Type here to search"
            onChange={handleSearchBodies}
            onChanged={() => {}}
            name="nameOrEmail"
            maxLength={150}
          />
        </DialogContentRow>
        <CertificationBodiesList
          certificationBody={processCertificateIssuedBy}
          bodies={filteredCompanyBodies}
          selectedVerifier={selectedBody}
          onSelectVerifier={setSelectedBody}
          onRequestCollaboration={onRequestCollaboration}
          onAddVerifier={onAddBody}
          buttonsAreDisabled={
            (inquiryStatus[0] === ProcessStatus.Fetching && inquiryStatus[1] === Command.Inquiry) ||
            (inquiryStatus[0] === ProcessStatus.Fetching && inquiryStatus[1] === Command.Connect)
          }
        />
      </DialogContentContainer>
    </Dialog>
  );
};

const InformationContainer = styled.div`
  background-color: ${(props) => props.theme.colors.elementsFilledInput};
  text-align: justify;
  padding: 1rem;
  border-radius: 4px;
`;

const DialogContentContainer = styled.div`
  padding: 0.25rem 2rem 2rem 2rem;
`;

const DialogContentRow = styled.div`
  margin-top: 25px;
`;

export default CertificationBodySelectorDialog;

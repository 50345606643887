import SmartLabel from 'components/v2/labels/SmartLabel';
import { ProcessStatus } from 'constants/constants';
import _ from 'lodash';
import { Button } from 'primereact/button';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PCRModel } from 'services/EpdClient';
import FileService from 'services/FileService';
import CPcrSelectorDetails from './CPcrSelectorDetails';
import CPcrSelectorDialog from './CPcrSelectorDialog';
import CPcrSelectorFilesDialog from './CPcrSelectorFilesDialog';
import { StyledCrossIcon, StyledDownloadIcon } from 'components/v2/epd-compiler-inputs/common-icons';

import {
  PcrListFileDownloadIcon,
  PcrListFileTitle,
  PcrListFileItem,
  AddButtonBox,
  InputTextStyled,
  PcrInputGroup,
} from '../../../../pcr-selector/styled';

interface CPcrSelectorProps {
  label?: string | undefined;
  tooltip?: string | undefined;
  tooltipPosition?: any | undefined;
  cpcr: PCRModel | undefined;
  cpcrList: PCRModel[];
  disabled: boolean | undefined;
  required: boolean | undefined;
  onChangeCPcr: (pcr: PCRModel | undefined) => void;
  onRemoveCPcr: () => void;
}

const CPcrSelector: React.FunctionComponent<CPcrSelectorProps> = ({
  label,
  tooltip,
  tooltipPosition,
  cpcr,
  cpcrList,
  disabled,
  required,
  onChangeCPcr,
  onRemoveCPcr,
}) => {
  const { t } = useTranslation();
  const [status, setStatus] = useState<ProcessStatus>(ProcessStatus.None);
  const [selectedCPcr, setSelectedCPcr] = useState<PCRModel | undefined>(cpcr);
  const [showFiles, setShowFiles] = useState<boolean>(false);
  const [isEditOpened, setIsEditOpened] = useState<boolean>(false);

  const selectPcr = (selectedCPcr: PCRModel | undefined) => {
    onChangeCPcr(selectedCPcr);
  };

  const handleCloseDialog = () => {
    setIsEditOpened(false);
    setStatus(ProcessStatus.None);
    setSelectedCPcr(undefined);
  };

  const handleSelectPcr = () => {
    selectPcr(selectedCPcr);
    handleCloseDialog();
  };

  const handleDownloadSelectedPcrFiles = (pcr: PCRModel | undefined) => {
    if (pcr?.documents && pcr.documents?.length > 1) {
      setShowFiles(true);
    } else if (pcr?.documents && pcr.documents?.length === 1) {
      window.open(FileService.getImageUrl(pcr.documents[0].id || ''), '_blank', 'noreferrer');
    }
  };

  const handleRemoveCpcr = () => {
    setSelectedCPcr(undefined);
    onRemoveCPcr();
  };

  return (
    <>
      {label && (
        <SmartLabel
          label={label}
          required={required}
          tooltip={tooltip}
          tooltipPosition={tooltipPosition}
          hasValue={!!cpcr}
        />
      )}
      <div style={{ flexGrow: 1, width: '100%', display: 'flex', flexDirection: 'column' }}>
        <PcrInputGroup className="p-inputgroup" disabled={disabled}>
          <InputTextStyled
            name="cpcr"
            value={cpcr ? cpcr.fullName : ''}
            placeholder="Add C-PCR"
            readOnly
            disabled={disabled}
          />
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ marginRight: 11 }}>{cpcr && !disabled && <StyledCrossIcon onClick={handleRemoveCpcr} />}</div>
            <div style={{ marginRight: 12 }}>
              {cpcr && !disabled && (
                <StyledDownloadIcon
                  onClick={() => {
                    handleDownloadSelectedPcrFiles(cpcr);
                  }}
                />
              )}
            </div>
          </div>
        </PcrInputGroup>
        {!disabled && (
          <AddButtonBox>
            <Button
              style={{ fontSize: 15, fontWeight: 500 }}
              type="button"
              label="+ Add C-PCR"
              text
              onClick={() => setIsEditOpened(true)}
              disabled={!!cpcr}
            />
          </AddButtonBox>
        )}

        <CPcrSelectorDialog
          header="Add Complementary Product Category Rule (C-PCR)"
          isOpened={isEditOpened}
          onHide={handleCloseDialog}
          onChange={handleSelectPcr}
          contentStyle={cpcrList?.length > 0 ? {} : { overflowY: 'initial' }}
        >
          <CPcrSelectorDetails cpcrList={cpcrList} cpcr={selectedCPcr} onPcrSelect={setSelectedCPcr} status={status} />
        </CPcrSelectorDialog>

        <CPcrSelectorFilesDialog
          header={cpcr?.fullName ?? ''}
          isOpened={showFiles}
          onHide={() => {
            setShowFiles(false);
          }}
        >
          <PcrListFileTitle>Here you can download PCR related documents.</PcrListFileTitle>
          {cpcr?.documents?.map(
            (file, index) =>
              file && (
                <PcrListFileItem key={index}>
                  <span>{file.name || t('epdWizard.wizardStep1.pcrMenu.placeholderImage')}</span>
                  <PcrListFileDownloadIcon
                    className="pi pi-download"
                    onClick={() => {
                      window.open(FileService.getImageUrl(file.id || ''), '_blank', 'noreferrer');
                    }}
                  />
                </PcrListFileItem>
              )
          )}
        </CPcrSelectorFilesDialog>
      </div>
    </>
  );
};

export default CPcrSelector;

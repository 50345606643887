import { DataTableValueArray } from 'primereact/datatable';

import LcaResultsGrid from './LcaResultsGrid';
import { LcaResultsGridColumnsNoCategory } from './LcaResultsGridColumns';

type TProps = {
  rows: DataTableValueArray | undefined;
};

const LcaResultsGridResourceConstruction = ({ rows }: TProps) => {
  const notes = [
    {
      note: 'Acronyms',
      description:
        'PERE = Use of renewable primary energy excluding renewable primary energy resources used as raw materials; PERM = Use of renewable primary energy resources used as raw materials; PERT = Total use of renewable primary energy resources; PENRE = Use of non-renewable primary energy excluding non-renewable primary energy resources used as raw materials; PENRM = Use of non-renewable primary energy resources used as raw materials; PENRT = Total use of non-renewable primary energy re-sources; SM = Use of secondary material; RSF = Use of renewable secondary fuels; NRSF = Use of non-renewable secondary fuels; FW = Use of net fresh water.',
    },
  ];

  return <LcaResultsGrid value={rows} columns={LcaResultsGridColumnsNoCategory} notes={notes} />;
};

export default LcaResultsGridResourceConstruction;

const EpdRoutes = {
  welcome: '/',
  login: '/login',
  epdCompilerPdfPreview: '/epdCompilerPdfPreview/:id',
  epdCompilerDocumentPreview: '/epdCompilerDocumentPreview/:id',
  dashboard: '/dashboard',
  pcr: {
    dashboard: '/pcrdashboard',
    create: '/pcr/:id',
    details: '/pcrdetails/:id',
  },
  epd: '/epd/:id?',
  invoice: '/invoice/:companyid/:accountId/:invoiceId',
  invoices: '/invoices/:companyid/:accountId',
  manageCompany: '/manage/:companyid',

  selectCompany: '/company/:companyId/organizations',
  selectCompanyEmpty: '/company//organizations',

  manageCollection: '/manage/:companyId/collections/:collectionId',
  manageCollections: '/manage/:companyId/collections',
  manageCollectionsEmpty: '/manage//collections',

  collections: '/collections',
  editCollection: '/collection/:collectionId',

  copyEpd: '/copyEpd',
  editCopyEpd: '/copyEpd/:epdGroupId',

  account: {
    register: {
      user: '/register/user',
      company: '/register/organisation',
    },
    forgotPassword: '/account/forgotpassword',
    resetPassword: '/account/resetpassword',
    confirmEmail: '/account/confirmemail',
  },
  admin: {
    users: {
      users: '/admin/users',
      editUser: '/admin/users/:userid',
    },
    companies: {
      companies: '/admin/companies',
      editCompany: '/admin/companies/:companyid',
    },
    invoices: {
      invoices: '/admin/invoices',
      editInvoice: '/admin/invoices/:invoiceid',
    },
    epds: {
      epds: '/admin/epds',
      editEpd: '/admin/epds/:epdid',
    },
    licensees: {
      licensees: '/admin/licensees',
    },
    pcrs: {
      pcrs: '/admin/pcrs',
      editPcr: '/admin/pcrs/:pcrid',
    },
    tools: {
      tools: '/admin/tools',
      editTool: '/admin/tools/:id',
    },
    dataSources: {
      dataSources: '/admin/dataSources',
      editDataSource: '/admin/dataSources/:id',
    },
    dictionaries: {
      dictionaries: '/admin/dictionaries',
    },
    kpis: {
      summary: '/admin/kpis/summary',
    },
    verifiers: {
      summary: '/admin/verifiers/summary',
    },
    epdsreport: {
      summary: '/admin/epdsreport/summary',
    },
    integration: {
      ilcdEpd: '/admin/integration/ilcdepd',
      ilcdEpdMapping: '/admin/integration/ilcdepd/:id',
      ilcdEpdMappingList: '/admin/integration/ilcdepdlist/:id',
      fortnoxIntegrationPage: '/admin/integration/fortnox',
      FortnoxInvoicesPage: '/admin/integration/fortnox/invoices',
    },
  },
  lcaValidation: '/lca/validation',
  param: (route: string, id: string, value: string) => {
    return route.replace(id, value); //TODO: Use EpdLinks instead?
  },
};

export const EpdLinks = {
  dashboard: () => {
    return EpdRoutes.dashboard;
  },

  detailsPcr: (id: string) => {
    return EpdRoutes.param(EpdRoutes.pcr.details, ':id', id);
  },

  manageCompany: (companyid: string) => {
    return EpdRoutes.param(EpdRoutes.manageCompany, ':companyid', companyid);
  },
  selectCompany: (companyId: string) => {
    return EpdRoutes.param(EpdRoutes.selectCompany, ':companyId', companyId);
  },
  createPcr: (id: string) => {
    return EpdRoutes.param(EpdRoutes.pcr.create, ':id', id);
  },
  epdVersionPdfPreview: (id: string) => {
    return EpdRoutes.param(EpdRoutes.epdCompilerPdfPreview, ':id', id);
  },
  epdVersionDocumentPreview: (id: string) => {
    return EpdRoutes.param(EpdRoutes.epdCompilerDocumentPreview, ':id', id);
  },
  epd: (id?: string) => {
    return EpdRoutes.param(EpdRoutes.epd, ':id?', id || '');
  },
  manageCollection: (companyId: string, collectionId: string) => {
    const route = EpdRoutes.param(EpdRoutes.manageCollection, ':companyId', companyId);
    return EpdRoutes.param(route, ':collectionId', collectionId);
  },
  manageCollections: (companyId: string) => {
    return EpdRoutes.param(EpdRoutes.manageCollections, ':companyId', companyId);
  },

  invoices: (companyId: string, accountId: string) => {
    const route = EpdRoutes.param(EpdRoutes.invoices, ':companyid', companyId);
    return EpdRoutes.param(route, ':accountId', accountId);
  },
  invoice: (companyId: string, accountId: string, invoiceId: string) => {
    let route = EpdRoutes.param(EpdRoutes.invoice, ':companyid', companyId);
    route = EpdRoutes.param(route, ':accountId', accountId);
    return EpdRoutes.param(route, ':invoiceId', invoiceId);
  },
  editCompany: (companyId: string) => {
    return EpdRoutes.param(EpdRoutes.admin.companies.editCompany, ':companyid', companyId);
  },
  editPcr: (pcrId: string) => {
    return EpdRoutes.param(EpdRoutes.admin.pcrs.editPcr, ':pcrid', pcrId);
  },
  editPreverifiedTool: (id: string) => {
    return EpdRoutes.param(EpdRoutes.admin.tools.editTool, ':id', id);
  },
  editDataSource: (id: string) => {
    return EpdRoutes.param(EpdRoutes.admin.dataSources.editDataSource, ':id', id);
  },
  editInvoice: (invoiceId: string) => {
    return EpdRoutes.param(EpdRoutes.admin.invoices.editInvoice, ':invoiceid', invoiceId);
  },
  editEpd: (epdId: string) => {
    return EpdRoutes.param(EpdRoutes.admin.epds.editEpd, ':epdid', epdId);
  },
  editUser: (userId: string) => {
    return EpdRoutes.param(EpdRoutes.admin.users.editUser, ':userid', userId);
  },

  //below routes for the v2 UI
  collections: () => {
    return EpdRoutes.collections;
  },
  editCollection: (collectionId: string) => {
    return EpdRoutes.param(EpdRoutes.editCollection, ':collectionId', collectionId);
  },
  ilcdEpdMapping: (id: string) => {
    return EpdRoutes.param(EpdRoutes.admin.integration.ilcdEpdMapping, ':id', id);
  },
  ilcdEpdMappingList: (id: string) => {
    return EpdRoutes.param(EpdRoutes.admin.integration.ilcdEpdMappingList, ':id', id);
  },
  FortnoxIntegrationPage: () => {
    return EpdRoutes.admin.integration.fortnoxIntegrationPage;
  },
  editCopyEpd: (epdGroupId: string) => {
    return EpdRoutes.param(EpdRoutes.editCopyEpd, ':epdGroupId', epdGroupId);
  },
  lcaValidation: () => {
    return EpdRoutes.lcaValidation;
  },
};

export default EpdRoutes;

import React from 'react';
import { FileModelPlus } from 'services/EpdClient';
import FileService from 'services/FileService';
import styled from 'styled-components';

interface ImageViewProps {
  images?: FileModelPlus[];
}

export const PDFImagesView: React.FunctionComponent<ImageViewProps> = ({ images }) => {
  return (
    <div>
      {images &&
        images.map((img) => (
          <ImageBox key={img.id}>
            <img alt={`Flow: ${img}`} src={FileService.getImageUrl(img.id)} />
          </ImageBox>
        ))}
    </div>
  );
};

const ImageBox = styled.div`
  width: auto;
  min-width: 80px;
  max-width: 20cm;
  max-height: 20cm;
  display: flex;
  flex-wrap: wrap;
  background-color: ${(props) => props.theme.colors.primaryWhite};
  img {
    max-width: 100%;
    max-height: 100%;
  }
  margin-bottom: 30px;
`;

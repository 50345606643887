import React, { ChangeEvent, useState, useEffect } from 'react';
import { LanguageModel, UploadDocumentTypesModel } from 'services/EpdClient';
import { createFilter } from 'react-select';
import { EPDModel, FileLoadingModel } from 'services/EpdClient';
import styled from 'styled-components';
import { FieldPanelFullWidth } from 'styles/v2/Styles.styled';
import { Option } from 'types/types';
import CompilerSelectControlledComponent from '../epd-compiler-inputs/CompilerSelectControlledComponent';
import { HelpBox } from '../help-boxes';
import { ButtonUploadIcon } from '../icons';
import { addEpdFile, useAddEpdDocument, useRemoveEpdDocument } from 'services/api/mutations';
import UploadDocumentTypesService from 'services/UploadDocumentTypesService';
import LanguagesService from 'services/LanguagesService';
import FilesList from 'components/v2/file-row/FilesList';
import FileUpload from 'components/v2/file-upload/FileUpload';

type OptionWithCode = Option & { code?: string };
const EPDDocumentsTab: React.FunctionComponent<{
  epd?: EPDModel;
  epdVersionId: string;
  onChangeEpd: any;
  canUpload: boolean;
  isReadOnly: boolean;
  fileLoading?: FileLoadingModel[];
}> = ({ epd, epdVersionId, onChangeEpd, canUpload, isReadOnly, fileLoading }) => {
  const [documentTypeList, setDocumentTypes] = React.useState<UploadDocumentTypesModel[]>([]);
  const [languageList, setLanguages] = React.useState<LanguageModel[]>([]);

  const [documentType, setDocumentType] = useState<Option>();
  const [documentLanguage, setDocumentLanguage] = useState<OptionWithCode>();

  const addEPDDocumentMutation = useAddEpdDocument(epd?.id!);
  const removeEPDDocumentMutation = useRemoveEpdDocument(epd?.id!, epdVersionId);

  const onRemoveEPDDocument = async (removeCommandAlias: string, fileId: string) => {
    removeEPDDocumentMutation.mutate(fileId);
  };

  useEffect(() => {
    const fetchDocumentType = async () => {
      const res = await UploadDocumentTypesService.getDocumentTypes();
      setDocumentTypes(res);
      return res;
    };
    const fetchLanguages = async () => {
      const result = await LanguagesService.getLanguages();
      setLanguages(result);
    };

    fetchLanguages();
    fetchDocumentType();
  }, []);

  const filterConfig = {
    ignoreCase: true,
    ignoreAccents: true,
    trim: true,
    matchFrom: 'start' as const,
  };

  const renameAndUploadFile = async (e: ChangeEvent<HTMLInputElement>, propertyName: string) => {
    const target = e.target as HTMLInputElement;
    let file = target && target.files && target.files[0];

    if (!file || !epd) {
      return null;
    }

    if (documentType?.value !== '2489c33d-56f6-4fe7-b5cd-a94e82900708') {
      propertyName = 'DocumentOther';
    }

    let newName = renameDocument(file?.name.split('.').pop() || '');
    await addEpdFile(
      { epdId: epd.id!, propertyName, file: new File([file], newName, { type: file.type }) },
      addEPDDocumentMutation
    );

    // @ts-ignore
    setDocumentType(null);
    // @ts-ignore
    setDocumentLanguage(null);
  };

  // TODO make proper file upload component, this code just copied
  const renameDocument = (fileExtension: string) => {
    let dublicates = 0;
    let newName = '';
    let nameFound = false;
    while (!nameFound) {
      let numDoc = dublicates === 0 ? '' : `(${dublicates})`;
      newName = `${documentType?.label} ${
        epd?.registrationNumber
      } ${documentLanguage?.code?.toLocaleUpperCase()}${numDoc}.${fileExtension}`;
      if (
        epd?.documents &&
        epd.documents.filter((x) => x.name?.toLocaleLowerCase() === newName.toLocaleLowerCase()).length > 0
      ) {
        dublicates += 1;
      } else nameFound = true;
    }

    return newName;
  };

  return (
    <FieldPanelFullWidth style={{ gridRowGap: 0 }}>
      <HelpBox>
        <span>
          Please upload EPD related documents in PDF format. Several documents can be uploaded.
          <br />
          Document type and language should be specified before uploading.
        </span>
      </HelpBox>
      <DoubleFieldPanel style={{ marginTop: 30 }}>
        <CompilerSelectControlledComponent
          label="Document type"
          tooltip="EPD realated documents."
          name="docType"
          options={documentTypeList?.map((item) => {
            return {
              value: item.id,
              label: item.name,
            } as Option;
          })}
          required={!documentType && !epd?.documents?.length}
          value={documentType}
          onChanged={(name: string, value: string) => {
            if (!value) {
              setDocumentType(undefined);
              return;
            }
            setDocumentType({ value: value, label: documentTypeList.find((x) => x.id == value)?.name || value });
          }}
          isClearable={true}
        />
        <CompilerSelectControlledComponent
          label="Document language"
          tooltip="Specify the language of the provided document."
          name="docLang"
          options={languageList?.map((item) => {
            return {
              value: item.id,
              label: item.name,
              code: item.code,
            } as OptionWithCode;
          })}
          required={!documentLanguage && !epd?.documents?.length}
          value={documentLanguage}
          onChanged={(name: string, value: string) => {
            setDocumentLanguage({
              value: value,
              label: languageList.find((x) => x.id == value)?.name || value,
              code: languageList.find((x) => x.id == value)?.code,
            });
          }}
          isClearable={false}
          filterOption={createFilter(filterConfig)}
        />
      </DoubleFieldPanel>
      <FileUpload
        label="Upload document"
        name="Document"
        icon={ButtonUploadIcon}
        accept=".pdf"
        validExtensions={['pdf']}
        onFileUpload={renameAndUploadFile}
        disabled={!(documentLanguage && documentType)}
      />
      <FilesList
        files={epd?.documents}
        onRemoveFile={onRemoveEPDDocument}
        fileLoading={
          fileLoading?.find(
            (loading) =>
              (loading.fileType === 'Document' || loading.fileType === 'DocumentOther') && loading.loadingsCount > 0
          ) !== undefined
        }
      />
    </FieldPanelFullWidth>
  );
};

const DoubleFieldPanel = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 30px;
  grid-row-gap: 0px;
  justify-content: start;

  ${(props) => props.theme.media.tablet} {
    grid-template-columns: 1fr 1fr;
  }

  ${(props) => props.theme.media.desktop} {
    grid-template-columns: 1fr 1fr;
  }
`;

export default EPDDocumentsTab;

import React, { useContext, useEffect, useState } from 'react';
import { Command, ProcessStatus, Role } from 'constants/constants';
import { CompanyContext } from 'contexts/CompanyContextProvider';
import { Button } from 'primereact/button';
import { CountryModel, DeveloperCompanyModel, MembershipModel } from 'services/EpdClient';
import { EPDDeveloperModel } from 'services/EpdClient';
import { Option } from 'types/types';
import { MultiValue } from 'react-select';
import _ from 'lodash';
import MembershipService from 'services/MembershipService';
import CompilerSelectControlledComponent from 'components/v2/epd-compiler-inputs/CompilerSelectControlledComponent';
import LcaPractitionerSelectorDialog from './LcaPractitionersSelectorDialog';
import { useConnectEpdLcaPractitioner, useRequestCollaboration } from 'services/api/mutations';
import { FindButtonBox } from 'components/v2/form/styled';
import { toaster } from 'components/v2/toast';
import CompanyService from 'services/CompanyService';

interface LcaPractitionersSelectorProps {
  epdId: string;
  epdVersionId: string;
  practitioners?: EPDDeveloperModel[] | undefined;
  onChanged: any;
}

const LcaPractitionersSelector: React.FunctionComponent<LcaPractitionersSelectorProps> = ({
  epdId,
  epdVersionId,
  practitioners,
  onChanged,
}) => {
  const { companyId } = useContext(CompanyContext);
  const [showSelectDialog, setShowSelectDialog] = useState<boolean>(false);
  const [epdDeveloperCompanies, setEpdDeveloperCompanies] = useState<DeveloperCompanyModel[]>([]);
  const [epdDevelopers, setEpdDevelopers] = useState<MembershipModel[]>([]);
  const [inquiryStatus, setInquiryStatus] = useState<[ProcessStatus, Command]>([ProcessStatus.None, Command.None]);
  const [filteredEpdDeveloperCompanies, setFilteredEpdDeveloperCompanies] = useState<DeveloperCompanyModel[]>([]);
  const [selectedEpdDeveloperCompany, setSelectedEpdDeveloperCompany] = useState<DeveloperCompanyModel | undefined>();
  const [selectedCountry, setSelectedCountry] = useState<Option | undefined>();
  const [countries, setCountries] = useState<Option[]>([]);

  const connectLcaPractitionerMutation = useConnectEpdLcaPractitioner(epdVersionId);
  const requestCollaborationMutation = useRequestCollaboration(epdVersionId!);

  const fetchEpdDevelopers = async () => {
    const developers = await MembershipService.getMembershipsByCompanyAndRole(companyId!, Role.EPDDeveloper);
    const owners = await MembershipService.getMembershipsByCompanyAndRole(companyId!, Role.EPDOwner);
    setEpdDevelopers([...developers, ...owners]);
  };

  useEffect(() => {
    fetchEpdDevelopers();
  }, [companyId]);

  useEffect(() => {
    const fetchEpdDeveloperCompanies = async () => {
      const res = await CompanyService.getDevelopers(undefined, selectedCountry?.value, epdVersionId);
      const companies = [...res].sort((a, b) => ((a.name ?? '') > (b.name ?? '') ? 1 : -1));
      setEpdDeveloperCompanies(companies);
      setFilteredEpdDeveloperCompanies(companies);
    };

    fetchEpdDeveloperCompanies();
  }, [selectedCountry]);

  useEffect(() => {
    const fetchCountries = async () => {
      const result = await CompanyService.getCountries(Role.EPDDeveloper);
      const countryOptions = result.map((country: CountryModel) => {
        return { value: country.id, label: country.name } as Option;
      });
      setCountries(countryOptions);
    };
    fetchCountries();
  }, [companyId]);

  const handleCloseDialog = () => {
    setShowSelectDialog(false);
    setEpdDeveloperCompanies([]);
  };

  const handleSelectPcr = () => {
    handleCloseDialog();
  };

  const changeEpdDevelopers = (_: string, developers: MultiValue<Option>) => {
    if (Array.isArray(developers)) {
      const selectedEpdDevelopers = epdDevelopers.filter((x) => developers.includes(x.userId)).map((x) => x.id);
      onChanged('EPDDevelopers', selectedEpdDevelopers);
    } else {
      onChanged('EPDDevelopers', undefined);
    }
  };

  const onAddExternalPractitioner = async (membershipId: string) => {
    try {
      setInquiryStatus([ProcessStatus.Fetching, Command.Connect]);
      await connectLcaPractitionerMutation.mutateAsync({ epdId, membershipId });
      setInquiryStatus([ProcessStatus.Success, Command.Connect]);
      fetchEpdDevelopers();
      toaster({
        severity: 'success',
        summary: 'The LCA practitioner was added to the EPD!',
        details: 'Now the LCA practitioner has an access to the EPD.',
      });
    } catch {
      setInquiryStatus([ProcessStatus.Error, Command.Connect]);
      setFilteredEpdDeveloperCompanies((prev) =>
        prev.map((c) => (c.id === selectedEpdDeveloperCompany?.id ? { ...c, errorText: 'Something went wrong.' } : c))
      );
    }
  };

  const onRequestCollaboration = async (companyId: string) => {
    try {
      setInquiryStatus([ProcessStatus.Fetching, Command.Inquiry]);
      await requestCollaborationMutation.mutateAsync({
        epdVersionId,
        roleId: Role.EPDDeveloper,
        companyId,
      });
      setInquiryStatus([ProcessStatus.Success, Command.Inquiry]);
      toaster({
        severity: 'success',
        summary: 'Collaboration e-mail request was sent!',
        details: 'Please wait for a confirmation respond.',
      });
      setFilteredEpdDeveloperCompanies((prev) =>
        prev.map((c) =>
          c.id === selectedEpdDeveloperCompany?.id
            ? { ...c, successOperationText: 'The collaboration request was sent, you can submit a new request in 24 hours.' }
            : c
        )
      );
    } catch {
      setInquiryStatus([ProcessStatus.Error, Command.Inquiry]);
      setFilteredEpdDeveloperCompanies((prev) =>
        prev.map((c) => (c.id === selectedEpdDeveloperCompany?.id ? { ...c, errorText: 'Something went wrong.' } : c))
      );
    }
  };

  const handleSearchVerifierCompanies = (event: any) => {
    const searchTerm = event.target.value.toLowerCase();
    if (!searchTerm) {
      setFilteredEpdDeveloperCompanies(epdDeveloperCompanies);
      return;
    }
    setFilteredEpdDeveloperCompanies((prev) =>
      epdDeveloperCompanies.filter(
        (item) =>
          item.name?.toLowerCase().includes(searchTerm) ||
          item.members?.some((m) => m.email?.toLowerCase().includes(searchTerm))
      )
    );
  };

  const onChangePractitionerCountry = async (propertyName: string, val: any) => {
    if (!countries.length) return;
    setSelectedCountry(countries.find((c) => c.value === val));
  };

  const selectedPractitioners = practitioners?.map(
    (dev: any) => ({ label: dev.contactName, value: dev.contactUserId } as Option)
  );

  const practitionersOptions = epdDevelopers.map((user) => {
    return {
      value: user.userId,
      label: `${user.userName} (${user.roleName})`,
    } as Option;
  });

  return (
    <>
      <div style={{ flexGrow: 1, width: '100%', display: 'flex', flexDirection: 'column' }}>
        <CompilerSelectControlledComponent
          label="LCA practitioner"
          tooltip="The person accountable for underlying Life cycle assessment."
          name="epdDevelopers"
          options={practitionersOptions}
          value={selectedPractitioners}
          onChanged={changeEpdDevelopers}
          placeholder="Select"
          isMulti
          required
        />
        <FindButtonBox>
          <Button
            style={{ fontSize: 15, fontWeight: 500 }}
            type="button"
            label="+ Find LCA Practitioner"
            text
            onClick={() => setShowSelectDialog(true)}
            disabled={false}
          />
        </FindButtonBox>
        <LcaPractitionerSelectorDialog
          epdId={epdId}
          epdVersionId={epdVersionId}
          companyId={companyId}
          selectedPractitioners={selectedPractitioners}
          companyPractitioners={practitionersOptions}
          filteredEpdDeveloperCompanies={filteredEpdDeveloperCompanies}
          handleSearchVerifierCompanies={handleSearchVerifierCompanies}
          inquiryStatus={inquiryStatus}
          isOpened={showSelectDialog}
          onHide={handleCloseDialog}
          onChange={handleSelectPcr}
          onAddPractitioner={onAddExternalPractitioner}
          onRequestCollaboration={onRequestCollaboration}
          onChangePractitionerCountry={onChangePractitionerCountry}
          contentStyle={epdDeveloperCompanies?.length > 0 ? {} : { overflowY: 'initial' }}
          selectedEpdDeveloperCompany={selectedEpdDeveloperCompany}
          setSelectedEpdDeveloperCompany={setSelectedEpdDeveloperCompany}
          countries={countries}
          selectedCountry={selectedCountry}
        />
      </div>
    </>
  );
};

export default LcaPractitionersSelector;

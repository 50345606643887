import stepStatusAttention from 'assets/images/icons/status/step/attention.svg';
import styled from 'styled-components';
import { StyledInput } from 'styles/v2/Styles.styled';

import SmartLabel, { TSmartLabelProps } from '../labels/SmartLabel';

type TProps = Partial<TSmartLabelProps> & {
  value: any;
  name: string;
  onChanged: any;
  onChange?: any;
  disabled?: boolean;
  required?: boolean;
  style?: any;
  placeholder?: string;
  type?: string;
} & React.InputHTMLAttributes<HTMLInputElement>;
const WizardTextInputControlledComponent: React.FunctionComponent<TProps> = ({
  label,
  tooltip,
  tooltipPosition,
  labelStyle,
  name,
  value,
  onChanged,
  onChange,
  disabled,
  style,
  placeholder,
  type,
  required,
  error,
  ...props
}) => {
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        padding: 0,
        margin: 0,
        ...style,
      }}
    >
      {label && (
        <SmartLabel
          label={label}
          required={required}
          labelStyle={labelStyle}
          tooltip={tooltip}
          tooltipPosition={tooltipPosition}
          hasValue={!!value}
          error={error}
        />
      )}
      <StyledInput
        {...props}
        style={style}
        type={type}
        value={value}
        onBlur={(e: React.ChangeEvent<HTMLInputElement>) => onChanged(name, value)}
        onChange={onChange}
        disabled={disabled}
        placeholder={disabled ? undefined : placeholder}
      />
      {!label && required && !value && <Image src={stepStatusAttention} alt="!" />}
    </div>
  );
};

const Image = styled.img`
  position: absolute;
  right: 13px;
  top: 13px;
`;

export default WizardTextInputControlledComponent;

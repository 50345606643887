import React from 'react';
import { FieldPanelFullWidth, FieldPanelHeader } from 'styles/v2/Styles.styled';
import { FieldPanelInput } from 'styles/v2/Styles.styled';
import { useGetEpdThirdPartyVerification } from 'services/api/queries';
import {
  addEpdFile,
  useAddEPDProcessCertificate,
  useUpdateEpdThirdPartyVerification,
  useRemoveEPDProcessCertificate,
} from 'services/api/mutations';
import { VerificationType, PreverifiedTool, PreverifiedToolVersion } from 'services/EpdClient';
import { EPDVerifierModel, Option, VerifierType } from 'types/types';
import { YesNoDataSource } from '../../constants';
import CompilerSelectControlledComponent from 'components/v2/epd-compiler-inputs/CompilerSelectControlledComponent';
import EpdVerifierSelector from './epd-verifier-selector/EpdVerifierSelector';
import CertificateSelector from './certificate-selector/CertificateSelector';
import CertificationBodySelector from './certification-body-selector/CertificationBodySelector';
import WizardTextInputControlledComponent from 'components/v2/epd-wizard-inputs/WizardTextInputControlled';

const ThirdPartyVerificationPanel: React.FunctionComponent<{
  epdId: string;
  epdVersionId: string;
}> = ({ epdId, epdVersionId }) => {
  const thirdPartyVerification = useGetEpdThirdPartyVerification(epdVersionId).data;
  const updateMutation = useUpdateEpdThirdPartyVerification(epdVersionId);

  const addProcessCertificateMutation = useAddEPDProcessCertificate(epdVersionId);
  const deleteProcessCertificateMutation = useRemoveEPDProcessCertificate(epdVersionId);

  const onChangeEpdThirdPartyVerification = async (propertyName: string, val: any) => {
    updateMutation.mutate({ epdId, versionId: epdVersionId, propertyName: propertyName, newValue: val });
  };

  const onAddEpdProcessCertificate = async (propertyName: string, file: File) => {
    await addEpdFile({ epdId, propertyName, file }, addProcessCertificateMutation);
  };

  const onRemoveProcessCertificate = async (_: string, fileId: string) => {
    deleteProcessCertificateMutation.mutate(fileId);
  };

  const handleVerifierChange = async (_: string, value: any) => {
    if (!value) {
      onChangeEpdThirdPartyVerification('epdVerifier', undefined);
      return;
    }
    onChangeEpdThirdPartyVerification('epdVerifier', value);
  };

  const handleCertificateIssuedByChange = async (_: string, value: any) => {
    if (!value) {
      onChangeEpdThirdPartyVerification('processCertificateIssuedBy', undefined);
      return;
    }
    onChangeEpdThirdPartyVerification('processCertificateIssuedBy', value);
  };

  const getVerificationTypeLabel = (verificationType: string) => {
    switch (verificationType) {
      case VerificationType.EPDVerification:
        return 'Individual EPD verification';
      case VerificationType.EPDProcessCertification:
        return 'EPD Process Certification';
      case VerificationType.EPDPreverifiedTool:
        return 'EPD Pre-verified tool';
      default:
        throw new Error('Invalid verification type has been passed.');
    }
  };

  const isDisabledVerificationType = (verificationType: string) => {
    switch (verificationType) {
      case VerificationType.EPDVerification:
        return false;
      case VerificationType.EPDProcessCertification:
        return true;
      case VerificationType.EPDPreverifiedTool:
        return true;
      default:
        return true;
    }
  };

  const selectedVerificationType = thirdPartyVerification?.verificationType
    ? {
        value: thirdPartyVerification.verificationType,
        label: getVerificationTypeLabel(thirdPartyVerification.verificationType),
      }
    : null;

  const selectedPreverifiedTool = thirdPartyVerification?.preverifiedTool
    ? {
        value: thirdPartyVerification.preverifiedTool.id,
        label: thirdPartyVerification.preverifiedTool.name,
      }
    : null;

  const preverifiedToolVersions = selectedPreverifiedTool
    ? thirdPartyVerification?.preVerifiedToolDataSource
        ?.find((t) => t.id === selectedPreverifiedTool.value)
        ?.preverifiedToolVersions?.map((toolVersion: PreverifiedToolVersion) => {
          return {
            value: toolVersion.id,
            label: toolVersion.name,
          } as Option;
        })
    : [];

  const selectedPreverifiedToolVersion = thirdPartyVerification?.preverifiedToolVersion
    ? {
        value: thirdPartyVerification.preverifiedToolVersion.id,
        label: thirdPartyVerification.preverifiedToolVersion.name,
      }
    : null;

  const renderVerificationTypeInputs = () => {
    if (!thirdPartyVerification?.verificationTypeDataSource || !selectedVerificationType?.value) return;
    switch (selectedVerificationType.value) {
      case VerificationType.EPDVerification:
        return renderIndividualVerifierInputs();
      case VerificationType.EPDProcessCertification:
        return renderProcessCertificationInputs();
      case VerificationType.EPDPreverifiedTool:
        return renderPreverifiedToolInputs();
      default:
        throw new Error('Invalid verification type has been passed.');
    }
  };

  const getApprovedOrAccreditedByLabel = (verifier: EPDVerifierModel) => {
    if (verifier.verifierType === VerifierType.Body) {
      return 'Certification body accredited by';
    }
    return 'Individual verifier approved by';
  };

  const renderIndividualVerifierInputs = () => (
    <>
      <FieldPanelInput>
        <EpdVerifierSelector
          epdId={epdId}
          epdVersionId={epdVersionId}
          epdVerifier={thirdPartyVerification?.epdVerifier}
          onChangeVerifier={handleVerifierChange}
        />
      </FieldPanelInput>
      {!!thirdPartyVerification?.epdVerifier && (
        <FieldPanelInput>
          <WizardTextInputControlledComponent
            label={getApprovedOrAccreditedByLabel(thirdPartyVerification.epdVerifier)}
            name="verifierApprovedBy"
            tooltip={getApprovedOrAccreditedByLabel(thirdPartyVerification.epdVerifier)}
            value={'The International EPD® System'}
            onChanged={undefined}
            disabled
          />
        </FieldPanelInput>
      )}
    </>
  );

  const renderProcessCertificationInputs = () => (
    <>
      <FieldPanelInput>
        <CertificateSelector
          processCertificates={thirdPartyVerification?.processCertificates ?? []}
          onFileSelected={onAddEpdProcessCertificate}
          onRemoveCertificate={onRemoveProcessCertificate}
        />
      </FieldPanelInput>
      <FieldPanelInput>
        <CertificationBodySelector
          epdId={epdId}
          epdVersionId={epdVersionId}
          processCertificateIssuedBy={thirdPartyVerification?.processCertificateIssuedBy}
          onChangeBody={handleCertificateIssuedByChange}
        />
      </FieldPanelInput>
      {!!thirdPartyVerification?.processCertificateIssuedBy && (
        <FieldPanelInput>
          <WizardTextInputControlledComponent
            label="Certification body accredited by"
            name="bodyAccreditedBy"
            tooltip="Certification body accredited by"
            value={thirdPartyVerification?.epdVerifier?.information ?? 'The International EPD® System'}
            onChanged={undefined}
            disabled
          />
        </FieldPanelInput>
      )}
    </>
  );

  const renderPreverifiedToolVerifierInformation = () => {
    if (!thirdPartyVerification?.preverifiedTool && !thirdPartyVerification?.preverifiedToolVersion) {
      return null;
    }

    const preverifiedToolVerifierName =
      thirdPartyVerification?.preverifiedToolVersion?.verifierName ?? thirdPartyVerification?.preverifiedTool?.verifierName;

    if (!!thirdPartyVerification?.preverifiedToolVerifierAccreditedBy) {
      return (
        <>
          <FieldPanelInput>
            <WizardTextInputControlledComponent
              label="Tool verifier"
              tooltip="Third-party verifier accountable for the tool verification."
              name="toolVerifier"
              value={preverifiedToolVerifierName}
              onChanged={undefined}
              disabled
            />
          </FieldPanelInput>
          <FieldPanelInput>
            <WizardTextInputControlledComponent
              label="Tool verifier accredited by"
              name="preverifiedToolVerifierAccreditedBy"
              value={thirdPartyVerification.preverifiedToolVerifierAccreditedBy}
              onChanged={undefined}
              disabled
            />
          </FieldPanelInput>
        </>
      );
    }

    return (
      <>
        <FieldPanelInput>
          <WizardTextInputControlledComponent
            label="Tool verifier"
            tooltip="Third-party verifier accountable for the tool verification."
            name="toolVerifier"
            value={preverifiedToolVerifierName}
            onChanged={undefined}
            disabled
          />
        </FieldPanelInput>
        <FieldPanelInput>
          <WizardTextInputControlledComponent
            label="Tool verifier approved by"
            name="preverifiedToolVerifierApprovedBy"
            value={'The International EPD® System'}
            onChanged={undefined}
            disabled
          />
        </FieldPanelInput>
      </>
    );
  };

  const renderPreverifiedToolInputs = () => (
    <>
      <FieldPanelInput>
        <CompilerSelectControlledComponent
          label="Pre-verified tool"
          tooltip="A pre-verified EPD tool contains data and calculation models to simplify the LCA calculation procedure based on a reference PCR. It is pre-verified to ensure that it produces correct data, given the correct input."
          name="preverifiedTool"
          options={
            thirdPartyVerification?.preVerifiedToolDataSource?.map((tool: PreverifiedTool) => {
              return {
                value: tool.id,
                label: tool.name,
              } as Option;
            }) ?? []
          }
          value={selectedPreverifiedTool}
          onChanged={onChangeEpdThirdPartyVerification}
          placeholder="Select"
          isClearable={true}
          required
        />
      </FieldPanelInput>
      <FieldPanelInput>
        <CompilerSelectControlledComponent
          label="Tool version"
          name="preverifiedToolVersion"
          options={preverifiedToolVersions}
          value={selectedPreverifiedToolVersion}
          onChanged={onChangeEpdThirdPartyVerification}
          placeholder="Select"
          isClearable={true}
          required
        />
      </FieldPanelInput>
      {renderPreverifiedToolVerifierInformation()}
      <FieldPanelInput>
        <EpdVerifierSelector
          epdId={epdId}
          epdVersionId={epdVersionId}
          epdVerifier={thirdPartyVerification?.epdVerifier}
          onChangeVerifier={handleVerifierChange}
        />
      </FieldPanelInput>
      {!!thirdPartyVerification?.epdVerifier && (
        <FieldPanelInput>
          <WizardTextInputControlledComponent
            label={getApprovedOrAccreditedByLabel(thirdPartyVerification.epdVerifier)}
            name="verifierApprovedBy"
            tooltip={getApprovedOrAccreditedByLabel(thirdPartyVerification.epdVerifier)}
            value={'The International EPD® System'}
            onChanged={undefined}
            disabled
          />
        </FieldPanelInput>
      )}
    </>
  );

  const isOptionDisabled = (option: any) => {
    return option.disabled;
  };

  return (
    <FieldPanelFullWidth>
      <FieldPanelHeader style={{ display: 'flex', alignItems: 'center' }}>Third Party Verification</FieldPanelHeader>
      <FieldPanelInput>
        <CompilerSelectControlledComponent
          label="Verification type"
          tooltip="EPDs must be independently verified before registration and publication."
          name="verificationType"
          options={
            thirdPartyVerification?.verificationTypeDataSource.map((value: VerificationType) => {
              return {
                value: value,
                label: getVerificationTypeLabel(value),
                disabled: isDisabledVerificationType(value),
              } as Option;
            }) ?? []
          }
          value={selectedVerificationType}
          onChanged={onChangeEpdThirdPartyVerification}
          placeholder="Select"
          isClearable={true}
          isOptionDisabled={isOptionDisabled}
          required
        />
      </FieldPanelInput>
      {renderVerificationTypeInputs()}
      <FieldPanelInput>
        <CompilerSelectControlledComponent
          label="Procedure for follow-up of data during EPD validity involves third party verifier"
          tooltip="Procedure for follow-up of data during EPD validity involves third party verifier"
          name="followUpInvolvesThirdPartyVerifier"
          options={YesNoDataSource}
          value={YesNoDataSource.find((item) => item.value === thirdPartyVerification?.followUpInvolvesThirdPartyVerifier)}
          onChanged={onChangeEpdThirdPartyVerification}
          isClearable={false}
          required
        />
      </FieldPanelInput>
    </FieldPanelFullWidth>
  );
};

export default ThirdPartyVerificationPanel;

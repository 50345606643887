import { DataTableValueArray } from 'primereact/datatable';

import LcaResultsGridPdf from './LcaResultsGridPdf';
import { LcaResultsGridColumnsNoCategoryPdf } from './LcaResultsGridColumns';

type TProps = {
  rows: DataTableValueArray | undefined;
};

const LcaResultsGridOutputFlowConstructionPdf = ({ rows }: TProps) => {
  const notes = [
    {
      note: 'Acronyms',
      description:
        'CRU = Components for re-use; MFR = Materials for recycling; MER = Materials for energy recovery; EEE = Exported electrical energy; EET = Exported thermal energy.',
    },
  ];

  return <LcaResultsGridPdf value={rows} columns={LcaResultsGridColumnsNoCategoryPdf} notes={notes} />;
};

export default LcaResultsGridOutputFlowConstructionPdf;

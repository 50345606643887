import React from 'react';
import { PDF_H2, PDF_P, PDF_PAGE } from './Styles.styled';

const AdditionalInfoPage: React.FunctionComponent<{
  e: any;
}> = ({ e }) => {
  return (
    <PDF_PAGE>
      <PDF_H2>Version history</PDF_H2>
      {/* <PDF_P>{additionalInformation.versionHistory}</PDF_P> */}

      <PDF_H2>Additional environmental information</PDF_H2>
      <PDF_P dangerouslySetInnerHTML={{ __html: e.additionalInformation?.environmental ?? '' }} />

      <PDF_H2>Conversion factors</PDF_H2>
      <PDF_P dangerouslySetInnerHTML={{ __html: e.additionalInformation?.conversion ?? '' }} />

      <PDF_H2>Dangerous substance to indoor air, soil, and water during the use stage</PDF_H2>
      <PDF_P dangerouslySetInnerHTML={{ __html: e.additionalInformation?.dangerous ?? '' }} />

      <PDF_H2>Economic and social information</PDF_H2>
      <PDF_P dangerouslySetInnerHTML={{ __html: e.additionalInformation?.economic ?? '' }} />
    </PDF_PAGE>
  );
};

export default AdditionalInfoPage;

import {
  CompaniesClient,
  FortnoxIntegrationsClient,
  CreateCompanyModel,
  CompanyModel,
  CountriesClient,
  UpdateCompanyPropertyModel,
  FileParameter,
  VerificationType,
} from './EpdClient';

const client = new CompaniesClient(process.env.REACT_APP_API_URL);
const countriesClient = new CountriesClient(process.env.REACT_APP_API_URL);
const fortnoxIntegrationsClient = new FortnoxIntegrationsClient(process.env.REACT_APP_API_URL);

class CompanyService {
  static findCompanies = async (name: string) => {
    return await client.getAll(name, null, undefined, null);
  };

  static createCompany = async (values: CreateCompanyModel) => {
    return await client.create(values);
  };

  static getCompanies = async () => {
    return await client.getAll(null, null, true, null);
  };

  static getSubsidiaries = async () => {
    return await client.getAll(null, null, true, null);
  };

  static getCompaniesByRole = async (roleId: string, countryId?: string) => {};

  static getCompanyVerifiers = async (
    companyId?: string,
    countryId?: string,
    epdVersionId?: string,
    verificationType?: VerificationType
  ) => {
    return await client.getVerifiers(null, companyId, countryId, epdVersionId, verificationType);
  };

  static getCompanyIndividualVerifiers = async (
    companyId?: string,
    countryId?: string,
    epdVersionId?: string,
    verificationType?: VerificationType
  ) => {
    return await client.getIndividualVerifiers(null, companyId, countryId, epdVersionId, verificationType);
  };

  static getCompanyCertificationBodies = async (
    companyId?: string,
    countryId?: string,
    epdVersionId?: string,
    verificationType?: VerificationType
  ) => {
    return await client.getCertificationBodies(null, companyId, countryId, epdVersionId, verificationType);
  };

  static getCompany = async (companyId: string) => {
    return await client.get(companyId);
  };

  static getLinkableCompanies = async (companyId?: string) => {
    return await client.getLinkableCompanies(companyId);
  };

  static updateProperty = async (companyId: string, propertyName: string, newValue: any) => {
    const updatedParent: UpdateCompanyPropertyModel = { companyId, propertyName, newValue };
    const result = await client.updateCompanyProperty(updatedParent);
    return result;
  };

  static updateCompany = async (company: CompanyModel) => {
    return company.id && (await client.update(company.id, company));
  };

  static getCountries = async (roleId?: string) => {
    return await countriesClient.getAll(null, roleId, null, null);
  };

  static getDevelopers = async (companyId?: string, countryId?: string, epdVersionId?: string) => {
    return await client.getDevelopers(null, companyId, countryId, epdVersionId);
  };

  static updateLogotype = async (companyId: string, file?: FileParameter) => {
    return await client.updateLogotype(companyId, companyId, file?.fileName, file);
  };

  static getLogotype = async (companyId: string) => {
    return await client.getCompanyLogotype(companyId);
  };

  static getLogotypeUrl = (companyId: string) => {
    return (process.env.REACT_APP_API_URL ?? 'https://localhost:44380') + `/api/v1/Companies/${companyId}/logotype`;
  };

  static getAccessToEdit = async (companyId: string) => {
    return await client.getAccessToEdit(companyId);
  };

  static getCompanyLicensees = async (companyId?: string) => {
    return await client.getLicensees(companyId);
  };

  static sendCompanyToFortnox = async (companyId?: string) => {
    return await fortnoxIntegrationsClient.send(companyId ?? '');
  };
}

export default CompanyService;

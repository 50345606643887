import CompilerSelectControlledComponent from 'components/v2/epd-compiler-inputs/CompilerSelectControlledComponent';
import WizardTextInputComponent from 'components/v2/epd-wizard-inputs/WizardTextInput';
import TooltipHelpIcon from 'components/v2/icons/TooltipHelpIcon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useUpsertEpdConversionFactor } from 'services/api/mutations';
import { useEpdConversionFactor } from 'services/api/queries';
import { FieldPanel, FieldPanelFullWidth, FieldPanelHeader } from 'styles/v2/Styles.styled';
import { ConversionFactorModel, DictionaryValue, Option } from 'types/types';

const ConversionFactorSection: React.FunctionComponent<{
  epdVersionId: string;
  unitDataSource: DictionaryValue[];
  errors? : any;
}> = ({ epdVersionId, unitDataSource, errors }) => {
  const { t } = useTranslation();
  const conversionFactorData = useEpdConversionFactor(epdVersionId!).data;
  const upsertEpdConversionFactor = useUpsertEpdConversionFactor(epdVersionId);

  const unitOptions = unitDataSource.map((x: DictionaryValue) => {
    return {
      value: x.value,
      label: x.description,
    } as Option;
  });

  const selectedConversionFactorUnit = unitOptions.find(
    (x: Option) => x.value.toLowerCase() === conversionFactorData?.unitId?.toLowerCase()
  );

  const onChangeConversionFactor = async (propertyName: string, val: any) => {
    const newData = { ...conversionFactorData, [propertyName]: val } as ConversionFactorModel;
    upsertEpdConversionFactor.mutate(newData);
  };

  return (
    <FieldPanel>
      <FieldPanelHeader className="w-full" style={{ display: 'flex', alignItems: 'center' }}>
        {t(`epdCompiler.step3.lcaSpecificationTab.properties.conversionFactor.title`)}{' '}
        <TooltipHelpIcon content={t(`epdCompiler.step3.lcaSpecificationTab.properties.conversionFactor.tooltip`)} />
      </FieldPanelHeader>
      <CompilerSelectControlledComponent
        label={t(`epdCompiler.step3.lcaSpecificationTab.properties.conversionFactor.unit`)}
        name="unitId"
        options={unitOptions}
        required
        value={selectedConversionFactorUnit}
        onChanged={onChangeConversionFactor}
        placeholder="Select"
        isClearable={false}
        error={errors?.conversionFactorUnit}
      />
      <WizardTextInputComponent
        label={t(`epdCompiler.step3.lcaSpecificationTab.properties.conversionFactor.value`)}
        name="value"
        required
        placeholder="Type number"
        value={conversionFactorData?.value}
        onChanged={onChangeConversionFactor}
        error={errors?.conversionFactorValue}
      />
    </FieldPanel>
  );
};

export default ConversionFactorSection;

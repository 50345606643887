import { version } from 'react';
import { ScenarioModuleRequest } from 'types/types';

import { QueryFunction, useQuery } from '@tanstack/react-query';

import { QUERY_KEYS } from './constants';
import {
  getCompanyCollectionsLookup,
  getConversionFactor,
  getCountriesLookup,
  getCpcCodes,
  parseAndSaveLCAFile,
  getDangerMaterialsLookup,
  getDictionaryCatalog,
  getDictionaryValues,
  getDocumentTypes,
  getElectricity,
  getEpd,
  getEpdActionsStates,
  getEpdAdditionalInformation,
  getEpdBestProductComponents,
  getEpdBestProductPackagingMaterials,
  getEpdBestProductPanelContent,
  getEpdCO2UptakeAssociation,
  getEpdCoverPageInformation,
  getEpdDataSourceOptions,
  getEpdDataSources,
  getEpdDeclarationsOfCO2,
  getEpdFormat,
  getEpdGeneralInformation,
  getEPDDefinitionModel,
  getEpdLcaResults,
  getEpdLcaResultsAdditional,
  getEpdLcaSupport,
  getEpdOrganizationInformation,
  getEpdOrganizationInformationAddress,
  getEpdPCRTypeInformation,
  getEpdProductComponents,
  getEpdProductDangerMaterials,
  getEpdProductInformation,
  getEpdProductPackagingMaterials,
  getEpdProductPanelContent,
  getEpdScenarioModule,
  getEpdScenarioUnits,
  getEpdScenarios,
  getEpdScrapInputs,
  getEpdShareOfTotalScrapInput,
  getEpdSystemBoundary,
  getEpdSystemBoundaryGrid,
  getEpdThirdPartyVerification,
  getEpdTransportationPerformance,
  getEpdVerificationReportInfo,
  getGeographicalScopeLookup,
  getLCASoftwareOptions,
  getLCASpecification,
  getLanguages,
  getReferenceFlow,
  getTags,
  getValidationState,
  getCompanyUsersLookup,
  getCompanyVerifiers,
} from './epdApi';

export const useDictionaryCatalog = () => {
  return useQuery({
    queryKey: [QUERY_KEYS.EPD_DICTIONARY_CATALOG],
    queryFn: () => getDictionaryCatalog(),
    refetchOnWindowFocus: false,
  });
};

export const useDictionaryValues = (dictionaryCode?: string) => {
  return useQuery({
    queryKey: [QUERY_KEYS.EPD_DICTIONARY_VALUES, dictionaryCode],
    queryFn: () => getDictionaryValues(dictionaryCode),
    refetchOnWindowFocus: false,
  });
};

export const useEpdCollectionsLookup = (companyId: string) => {
  return useQuery({
    queryKey: [QUERY_KEYS.EPD_COLLECTIONS_LOOKUP, companyId],
    queryFn: () => getCompanyCollectionsLookup(companyId),
    refetchOnWindowFocus: false,
    enabled: !!companyId,
  });
};

export const useCompanyUsersLookup = (companyId: string) => {
  return useQuery({
    queryKey: [QUERY_KEYS.EPD_COMPANY_USERS_LOOKUP, companyId],
    queryFn: () => getCompanyUsersLookup(companyId),
    refetchOnWindowFocus: false,
    enabled: !!companyId,
  });
};

export const useEpd = (epdId: string) => {
  return useQuery({
    queryKey: [QUERY_KEYS.EPD, epdId],
    queryFn: () => getEpd(epdId),
    refetchOnWindowFocus: false,
    enabled: !!epdId,
  });
};

export const useEpdFormat = (epdId: string) => {
  return useQuery({
    queryKey: [QUERY_KEYS.EPD_FORMAT, epdId],
    queryFn: () => getEpdFormat(epdId),
    refetchOnWindowFocus: false,
    enabled: !!epdId,
  });
};

const baseQuery = <TData, TError>(versionId: string, queryKey: string[], queryFn: (versionId: string) => Promise<TData>) => {
  return useQuery({
    queryKey: queryKey.includes(versionId) ? queryKey : [...queryKey, versionId],
    queryFn: () => queryFn(versionId),
    refetchOnWindowFocus: false,
    enabled: !!versionId,
  });
};

export const useEPDDefinitionInformation = (versionId: string) => {
  return useQuery({
    queryKey: [QUERY_KEYS.EPD_DEFINITION, versionId],
    queryFn: () => getEPDDefinitionModel(versionId),
    refetchOnWindowFocus: false,
    enabled: !!versionId,
  });
};

export const useServerValidationQuery = (versionId: string) => {
  return baseQuery(versionId, [QUERY_KEYS.EPD_SERVER_VALIDATION], getValidationState);
};

export const useEpdGeneralInformation = (versionId: string) => {
  return baseQuery(versionId, [QUERY_KEYS.EPD_GENERAL_INFORMATION], getEpdGeneralInformation);
};

export const useEpdActionsStates = (epdId: string) => {
  return useQuery({
    queryKey: [QUERY_KEYS.EPD_ACTIONS_STATES, epdId],
    queryFn: () => getEpdActionsStates(epdId),
    refetchOnWindowFocus: false,
    enabled: !!epdId,
  });
};

export const useEpdVerificationReportInfo = (versionId: string) => {
  return useQuery({
    queryKey: [QUERY_KEYS.EPD_VERIFICATION_REPORT_INFO, versionId],
    queryFn: () => getEpdVerificationReportInfo(versionId),
    refetchOnWindowFocus: false,
    enabled: !!versionId,
  });
};

export const useLanguages = () => {
  return useQuery({
    queryKey: [QUERY_KEYS.LANGUAGES],
    queryFn: () => getLanguages(),
    refetchOnWindowFocus: false,
  });
};

export const useDocumentTypes = () => {
  return useQuery({
    queryKey: [QUERY_KEYS.DOCUMENT_TYPES],
    queryFn: () => getDocumentTypes(),
    refetchOnWindowFocus: false,
  });
};

export const useEpdOrganizationInformation = (versionId: string) => {
  return useQuery({
    queryKey: [QUERY_KEYS.EPD_ORGANIZATION_INFORMATION, versionId],
    queryFn: () => getEpdOrganizationInformation(versionId),
    refetchOnWindowFocus: false,
    enabled: !!versionId,
  });
};

export const useEpdAdditionalInformation = (versionId: string) => {
  return useQuery({
    queryKey: [QUERY_KEYS.EPD_ADDITIONAL_INFORMATION, versionId],
    queryFn: () => getEpdAdditionalInformation(versionId),
    refetchOnWindowFocus: false,
    enabled: !!versionId,
  });
};

export const useEpdProductInformation = (versionId: string) => {
  return useQuery({
    queryKey: [QUERY_KEYS.EPD_PRODUCT_INFORMATION, versionId],
    queryFn: () => getEpdProductInformation(versionId),
    refetchOnWindowFocus: false,
    enabled: !!versionId,
  });
};

export const useUnCpcCodes = (data: any) => {
  return useQuery({
    queryKey: [QUERY_KEYS.UN_CPC_CODES],
    queryFn: () => getCpcCodes(data),
    refetchOnWindowFocus: false,
  });
};

export const useEpdCoverPageInformation = (versionId: string) => {
  return useQuery({
    queryKey: [QUERY_KEYS.EPD_COVER_PAGE_INFORMATION, versionId],
    queryFn: () => getEpdCoverPageInformation(versionId),
    refetchOnWindowFocus: false,
    enabled: !!versionId,
  });
};

export const useGetEpdPCRInformation = (versionId: string) => {
  return useQuery({
    queryKey: [QUERY_KEYS.EPD_PCR_INFORMATION, versionId],
    queryFn: () => getEpdPCRTypeInformation(versionId),
    refetchOnWindowFocus: false,
    enabled: !!versionId,
  });
};

export const useGetEpdLcaSupport = (versionId: string) => {
  return useQuery({
    queryKey: [QUERY_KEYS.EPD_LCA_SUPPORT, versionId],
    queryFn: () => getEpdLcaSupport(versionId),
    refetchOnWindowFocus: false,
    enabled: !!versionId,
  });
};

export const useGetEpdThirdPartyVerification = (versionId: string) => {
  return useQuery({
    queryKey: [QUERY_KEYS.EPD_THIRD_PARTY_VERIFICATION, versionId],
    queryFn: () => getEpdThirdPartyVerification(versionId),
    refetchOnWindowFocus: false,
    enabled: !!versionId,
  });
};

export const useEpdOrganizationInformationAddress = (versionId: string) => {
  return useQuery({
    queryKey: [QUERY_KEYS.EPD_ORGANIZATION_INFORMATION_ADDRESS, versionId],
    queryFn: () => getEpdOrganizationInformationAddress(versionId),
    refetchOnWindowFocus: false,
    enabled: !!versionId,
  });
};

export const useTagSource = () => {
  return useQuery({
    queryKey: [QUERY_KEYS.TAG_SOURCE],
    queryFn: () => getTags(),
    refetchOnWindowFocus: false,
  });
};

export const useEpdSystemBoundaryData = (epdVersionId: string) => {
  return useQuery({
    queryKey: [QUERY_KEYS.EPD_SYSTEM_BOUNDARY, epdVersionId],
    queryFn: () => getEpdSystemBoundary(epdVersionId),
    refetchOnWindowFocus: false,
    enabled: !!epdVersionId,
  });
};

export const useEpdProductComponents = (epdVersionId: string) => {
  return useQuery({
    queryKey: [QUERY_KEYS.EPD_PRODUCT_COMPONENTS, epdVersionId],
    queryFn: () => getEpdProductComponents(epdVersionId),
    refetchOnWindowFocus: false,
    enabled: !!epdVersionId,
  });
};

export const useEpdBestProductComponents = (epdVersionId: string, productId: string | null) => {
  return useQuery({
    queryKey: [QUERY_KEYS.EPD_BEST_PRODUCT_COMPONENTS, epdVersionId],
    queryFn: () => getEpdBestProductComponents(epdVersionId, productId),
    refetchOnWindowFocus: false,
    enabled: !!productId && !!epdVersionId,
  });
};

export const useEpdBestProductPackagingMaterials = (epdVersionId: string, productId: string | null) => {
  return useQuery({
    queryKey: [QUERY_KEYS.EPD_BEST_PRODUCT_PACKAGING_MATERIALS, epdVersionId],
    queryFn: () => getEpdBestProductPackagingMaterials(epdVersionId, productId),
    refetchOnWindowFocus: false,
    enabled: !!epdVersionId,
  });
};

export const useEpdProductPackagingMaterials = (epdVersionId: string) => {
  return useQuery({
    queryKey: [QUERY_KEYS.EPD_PRODUCT_PACKAGING_MATERIALS, epdVersionId],
    queryFn: () => getEpdProductPackagingMaterials(epdVersionId),
    refetchOnWindowFocus: false,
    enabled: !!epdVersionId,
  });
};

export const useEpdBestProductPanelContent = (epdVersionId: string, productId: string | null) => {
  return useQuery({
    queryKey: [QUERY_KEYS.EPD_BEST_PRODUCT_PANEL_CONTENT, epdVersionId],
    queryFn: () => getEpdBestProductPanelContent(epdVersionId, productId),
    refetchOnWindowFocus: false,
    enabled: !!epdVersionId,
  });
};

export const useEpdProductPanelContent = (epdVersionId: string) => {
  return useQuery({
    queryKey: [QUERY_KEYS.EPD_PRODUCT_PANEL_CONTENT, epdVersionId],
    queryFn: () => getEpdProductPanelContent(epdVersionId),
    refetchOnWindowFocus: false,
    enabled: !!epdVersionId,
  });
};

export const useEpdProductDangerMaterials = (epdVersionId: string) => {
  return useQuery({
    queryKey: [QUERY_KEYS.EPD_PRODUCT_DANGER_MATERIALS, epdVersionId],
    queryFn: () => getEpdProductDangerMaterials(epdVersionId),
    refetchOnWindowFocus: false,
    enabled: !!epdVersionId,
  });
};

export const useDangerMaterialsLookup = () => {
  return useQuery({
    queryKey: [QUERY_KEYS.DANGER_MATERIALS_LOOKUP],
    queryFn: () => getDangerMaterialsLookup(),
    refetchOnWindowFocus: false,
  });
};

export const useGetLCASpecification = (epdVersionId: string) => {
  return useQuery({
    queryKey: [QUERY_KEYS.EPD_LCA_SPECIFICATION, epdVersionId],
    queryFn: () => getLCASpecification(epdVersionId),
    refetchOnWindowFocus: false,
    enabled: !!epdVersionId,
  });
};

export const useEpdReferenceFlow = (epdVersionId: string) => {
  return useQuery({
    queryKey: [QUERY_KEYS.EPD_REFERENCE_FLOW, epdVersionId],
    queryFn: () => getReferenceFlow(epdVersionId),
    refetchOnWindowFocus: false,
    enabled: !!epdVersionId,
  });
};

export const useEpdConversionFactor = (epdVersionId: string) => {
  return useQuery({
    queryKey: [QUERY_KEYS.EPD_CONVERSION_FACTOR, epdVersionId],
    queryFn: () => getConversionFactor(epdVersionId),
    refetchOnWindowFocus: false,
    enabled: !!epdVersionId,
  });
};

export const useEpdDataSources = (epdVersionId: string) => {
  return useQuery({
    queryKey: [QUERY_KEYS.EPD_DATA_SOURCES, epdVersionId],
    queryFn: () => getEpdDataSources(epdVersionId),
    refetchOnWindowFocus: false,
    enabled: !!epdVersionId,
  });
};

export const useEpdDataSourceOptions = () => {
  return useQuery({
    queryKey: [QUERY_KEYS.EPD_DATA_SOURCE_OPTIONS],
    queryFn: () => getEpdDataSourceOptions(),
    refetchOnWindowFocus: false,
  });
};

export const useLCASoftwareOptions = () => {
  return useQuery({
    queryKey: [QUERY_KEYS.EPD_LCA_SOFTWARE_OPTIONS],
    queryFn: () => getLCASoftwareOptions(),
    refetchOnWindowFocus: false,
  });
};

export const useGetEpdCO2UptakeAssociation = (epdVersionId: string) => {
  return useQuery({
    queryKey: [QUERY_KEYS.EPD_CO2_UPTAKE_ASSOCIATION, epdVersionId],
    queryFn: () => getEpdCO2UptakeAssociation(epdVersionId),
    refetchOnWindowFocus: false,
    enabled: !!epdVersionId,
  });
};

export const useGetEpdDeclarationsOfCO2 = (epdVersionId: string) => {
  return useQuery({
    queryKey: [QUERY_KEYS.EPD_DECLARATIONS_OF_CO2, epdVersionId],
    queryFn: () => getEpdDeclarationsOfCO2(epdVersionId),
    refetchOnWindowFocus: false,
    enabled: !!epdVersionId,
  });
};

export const useGetEpdScrapInputs = (epdVersionId: string) => {
  return useQuery({
    queryKey: [QUERY_KEYS.EPD_SCRAP_INPUTS, epdVersionId],
    queryFn: () => getEpdScrapInputs(epdVersionId),
    refetchOnWindowFocus: false,
    enabled: !!epdVersionId,
  });
};

export const useGetEpdShareOfTotalScrapInput = (epdVersionId: string) => {
  return useQuery({
    queryKey: [QUERY_KEYS.EPD_SHARE_OF_TOTAL_SCRAP_INPUT, epdVersionId],
    queryFn: () => getEpdShareOfTotalScrapInput(epdVersionId),
    refetchOnWindowFocus: false,
    enabled: !!epdVersionId,
  });
};

export const useElectricity = (epdVersionId: string) => {
  return useQuery({
    queryKey: [QUERY_KEYS.EPD_ELECTRICITY, epdVersionId],
    queryFn: () => getElectricity(epdVersionId),
    refetchOnWindowFocus: false,
    enabled: !!epdVersionId,
  });
};

export const useGeographicalScopeLookup = () => {
  return useQuery({
    queryKey: [QUERY_KEYS.GEOGRAPHICAL_SCOPE_LOOKUP],
    queryFn: () => getGeographicalScopeLookup(),
    refetchOnWindowFocus: false,
  });
};

export const useCountriesLookup = () => {
  return useQuery({
    queryKey: [QUERY_KEYS.FOUNDATION_COUNTRIES],
    queryFn: () => getCountriesLookup(),
    refetchOnWindowFocus: false,
  });
};

export const useEpdScenarios = (epdVersionId: string) => {
  return useQuery({
    queryKey: [QUERY_KEYS.EPD_SCENARIOS, epdVersionId],
    queryFn: () => getEpdScenarios(epdVersionId),
    refetchOnWindowFocus: false,
    enabled: !!epdVersionId,
  });
};

export const useEpdScenarioModule = (request: ScenarioModuleRequest) => {
  return useQuery({
    queryKey: [QUERY_KEYS.EPD_MODULE, request],
    queryFn: () => getEpdScenarioModule(request),
    refetchOnWindowFocus: false,
    enabled: !!request?.scenarioId && !!request?.module,
  });
};

export const useEpdScenarioUnits = () => {
  return useQuery({
    queryKey: [QUERY_KEYS.EPD_SCENARIO_UNITS],
    queryFn: () => getEpdScenarioUnits(),
    refetchOnWindowFocus: false,
  });
};

export const useEpdLcaResultsAdditional = (epdVersionId: string) => {
  return useQuery({
    queryKey: [QUERY_KEYS.EPD_LCA_RESULTS_ADDITIONAL, epdVersionId],
    queryFn: () => getEpdLcaResultsAdditional(epdVersionId),
    refetchOnWindowFocus: false,
    enabled: !!epdVersionId,
  });
};

export const useEpdLcaResultsParse = (epdVersionId: string) => {
  return useQuery({
    queryKey: [QUERY_KEYS.EPD_LCA_RESULTS, epdVersionId],
    queryFn: () => parseAndSaveLCAFile(epdVersionId),
    refetchOnWindowFocus: false,
    enabled: !!epdVersionId,
  });
};

export const useEpdLcaResults = (epdVersionId: string) => {
  return useQuery({
    queryKey: [QUERY_KEYS.EPD_LCA_RESULTS, epdVersionId],
    queryFn: () => getEpdLcaResults(epdVersionId),
    refetchOnWindowFocus: false,
    enabled: !!epdVersionId,
  });
};

export const useEpdSystemBoundaryGrid = (epdVersionId: string) => {
  return useQuery({
    queryKey: [QUERY_KEYS.EPD_LCA_DECLARED_MODULES, epdVersionId],
    queryFn: () => getEpdSystemBoundaryGrid(epdVersionId),
    refetchOnWindowFocus: false,
    enabled: !!epdVersionId,
  });
};

export const useEpdLcaTransportation = (epdVersionId: string) => {
  return useQuery({
    queryKey: [QUERY_KEYS.EPD_LCA_TRANSPORTATION, epdVersionId],
    queryFn: () => getEpdTransportationPerformance(epdVersionId),
    refetchOnWindowFocus: false,
    enabled: !!epdVersionId,
  });
};

export const useCompanyVerifiers = (companyId: string) => {
  return useQuery({
    queryKey: [QUERY_KEYS.EPD_COMPANY_VERIFIERS],
    queryFn: () => getCompanyVerifiers(companyId),
    refetchOnWindowFocus: false,
  });
};

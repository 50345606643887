import { REMOVE_FILE } from 'constants/constants';
import downloadIcon from 'images/icons/svg/download.svg';
import { isEmpty } from 'lodash';
import { useState } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { FileModel, LanguageModel, UploadDocumentTypesModel } from 'services/EpdClient';
import FileService from 'services/FileService';
import styled from 'styled-components';
import { defaultThemeColors } from 'styles/theme';

const UploadFile = (props: any) => {
  const [selectedDocType, setSelectedDocType] = useState<string | undefined>();
  const [selectedLang, setSelectedLang] = useState<string | undefined>();

  const { t } = useTranslation();
  const inputRef = React.createRef();

  let withAdditionalProperties = !isEmpty(props.languageList) && !isEmpty(props.documentTypeList);

  const selectFile = () => {
    if (withAdditionalProperties && (isEmpty(selectedLang) || isEmpty(selectedDocType))) {
      toast.error(t('messages.uploadFileLangNeeded') as string, { position: 'top-center' });
      return;
    }

    if (props.singleFile && props.documents?.length > 0) {
      toast.error(t('messages.uploadFileSingle') as string, { position: 'top-center' });
      return;
    }

    (inputRef as any).current.click();
  };

  const upload = (e: any, validExtensions: Array<string>) => {
    if (validExtensions?.length > 0) {
      for (const file of e.target.files) {
        const fileExtension = file.name.split('.').pop().toLowerCase();
        if (!props.validExtensions.includes(fileExtension)) {
          toast.error(`Please upload file having extensions ${validExtensions.join('/')} only.`, { position: 'top-center' });
          return false;
        }
      }
    }
    if (withAdditionalProperties) {
      props.uploadFile(e, props.field, selectedDocType, selectedLang);
      return;
    }
    props.uploadFile(e, props.field);
  };

  const renderUploadLabel = () => {
    const additionalPropertiesNotEmpty = !!selectedDocType && !!selectedLang;
    if (props.disabled) return null;
    if (withAdditionalProperties) {
      return (
        <UploadLabel
          style={{
            cursor: 'pointer',
            background: additionalPropertiesNotEmpty ? defaultThemeColors.orange : defaultThemeColors.lightOrange,
            pointerEvents: additionalPropertiesNotEmpty ? 'auto' : 'none',
          }}
          onClick={() => selectFile()}
        >
          {t('upload')}
        </UploadLabel>
      );
    }
    return (
      <UploadLabel style={{ cursor: 'pointer' }} onClick={() => selectFile()}>
        {t('upload')}
      </UploadLabel>
    );
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <FileBox>
        {props.maxSize ? (
          <h3 style={{ fontSize: '14px' }}> {props.title} (max&nbsp;10&nbsp;MB)</h3>
        ) : (
          <h3 style={{ fontSize: '14px' }}> {props.title}</h3>
        )}
        <div>
          <input
            ref={inputRef as any}
            type="file"
            accept={props.accept}
            value={''}
            onChange={(e) => upload(e, props.validExtensions)}
            disabled={props.disabled}
            style={{ visibility: 'hidden', display: 'none' }}
          />

          {!isEmpty(props.documents) && (
            <FileContainer>
              {props?.documents?.map((file: any) => (
                <FileRow
                  file={file}
                  onChangeFile={props.onChangeFile}
                  onRemoveFile={props.onRemoveFile}
                  disabled={props.disabled}
                />
              ))}
            </FileContainer>
          )}

          {withAdditionalProperties && (
            <FileContainer>
              <MaterialGrid>
                <MaterialColumn1>
                  Document type
                  <Select
                    options={props?.documentTypeList?.map((x: UploadDocumentTypesModel) => {
                      return { value: x.id, label: x.name } as Option;
                    })}
                    styles={SelectStyle}
                    isClearable
                    onChange={(e) => setSelectedDocType((e as Option)?.value)}
                  />
                </MaterialColumn1>
                <MaterialColumn2>
                  Language
                  <Select
                    options={props?.languageList?.map((x: LanguageModel) => {
                      return { value: x.id, label: x.name } as Option;
                    })}
                    styles={SelectStyle}
                    isClearable
                    onChange={(e) => setSelectedLang((e as Option)?.value)}
                  />
                </MaterialColumn2>
              </MaterialGrid>
            </FileContainer>
          )}
          {renderUploadLabel()}
        </div>
      </FileBox>
    </div>
  );
};

export const FileRow: React.FunctionComponent<{
  file?: FileModel;
  onChangeFile?: any;
  onRemoveFile?: any;
  disabled: boolean;
}> = ({ file, onChangeFile, onRemoveFile, disabled }) => {
  const { t } = useTranslation();
  if (!file || !file.id) {
    return null;
  }

  const onRemove = async () => {
    if (!file || !file.id) {
      return null;
    }

    onChangeFile && onChangeFile(REMOVE_FILE, file?.id);
    onRemoveFile && onRemoveFile(file?.id);
  };

  const fileUrl = FileService.getImageUrl(file?.id || '');

  return (
    <FileBoxRow>
      {file && (
        <div>
          <a href={fileUrl} title="Download file" target="_blank" rel="noopener noreferrer">
            <img alt="Download file" src={downloadIcon} style={{ width: '20px', margin: '0 0.5rem', cursor: 'pointer' }} />
          </a>
        </div>
      )}
      <div style={{ margin: '0.5rem 0' }}>
        <Text>{file?.name} </Text>{' '}
      </div>

      {!disabled && (
        <RemoveBox>
          <button onClick={onRemove}>{t('remove')}</button>
        </RemoveBox>
      )}
    </FileBoxRow>
  );
};

export default UploadFile;

const FileContainer = styled.div`
  border-top: 2px solid theme.colors.darkGray;
  margin-bottom: 1rem;
`;

const UploadLabel = styled.label`
  font-size: 0.8rem;
  padding: 0.5rem;
  margin: 0.5rem 0;
  background: ${(props) => props.theme.colors.orange};
  border-radius: 5px;
  color: white;

  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none;

  :hover {
    background: ${(props) => props.theme.colors.lightOrange};
  }
`;

export const docxOrPdfType = () => {
  return '.docx' || '.pdf';
};

export const FileBox = styled.div`
  background-color: ${(props) => props.theme.colors.regionColorLight};
  padding: 1rem 3rem;
  flex: 1 0 auto;

  > h3 {
    margin-top: 0;
  }
`;

const FileBoxRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: white;
  font-size: 14px;
`;

const Text = styled.span`
  max-width: fit-content;
`;

const RemoveBox = styled.div`
  margin-left: auto;
  padding-right: 0.5rem;

  > button {
    cursor: pointer;
    font-size: 0.8rem;
    padding: 0.3rem;
    margin: 0.5rem 0;
    background: rgb(105, 105, 105);
    border-radius: 5px;
    color: white;
    :hover {
      background: rgb(128, 128, 128);
    }
  }
`;

export type Option = {
  label: string;
  value: string;
};

const Column = styled.div`
  align-self: center;
  width: 100%;
`;

const MaterialGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1rem;
  grid-row-gap: 0.5rem;
  width: 100%;
`;

const MaterialColumn1 = styled(Column)`
  grid-column-start: 1;
  grid-column-end: 2;
`;

const MaterialColumn2 = styled(Column)`
  grid-column-start: 2;
  grid-column-end: 3;
`;
const MaterialColumn3 = styled(Column)`
  grid-column-start: 3;
  grid-column-end: 4;
`;
const MaterialColumn4 = styled(Column)`
  justify-self: end;
  grid-column-start: 4;
  grid-column-end: 5;
`;

export const SelectStyle = {
  menu: (styles: any) => ({ ...styles, zIndex: 999 }),
  control: (styles: any) => ({
    ...styles,
    borderRadius: 0,
    borderColor: 'gray',
    color: 'black',
    fontSize: '12px',
    minHeight: '30px',
    minWidth: '20px',
    width: '100%',
    background: 'white',
  }),
  multiValueLabel: (styles: any) => ({
    ...styles,
    fontSize: '100%',
  }),
};

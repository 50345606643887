import React from 'react';
import { EPDModel } from 'services/EpdClient';
import PcrInformationPanel from './panels/pcr-information/PcrInformationPanel';
import LcaSupportPanel from './panels/lca-support/LcaSupportPanel';
import ThirdPartyVerificationPanel from './panels/third-party-verification/ThirdPartyVerificationPanel';

const PcrLcaAndVerificationTab: React.FunctionComponent<{
  epd: EPDModel;
  epdVersionId: string;
  validationState? : any;
}> = ({ epd, epdVersionId, validationState }) => {
  return (
    <>
      <PcrInformationPanel epdId={epd?.id!} epdVersionId={epdVersionId} validationState={validationState}/>
      <LcaSupportPanel epdId={epd?.id!} epdVersionId={epdVersionId} />
      <ThirdPartyVerificationPanel epdId={epd?.id!} epdVersionId={epdVersionId} />
    </>
  );
};

export default PcrLcaAndVerificationTab;

import { Dialog } from 'primereact/dialog';
import { DialogContentContainer } from '../../../../pcr-selector/styled';
import { ProcessStatus } from 'constants/constants';
import { DeveloperCompanyModel } from 'services/EpdClient';
import { Option } from 'types/types';
import { Command } from 'constants/constants';
import CompilerSelectControlledComponent from 'components/v2/epd-compiler-inputs/CompilerSelectControlledComponent';
import WizardTextInputControlledComponent from 'components/v2/epd-wizard-inputs/WizardTextInputControlled';
import LcaCompaniesList from './LcaCompaniesList';
import InviteExternalPractitionerTextInput from './InviteExternalPractitionerTextInput';
import styled from 'styled-components';

type TProps = {
  epdId: string;
  epdVersionId: string;
  companyId?: string | undefined;
  selectedPractitioners?: Option[] | undefined;
  companyPractitioners?: Option[] | undefined;
  filteredEpdDeveloperCompanies: DeveloperCompanyModel[];
  inquiryStatus: [ProcessStatus, Command];
  isOpened: boolean;
  onHide: any;
  maximizable?: boolean;
  onChange: any;
  onAddPractitioner: any;
  onRequestCollaboration: any;
  handleSearchVerifierCompanies: any;
  onChangePractitionerCountry: any;
  contentStyle?: any;
  selectedEpdDeveloperCompany?: DeveloperCompanyModel | undefined;
  setSelectedEpdDeveloperCompany: any;
  countries?: Option[] | undefined;
  selectedCountry?: Option | undefined;
};

const LcaPractitionersSelectorDialog = ({
  epdId,
  epdVersionId,
  companyId,
  selectedPractitioners,
  companyPractitioners,
  filteredEpdDeveloperCompanies,
  inquiryStatus,
  isOpened,
  onHide,
  maximizable,
  onChange,
  onAddPractitioner,
  onRequestCollaboration,
  handleSearchVerifierCompanies,
  onChangePractitionerCountry,
  contentStyle,
  selectedEpdDeveloperCompany,
  setSelectedEpdDeveloperCompany,
  countries,
  selectedCountry,
  ...props
}: TProps) => {
  return (
    <Dialog
      header={'LCA Practitioner'}
      visible={isOpened}
      style={{ width: '40vw' }}
      contentStyle={!!filteredEpdDeveloperCompanies.length ? {} : { overflowY: 'initial' }}
      onHide={onHide}
      maximizable={maximizable}
      {...props}
    >
      <DialogContentContainer>
        <CompilerSelectControlledComponent
          label="Country"
          name="verifierCountry"
          options={countries}
          value={selectedCountry}
          onChanged={onChangePractitionerCountry}
          placeholder="Select"
          isClearable={true}
        />
        <DialogContentRow>
          <WizardTextInputControlledComponent
            label="Organization name or e-mail address"
            value={undefined}
            placeholder="Type here to search"
            onChange={handleSearchVerifierCompanies}
            onChanged={() => {}}
            name="nameOrEmail"
            maxLength={150}
          />
        </DialogContentRow>
        <LcaCompaniesList
          selectedPractitioners={selectedPractitioners}
          companyPractitioners={companyPractitioners}
          companies={filteredEpdDeveloperCompanies}
          selectedCompany={selectedEpdDeveloperCompany}
          onSelectCompany={setSelectedEpdDeveloperCompany}
          onRequestCollaboration={onRequestCollaboration}
          onAddPractitioner={onAddPractitioner}
          buttonsAreDisabled={
            (inquiryStatus[0] === ProcessStatus.Fetching && inquiryStatus[1] === Command.Inquiry) ||
            (inquiryStatus[0] === ProcessStatus.Fetching && inquiryStatus[1] === Command.Connect)
          }
        />
        <DialogContentRow>
          <InformationContainer>
            Can't find your LCA practitioner in the list? Invite a member! Send invitation to the EPD Portal and share the
            access to your EPD.
          </InformationContainer>
          <InviteExternalPractitionerTextInput companyId={companyId} />
        </DialogContentRow>
      </DialogContentContainer>
    </Dialog>
  );
};

const InformationContainer = styled.div`
  background-color: ${(props) => props.theme.colors.elementsFilledInput};
  text-align: justify;
  padding: 1rem;
  border-radius: 4px;
  margin-bottom: 10px;
`;

const DialogContentRow = styled.div`
  margin-top: 25px;
`;

export default LcaPractitionersSelectorDialog;

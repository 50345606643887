import { DataTableValueArray } from 'primereact/datatable';

import { LcaResultsGridColumnsPdf } from './LcaResultsGridColumns';
import LcaResultsGridPdf from './LcaResultsGridPdf';

type TProps = {
  rows: DataTableValueArray | undefined;
};

const LcaResultsGridAdditionalMandatoryPdf = ({ rows }: TProps) => {
  const notes = [
    {
      note: 'Acronyms',
      description: 'GWP-GHG = Global warming potential greenhouse gas.',
    },
    {
      note: 'Note',
      description:
        'The GWP-GHG indicator is termed GWP-IOBC/GHG in the ILCD+EPD+ data format. The indicator accounts for all greenhouse gases except biogenic carbon dioxide uptake and emissions and biogenic carbon stored in the product. As such, the indicator is identical to GWP-total except that the CF for biogenic CO2 is set to zero.',
    },
  ];

  return <LcaResultsGridPdf value={rows} columns={LcaResultsGridColumnsPdf} notes={notes} />;
};

export default LcaResultsGridAdditionalMandatoryPdf;

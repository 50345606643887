import 'primeflex/primeflex.css';

import { confirmDelete } from 'components/v2/confirm-dialog/ConfirmDelete';
import { toaster } from 'components/v2/toast';
import { ProcessStatus } from 'constants/constants';
import { CompanyContext } from 'contexts/CompanyContextProvider';
import { AddIcon, FilterIcon, SettingsIcon } from 'images/icons/svg';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Menu } from 'primereact/menu';
import { MenuItem } from 'primereact/menuitem';
import { Panel, PanelHeaderTemplateOptions } from 'primereact/panel';
import { ProgressSpinner } from 'primereact/progressspinner';
import React, { useContext, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import EpdRoutes from 'routes/EpdRoutes';
import { AdminLicenseeModel, CompanyListModel } from 'services/EpdClient';
import AdminLicenseesService from 'services/admin/AdminLicenseesService';
import styled from 'styled-components';

import LicenseeThemeIcon from './LicenseeThemeIcon';

type TProps = {
  licensee: AdminLicenseeModel;
  fetchLicensees: () => {};
  handleEditLicensee: () => void;
};

const LicenseeItem = ({ licensee, fetchLicensees, handleEditLicensee }: TProps) => {
  const { changeCompany } = useContext(CompanyContext);
  const menu = useRef<Menu>(null);
  const [data, setData] = useState<CompanyListModel[]>([]);
  const [status, setStatus] = useState<ProcessStatus>(ProcessStatus.None);

  const fetchData = async () => {
    setStatus(ProcessStatus.Fetching);
    try {
      const result = await AdminLicenseesService.getLicenseeCompanies(licensee.id);
      setData(result);
      setStatus(ProcessStatus.Success);
    } catch (error) {
      console.error(error);
      setStatus(ProcessStatus.Error);
    }
  };

  const acceptLicenseeDelete = async () => {
    try {
      await AdminLicenseesService.deleteLicensee(licensee.id);
      fetchLicensees();
      toaster({ severity: 'success', summary: 'Success', details: 'Licensee has been deleted' });
    } catch (error) {
      toaster({ severity: 'error', summary: 'Error', details: 'Licensee deletion error' });
      console.error(error);
    }
  };

  const items: MenuItem[] = [
    {
      label: 'Edit Licensee',
      command: handleEditLicensee,
    },
    {
      label: 'Delete Licensee',
      command: () => {
        confirmDelete({
          caption: 'Are you sure you want to delete the Licensee?',
          description: 'This action cannot be undone',
          onConfirm: () => {
            acceptLicenseeDelete();
          },
        });
      },
    },
  ];

  const template = (options: PanelHeaderTemplateOptions) => {
    const toggleIcon = options.collapsed ? 'pi pi-chevron-down' : 'pi pi-chevron-up';
    const className = `${options.className} justify-content-between border-none `;
    const titleClassName = `${options.titleClassName} ml-2`;
    return (
      <div className={className} style={{ backgroundColor: '#FFFFFF' }}>
        <div>
          <LicenseeThemeIcon option={{ code: licensee.theme, name: licensee.theme }} />
          <span className={titleClassName}>{licensee.name}</span>
          <button className={options.togglerClassName} onClick={options.onTogglerClick}>
            <span className={toggleIcon}></span>
          </button>
        </div>
        <div>
          <Menu model={items} popup ref={menu} />
          <Button className="border-none surface-section text-gray-900" onClick={(e) => menu?.current?.toggle(e)}>
            <i className="pi pi-ellipsis-v font-bold text-2xl" style={{ color: '#1E6052' }} />
          </Button>
        </div>
      </div>
    );
  };

  const settingsLinkTemplate = (company: CompanyListModel) => {
    return (
      <Link
        to={EpdRoutes.param(EpdRoutes.manageCompany, ':companyid', company.id || '')}
        style={{ padding: '1rem' }}
        onClick={() => changeCompany && changeCompany(company.id)}
      >
        <Image alt="cog" src={SettingsIcon} />
      </Link>
    );
  };

  return (
    <Panel className="border-none mb-2" headerTemplate={template} toggleable collapsed onExpand={fetchData}>
      {status == ProcessStatus.Fetching ? (
        <ProgressSpinner style={{ width: '50px', height: '50px' }} />
      ) : (
        <DataTable
          value={data}
          paginator
          rows={5}
          paginatorTemplate="PrevPageLink PageLinks NextPageLink CurrentPageReport RowsPerPageDropdown "
          currentPageReportTemplate="{first}-{last} of {totalRecords}"
          rowsPerPageOptions={[5, 10, 25, 50]}
          tableStyle={{ minWidth: '49rem' }}
        >
          <Column field="name" header="Name" sortable style={{ minWidth: '25%' }}></Column>
          <Column field="homeCountryName" sortable header="Country" style={{ minWidth: '25%' }}></Column>
          <Column field="website" header="Website" style={{ minWidth: '25%' }}></Column>
          <Column header="Settings" body={settingsLinkTemplate} style={{ maxWidth: '5rem' }}></Column>
        </DataTable>
      )}
    </Panel>
  );
};

const Image = styled.img`
  height: 1rem;
  vertical-align: top;
  cursor: pointer;
  &:hover {
    & + * {
      display: initial;
    }
  }
`;

export default LicenseeItem;

import { Column, ColumnEvent } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import React, { useMemo, useState } from 'react';
import {
  useCreateEpdProductComponent,
  useDeleteProductComponent,
  useUpdateEpdProductComponent,
} from 'services/api/mutations';
import { useEpdBestProductComponents } from 'services/api/queries';
import { HeaderCaptionSemiBold } from 'styles/v2/Styles.styled';
import { ProductComponent } from 'types/types';

import { cellNumberEditor, cellTextEditor } from '../GridCellEditors';
import { DataTableStyled, GridButton } from '../StyledGrid';

const BestProductComponentsGrid: React.FunctionComponent<{ epdVersionId: string; productId: string | null }> = ({
  epdVersionId,
  productId,
}) => {
  const [selectedRows, setSelectedRows] = useState<ProductComponent[] | null>(null);

  const rows = useEpdBestProductComponents(epdVersionId!, productId).data;
  const createMutation = useCreateEpdProductComponent(productId);
  const updateMutation = useUpdateEpdProductComponent(epdVersionId);
  const deleteMutation = useDeleteProductComponent(productId);

  const handleAddRow = (productId: string | null) => {
    createMutation.mutate({ epdVersionId: epdVersionId, weight: 0, productId: productId });
  };

  const handleDeleteSelected = async () => {
    if (!selectedRows) return;
    await Promise.all(selectedRows.map((x) => deleteMutation.mutateAsync(x.id!)));
    setSelectedRows(null);
  };

  const header = (
    <div className="flex flex-wrap align-items-center justify-content-between gap-2">
      <HeaderCaptionSemiBold className="white-space-nowrap">Product content</HeaderCaptionSemiBold>
      <span>
        <GridButton title="Add" icon="pi pi-plus" onClick={() => handleAddRow(productId)} />{' '}
        <GridButton
          title="Delete"
          icon="pi pi-trash"
          onClick={handleDeleteSelected}
          disabled={!selectedRows || selectedRows.length === 0 || deleteMutation.isPending}
        />
      </span>
    </div>
  );

  const columnTotal = (columnName: keyof ProductComponent) => {
    return useMemo(
      () =>
        rows?.reduce((a, b: ProductComponent) => {
          return a + (b[columnName] as number);
        }, 0),
      [columnName, rows?.values()]
    );
  };

  const footerGroup = (
    <ColumnGroup>
      <Row>
        <Column className="p-row-total" />
        <Column className="p-row-total" footer="Total" />
        <Column className="p-row-total" footer={columnTotal('weight')} />
        <Column className="p-row-total" footer={columnTotal('finalWeightPercent')} />
        <Column className="p-row-total" footer={columnTotal('biogenicWeightPercent')} />
        <Column className="p-row-total" footer={columnTotal('biogenicCarbonWeight')} />
      </Row>
      <Row style={{ borderTop: 'none' }}>
        <Column className="p-row-note" />
        <Column
          className="p-row-note"
          footer={
            <div>
              Note<sup>1</sup>
            </div>
          }
        />
        <Column className="p-row-note" footer={'1 kg biogenic carbon is equivalent to 44/12 kg of CO2'} colSpan={4} />
      </Row>
      <Row style={{ borderTop: 'none' }}>
        <Column className="p-row-note" />
        <Column
          className="p-row-note"
          footer={
            <div>
              Note<sup>2</sup>
            </div>
          }
        />
        <Column
          className="p-row-note"
          footer={
            <div>
              The value will be changed accordingly to the selected unit in Step 3:
              <br /> Option 1: if choosing declared unit - Biogenic material<sup>1</sup>, <b>kg C/declared unit</b>
              <br /> Option 2: if choosing functional unit - Biogenic material<sup>2</sup>, <b>kg C/product</b>
            </div>
          }
          colSpan={4}
        />
      </Row>
    </ColumnGroup>
  );

  const onCellEditComplete = (e: ColumnEvent) => {
    let { rowData, newValue, field, originalEvent: event } = e;
    rowData[field] = newValue;
    rowData.fieldToUpdateValue = newValue;
    updateMutation.mutate(rowData);
  };

  return (
    <DataTableStyled
      header={header}
      footerColumnGroup={footerGroup}
      value={rows}
      selectionMode="checkbox"
      selection={selectedRows!}
      onSelectionChange={(e) => setSelectedRows(e.value as ProductComponent[])}
      dataKey="id"
      tableStyle={{ minWidth: '49rem' }}
      showGridlines
      editMode="cell"
    >
      <Column selectionMode="multiple" headerStyle={{ width: '3rem' }} />
      <Column
        field="name"
        header="Content name"
        editor={(options) => cellTextEditor(options)}
        onCellEditComplete={onCellEditComplete}
      />
      <Column
        field="weight"
        header="Weight, kg"
        editor={(options) => cellNumberEditor(options)}
        onCellEditComplete={onCellEditComplete}
      />
      <Column
        field="finalWeightPercent"
        header="Post-consumer recycled material, weight-% of product"
        editor={(options) => cellNumberEditor(options)}
        onCellEditComplete={onCellEditComplete}
      />
      <Column
        field="biogenicWeightPercent"
        header="Biogenic material, weight-% of product"
        editor={(options) => cellNumberEditor(options)}
        onCellEditComplete={onCellEditComplete}
      />
      <Column
        field="biogenicCarbonWeight"
        header={
          <span>
            Biogenic material<sup>1</sup>, kg C/declared unit or product<sup>2</sup>
          </span>
        }
        editor={(options) => cellNumberEditor(options)}
        onCellEditComplete={onCellEditComplete}
      ></Column>
    </DataTableStyled>
  );
};

export default BestProductComponentsGrid;

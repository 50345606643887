import CompilerSelectControlledComponent from 'components/v2/epd-compiler-inputs/CompilerSelectControlledComponent';
import WizardTextInputComponent from 'components/v2/epd-wizard-inputs/WizardTextInput';
import TooltipErrorIcon from 'components/v2/icons/TooltipErrorIcon';
import TooltipHelpIcon from 'components/v2/icons/TooltipHelpIcon';
import { EPD_DICTIONARY_CODES } from 'services/api/constants';
import { useDictionaryValues } from 'services/api/queries';
import {
  ExpandablePanelHeaderName,
  ExpandablePanelModule,
  FieldPanel,
  HeaderCaptionSemiBold,
} from 'styles/v2/Styles.styled';
import { EPDProductInformationModel, Option } from 'types/types';

const LifespanSection: React.FunctionComponent<{
  product?: EPDProductInformationModel;
  onChange: any;
  isReadOnly: boolean;
}> = ({ product, onChange, isReadOnly }) => {
  const dictionariesQuery = useDictionaryValues();

  const getOptionsFromDictionary = (code: string) =>
    dictionariesQuery.data
      ?.filter((x) => x.dictionaryCode === code)
      .map((x) => {
        return {
          value: x.key.toString(),
          label: x.value,
        };
      }) || [];

  const unitTypeOptions = getOptionsFromDictionary(EPD_DICTIONARY_CODES.UNIT_TYPE);

  const selectedUnitTypeOriginal = !isNaN(Number(product?.unitType))
    ? unitTypeOptions.find((x: any) => x.value == product?.unitType)
    : unitTypeOptions.find((x: any) => x.label == product?.unitType);

  const selectedProductType = selectedUnitTypeOriginal
    ? ({
        value: selectedUnitTypeOriginal?.value.toString(),
        label: selectedUnitTypeOriginal?.label,
      } as Option)
    : null;

  const headerModuleTemplate = (
    options: any,
    header: string,
    tooltip?: string,
    tooltipPosition?: any | undefined,
    required?: boolean,
    isFilledIn?: boolean
  ) => {
    const toggleIcon = options.collapsed ? 'pi pi-chevron-down' : 'pi pi-chevron-up';

    return (
      <div className={options.className}>
        <ExpandablePanelHeaderName>
          <HeaderCaptionSemiBold>{header}</HeaderCaptionSemiBold>
          {tooltip && <TooltipHelpIcon content={tooltip} position={tooltipPosition} />}
          {required && !isFilledIn && <TooltipErrorIcon />}
          <button className={options.togglerClassName} onClick={options.onTogglerClick} style={{ margin: '0 0.5rem' }}>
            <span className={toggleIcon}></span>
          </button>
        </ExpandablePanelHeaderName>
      </div>
    );
  };

  return (
    <ExpandablePanelModule
      headerTemplate={(options) =>
        headerModuleTemplate(
          options,
          'Actual or technical lifespan',
          'The average time for which the product has been shown to be in use. Lifespans shall be expressed in relevant units such as years, operating hours, or kilometres travelled.',
          'top | right',
          false,
          false
        )
      }
      toggleable
    >
      <FieldPanel style={{ paddingTop: '0', marginTop: '0' }}>
        <CompilerSelectControlledComponent
          label="Unit"
          name="unit"
          options={unitTypeOptions}
          value={selectedProductType}
          onChanged={onChange}
          placeholder="Select"
        />
        <WizardTextInputComponent
          label="Value"
          placeholder="Type here"
          value={product?.unitValue}
          onChanged={onChange}
          name="unitValue"
        />
      </FieldPanel>
    </ExpandablePanelModule>
  );
};

export default LifespanSection;

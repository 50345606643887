import FileUpload from 'components/v2/file-upload/FileUpload';
import { ButtonUploadIcon } from 'components/v2/icons';
import TooltipHelpIcon from 'components/v2/icons/TooltipHelpIcon';
import React, { ChangeEvent } from 'react';
import { INDICATOR_GROUP_CODES } from 'services/api/constants';
import { addEpdFile } from 'services/api/mutations';
import { useAddEpdLcaResultFile, useRemoveEpdLcaResultFile } from 'services/api/mutations';
import { useEpdLcaResults } from 'services/api/queries';
import { HyperLink } from 'styles/Styles.styled';
import { FieldPanelFullWidth } from 'styles/v2/Styles.styled';

import FilesList from '../file-row/FilesList';
import { HelpBox } from '../help-boxes';
import LcaResultsGridAdditionalConstruction from './grids/lca-results/LcaResultsGridAdditionalConstruction';
import LcaResultsGridAdditionalMandatory from './grids/lca-results/LcaResultsGridAdditionalMandatory';
import LcaResultsGridAdditionalVoluntary from './grids/lca-results/LcaResultsGridAdditionalVoluntary';
import LcaResultsGridCoreConstruction from './grids/lca-results/LcaResultsGridCoreConstruction';
import LcaResultsGridOutputFlowConstruction from './grids/lca-results/LcaResultsGridOutputFlowConstruction';
import LcaResultsGridResourceConstruction from './grids/lca-results/LcaResultsGridResourceConstruction';
import LcaResultsGridWasteConstruction from './grids/lca-results/LcaResultsGridWasteConstruction';
import GridExpandablePanel from './panels/GridExpandablePanel';

const LcaResultsTab: React.FunctionComponent<{
  epdId: string;
  epdVersionId: string;
  errors?: any;
}> = ({ epdId, epdVersionId, errors }) => {
  const lcaResultsInformation = useEpdLcaResults(epdVersionId!).data;
  const addLcaResultFileMutation = useAddEpdLcaResultFile(epdVersionId);
  const removeLcaResultFileMutation = useRemoveEpdLcaResultFile(epdVersionId);

  const uploadFile = async (e: ChangeEvent<HTMLInputElement>, propertyName: string) => {
    const target = e.target as HTMLInputElement;
    let file = target && target.files && target.files[0];
    if (!file) {
      return null;
    }
    await addEpdFile({ epdId, propertyName, file }, addLcaResultFileMutation);
  };

  const onRemoveLcaResultFile = async (removeCommandAlias: string, fileId: string) => {
    removeLcaResultFileMutation.mutate(fileId);
  };

  return (
    <>
      <FieldPanelFullWidth style={{ gridRowGap: 0 }}>
        <HelpBox>
          <span>
            The LCA results can be either directly added manually to the tables below or autofilled based upon uploaded LCA
            results document in the excel format. The excel template can be found on{' '}
            <HyperLink target="_blank" rel="noopener noreferrer" href="environdec.com">
              environdec.com
            </HyperLink>
          </span>
        </HelpBox>
        <FileUpload
          label={'Upload LCA results'}
          icon={ButtonUploadIcon}
          accept=".xlsx"
          validExtensions={['xlsx']}
          name={'LCA'}
          onFileUpload={uploadFile}
          disabled={false}
        />
        <FilesList files={lcaResultsInformation?.lcAs} onRemoveFile={onRemoveLcaResultFile} />
      </FieldPanelFullWidth>

      <GridExpandablePanel
        title="Mandatory environmental performance indicators according to EN 15804"
        isReadOnly={false}
        tooltip="Environmental performance results shall declare the results of the indicators, and the corresponding disclaimers, of EN 15804:2012+A2:2019/AC:2021."
        error={errors?.core15804}
      >
        <LcaResultsGridCoreConstruction
          rows={lcaResultsInformation?.lcaResults?.filter((x) => x.indicatorGroupCode === INDICATOR_GROUP_CODES.CORE15804)}
        />
      </GridExpandablePanel>

      <GridExpandablePanel
        title="Additional mandatory environmental performance indicators"
        isReadOnly={false}
        // error={errors?.general || (errors && Object.keys(errors).length > 0 && 'Has errors')}
        // error="test"
        tooltip="GWP-GHG accounts for all greenhouse gases except biogenic carbon dioxide uptake and emissions and biogenic carbon stored in the product. As such, the indicator is identical to GWP-total except that the CF for biogenic CO2 is set to zero."
        error={errors?.additionalMandatory}
      >
        <LcaResultsGridAdditionalMandatory
          rows={lcaResultsInformation?.lcaResults?.filter(
            (x) => x.indicatorGroupCode === INDICATOR_GROUP_CODES.ADDITIONALMANDATORY
          )}
        />
      </GridExpandablePanel>

      <GridExpandablePanel
        title="Additional voluntary environmental performance indicators according to EN 15804"
        isReadOnly={false}
      >
        <LcaResultsGridAdditionalConstruction
          rows={lcaResultsInformation?.lcaResults?.filter(
            (x) => x.indicatorGroupCode === INDICATOR_GROUP_CODES.ADDITIONALVOLUNTARY15804
          )}
        />
      </GridExpandablePanel>

      <GridExpandablePanel title="Additional voluntary environmental performance indicators" isReadOnly={false}>
        <LcaResultsGridAdditionalVoluntary epdVersionId={epdVersionId} />
      </GridExpandablePanel>

      <GridExpandablePanel
        title="Resource use indicators according to EN 15804"
        isReadOnly={false}
        error={errors?.resourceUse15804}
      >
        <LcaResultsGridResourceConstruction
          rows={lcaResultsInformation?.lcaResults?.filter(
            (x) => x.indicatorGroupCode === INDICATOR_GROUP_CODES.RESOURCE15804
          )}
        />
      </GridExpandablePanel>

      <GridExpandablePanel title="Waste indicators according to EN 15804" isReadOnly={false} error={errors?.waste15804}>
        <LcaResultsGridWasteConstruction
          rows={lcaResultsInformation?.lcaResults?.filter((x) => x.indicatorGroupCode === INDICATOR_GROUP_CODES.WASTE15804)}
        />
      </GridExpandablePanel>

      <GridExpandablePanel
        title="Output flow indicators according to EN 15804"
        isReadOnly={false}
        error={errors?.outputFlow15804}
      >
        <LcaResultsGridOutputFlowConstruction
          rows={lcaResultsInformation?.lcaResults?.filter(
            (x) => x.indicatorGroupCode === INDICATOR_GROUP_CODES.OUTPUTFLOW15804
          )}
        />
      </GridExpandablePanel>
    </>
  );
};

export default LcaResultsTab;

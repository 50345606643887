import companyLogo from 'assets/images/dashboard.png';
import ErrorFallback from 'components/error-fallback/ErrorFallback';
import useCompilerPdfLogic from 'components/v2/compiler/useCompilerPdfLogic';
import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import AuthService from 'services/AuthService';
import { CountryModel } from 'services/EpdClient';
import { EPD_DICTIONARY_CODES } from 'services/api/constants';
import styled from 'styled-components';
import { EPDGeneralInformationModel, EPDPDFModel, PCRTypeModel } from 'types/types';
import { formatDate } from 'util/utils';

import Page_AdditionalInfo from './AdditionalInfoPage';
import Page6_ContentDeclaration from './ContentDeclarationPage';
import CoverPage from './CoverPage';
import Page7_LCAInformation from './LCAInformationPage';
import Page16_18_LCAResults from './LCAResultsPage';
import Page4_ManufacturerInformation from './ManufacturerInformationPage';
import Page3_Ownership from './OwnershipPage';
import Page9_ProcessFlowDescription from './ProcessFlowDescriptionPage';
import Page5_ProductInformation from './ProductInformationPage';
import Page2_ProgrammeInformation from './ProgrammeInformationPage';
import Page_References from './ReferencePage';
import Page10_15_Scenarios from './ScenariosPage';
import Page8_SystemBoundary from './SystemBoundaryPage';

const EpdCompilerPdfPreview: React.FunctionComponent = () => {
  const {
    epdGeneralInformationQuery,
    additionalInformationQuery,
    organizationInformationQuery,
    organizationInformationAddressQuery,
    productInformationQuery,
    dictionariesQuery,
    countriesQuery,
    coverPageInformationQuery,
    lcaResultsQuery,
    pcrInformationQuery,
    lcaSpecificationQuery,
    dataSourcesQuery,
  } = useCompilerPdfLogic();

  React.useEffect(() => {
    AuthService.login({ userName: 'pdfgenerator@sigma.software', password: 'qQ1!2@' });
  }, []);

  const epdInformation =
    epdGeneralInformationQuery?.data ?? ({ declarationName: null } as unknown as EPDGeneralInformationModel);
  const additionalInformation = additionalInformationQuery?.data;
  const organizationInformation = organizationInformationQuery.data;
  const organizationInformationAddress = organizationInformationAddressQuery.data;
  const productInformations = productInformationQuery.data;
  const countriesList = countriesQuery.data;
  const coverPageInformation = coverPageInformationQuery.data;
  const lcaResultsRows = lcaResultsQuery.data;
  const pcrInformation = pcrInformationQuery.data;
  const lcaSpecification = lcaSpecificationQuery.data;
  const dataSources = dataSourcesQuery.data;
  const referenceFlowUnits =
    dictionariesQuery.data?.filter((x) => x.dictionaryCode === EPD_DICTIONARY_CODES.REFERENCE_FLOW_UNIT) || [];

  const getOptionsFromDictionary = (code: string) =>
    dictionariesQuery.data
      ?.filter((x) => x.dictionaryCode === code)
      .map((x) => {
        return {
          value: x.key.toString(),
          label: x.value,
        };
      }) || [];

  const epdPDFModel: EPDPDFModel = {
    generalInformation: {
      epdVersionId: epdInformation.epdVersionId,
      declarationName: epdInformation.declarationName ?? 'Some name',
      registrationNumber: epdInformation.registrationNumber,
      publishDate: epdInformation.issueDate,
      expirationDate: formatDate(epdInformation.expirationDate),
      revisionDate: formatDate(new Date(Date.now())),
      hasDangerMaterials: epdInformation.hasDangerMaterials,
    },
    organizationInformation: { ...(organizationInformation as any), address: organizationInformationAddress },
    companyInformation: {
      name: 'Company name',
      logo: companyLogo,
      address: 'Company address',
      description: 'Company description',
      contact: 'Contact person information',
    },
    additionalInformation: { ...(additionalInformation as any) },
    pcrInformation: { ...(pcrInformation as PCRTypeModel) },
    coverPageInformation: { ...(coverPageInformation as any) },
    countries: countriesList,
    lcaSpecification: { ...(lcaSpecification as any) },
  };

  return (
    <>
      {AuthService.isLoggedin() && (
        <>
          <CoverPage e={epdPDFModel} />
          <Page2_ProgrammeInformation e={epdPDFModel} />
          <Page3_Ownership e={epdPDFModel} />
          <Page4_ManufacturerInformation e={epdPDFModel} />
          {productInformations?.map((product, index) => (
            <React.Fragment key={index}>
              <Page5_ProductInformation
                e={epdPDFModel}
                productInformation={product}
                countries={countriesList as CountryModel[]}
                getOptionsFromDictionary={getOptionsFromDictionary}
              />
            </React.Fragment>
          ))}
          <Page6_ContentDeclaration e={epdPDFModel} />
          <Page7_LCAInformation
            e={epdPDFModel}
            dataSources={dataSources}
            getOptionsFromDictionary={getOptionsFromDictionary}
            referenceFlowUnits={referenceFlowUnits}
          />
          <Page8_SystemBoundary e={epdPDFModel} />
          <Page9_ProcessFlowDescription e={epdPDFModel} />

          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <Page10_15_Scenarios e={epdPDFModel} />
          </ErrorBoundary>

          <Page16_18_LCAResults e={lcaResultsRows?.lcaResults} />
          <Page_AdditionalInfo e={epdPDFModel} />
          <Page_References e={epdPDFModel} />
          <PDF_PAGE className="final">
            <div></div>
            <div style={{ textAlign: 'center', padding: '1cm' }}>environdec.com</div>
          </PDF_PAGE>
        </>
      )}
    </>
  );
};

const PDF_PAGE = styled.div`
  width: 100%;
  page-break-before: always;
  background-color: rgba(180, 208, 182, 0.3);
`;

export default EpdCompilerPdfPreview;

import { FileHelpBox } from 'components/v2/help-boxes';
import { REMOVE_FILE } from 'constants/constants';
import { Button } from 'primereact/button';
import React from 'react';
import { FileModel } from 'services/EpdClient';
import FileService from 'services/FileService';
import styled from 'styled-components';

export const FileRow: React.FunctionComponent<{
  file?: FileModel;
  onRemoveFile?: any;
  disabled?: boolean;
}> = ({ file, onRemoveFile, disabled }) => {
  if (!file || !file.id) {
    return null;
  }

  const onRemove = async () => {
    if (!file || !file.id) {
      return null;
    }

    onRemoveFile && onRemoveFile(REMOVE_FILE, file?.id);
  };

  const fileUrl = FileService.getImageUrl(file?.id || '');

  return (
    <FileHelpBox>
      <Text title={file?.name}>{file?.name}</Text>
      <div style={{ display: 'flex', alignItems: 'center', maxHeight: '1rem' }}>
        {file && (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Button title="Download file" link text icon="pi pi-download" onClick={() => window.open(fileUrl, '_blank')} />{' '}
            {!disabled && <Button title="Remove file" text icon="pi pi-trash" onClick={onRemove} disabled={disabled} />}
          </div>
        )}
      </div>
    </FileHelpBox>
  );
};

const Text = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
`;

export default FileRow;

import TooltipErrorIcon from 'components/v2/icons/TooltipErrorIcon';
import { Column, ColumnBodyOptions, ColumnEditorOptions, ColumnProps } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { Row } from 'primereact/row';
import React from 'react';
import {
  useUpdateEpdSystemBoundaryModuleDeclared,
  useUpdateEpdSystemBoundaryModuleGeography,
  useUpdateSystemBoundaryMutation,
} from 'services/api/mutations';
import { useEpdSystemBoundaryData, useEpdSystemBoundaryGrid, useGeographicalScopeLookup } from 'services/api/queries';
import styled from 'styled-components';
import { LCAStage } from 'types/types';

import useCompilerLogic from '../../useCompilerLogic';
import { mandatoryBodyTemplate, validatedCellTemplate } from '../GridService';
import { DataTableStyled } from '../StyledGrid';
import { SystemBoundaryGridHeader } from './SystemBoundaryGridHeader';

const SystemBoundaryGrid: React.FunctionComponent<{ epdId: string; epdVersionId: string; errors?: any }> = ({
  epdId,
  epdVersionId,
  errors,
}) => {
  const geographicalScopeLookup = useGeographicalScopeLookup().data;

  const data = useEpdSystemBoundaryGrid(epdVersionId).data;
  const boundaryData = useEpdSystemBoundaryData(epdVersionId).data;
  const { epdGeneralInformationQuery } = useCompilerLogic();
  const generalInformation = epdGeneralInformationQuery.data;

  const rows = [
    { ord: 0, epdVersionId: data?.epdVersionId, module: 'Modules declared', ...data?.declaredModules },
    { ord: 1, epdVersionId: data?.epdVersionId, module: 'Geography', ...data?.declaredModulesGeography },
  ];

  const updateDeclaredModulesMutation = useUpdateEpdSystemBoundaryModuleDeclared(epdVersionId);
  const updateDeclaredModulesGeographyMutation = useUpdateEpdSystemBoundaryModuleGeography(epdVersionId);

  const updateSystemBoundaryMutation = useUpdateSystemBoundaryMutation(epdVersionId);
  const onChangeEpdSystemBoundaryData = async (propertyName: string, val: any) => {
    updateSystemBoundaryMutation.mutate({
      epdId: epdId,
      versionId: epdVersionId,
      propertyName: propertyName,
      newValue: val,
    });
  };

  const cellEditor = (options: ColumnEditorOptions) => {
    if (options.rowIndex === 0) {
      return (
        <Dropdown
          style={{ width: '100%', height: '28px', minWidth: '1rem' }}
          value={options.rowData[options.field]}
          optionLabel="label"
          optionValue="value"
          options={[
            { label: 'X', value: true },
            { label: 'ND', value: false },
          ]}
          onChange={(e: DropdownChangeEvent) => {
            updateDeclaredModulesMutation.mutate({
              epdVersionId: epdVersionId,
              fieldName: options.field,
              value: e.value,
            });
          }}
        />
      );
    }
    if (options.rowIndex === 1) {
      if (rows[0][options.field as keyof LCAStage<boolean | null>] === false) {
        return <span style={{ display: 'flex', justifyContent: 'center' }}>XXX</span>;
      }
      return (
        <Dropdown
          filter
          style={{ width: '100%', height: '28px' }}
          value={options.rowData[options.value]}
          optionLabel="name"
          optionValue="id"
          options={geographicalScopeLookup}
          onChange={(e: DropdownChangeEvent) => {
            updateDeclaredModulesGeographyMutation.mutate({
              epdVersionId: epdVersionId,
              fieldName: options.field,
              value: e.value,
            });
          }}
          itemTemplate={(option) => {
            return <div style={{ maxWidth: '40rem', wordWrap: 'break-word', fontSize: '12px' }}>{option.name}</div>;
          }}
        />
      );
    }

    return options.rowData[options.field];
  };

  const modulesTemplate = (row: any, options: ColumnBodyOptions) => {
    if (options.rowIndex == 0) {
      if (row[options.field] == null) {
        return null;
      }
      return row[options.field] === true ? 'X' : 'ND';
    } else {
      if (rows[0][options.field as keyof LCAStage<boolean | null>] === false) {
        return 'XXX';
      }
      return geographicalScopeLookup?.find((x) => x.id === row[options.field])?.name;
    }
  };

  const cellTemplate = (row: any, options: ColumnBodyOptions) => {
    return validatedCellTemplate(
      modulesTemplate(row, options) as any,
      options.rowIndex == 0 ? errors?.declaredModules?.[options?.field] : errors?.declaredModulesGeography?.[options?.field]
    );
  };

  const SystemBoundaryGridColumns: ColumnProps[] = [
    {
      field: 'module',
      header: 'Module',
      style: { minWidth: '10rem' },
    },
    {
      field: 'a1',
      header: 'A1',
      style: { minWidth: '3rem' },
      editor: (options) => cellEditor(options),
      body: cellTemplate,
    },
    {
      field: 'a2',
      header: 'A2',
      style: { minWidth: '3rem' },
      editor: (options) => cellEditor(options),
      body: cellTemplate,
    },
    {
      field: 'a3',
      header: 'A4',
      style: { minWidth: '3rem' },
      editor: (options) => cellEditor(options),
      body: cellTemplate,
    },
    {
      field: 'a4',
      header: 'A4',
      style: { minWidth: '3rem' },
      editor: (options) => cellEditor(options),
      body: cellTemplate,
    },
    {
      field: 'a5',
      header: 'A5',
      style: { minWidth: '3rem' },
      editor: (options) => cellEditor(options),
      body: cellTemplate,
    },
    {
      field: 'b1',
      header: 'B1',
      style: { minWidth: '3rem' },
      editor: (options) => cellEditor(options),
      body: cellTemplate,
    },
    {
      field: 'b2',
      header: 'B2',
      style: { minWidth: '3rem' },
      editor: (options) => cellEditor(options),
      body: cellTemplate,
    },
    {
      field: 'b3',
      header: 'B3',
      style: { minWidth: '3rem' },
      editor: (options) => cellEditor(options),
      body: cellTemplate,
    },
    {
      field: 'b4',
      header: 'B4',
      style: { minWidth: '3rem' },
      editor: (options) => cellEditor(options),
      body: cellTemplate,
    },
    {
      field: 'b5',
      header: 'B5',
      style: { minWidth: '3rem' },
      editor: (options) => cellEditor(options),
      body: cellTemplate,
    },
    {
      field: 'b6',
      header: 'B6',
      style: { minWidth: '3rem' },
      editor: (options) => cellEditor(options),
      body: cellTemplate,
    },
    {
      field: 'b7',
      header: 'B7',
      style: { minWidth: '3rem' },
      editor: (options) => cellEditor(options),
      body: cellTemplate,
    },
    {
      field: 'c1',
      header: 'C1',
      style: { minWidth: '3rem' },
      editor: (options) => cellEditor(options),
      body: cellTemplate,
    },
    {
      field: 'c2',
      header: 'C2',
      style: { minWidth: '3rem' },
      editor: (options) => cellEditor(options),
      body: cellTemplate,
    },
    {
      field: 'c3',
      header: 'C3',
      style: { minWidth: '3rem' },
      editor: (options) => cellEditor(options),
      body: cellTemplate,
    },
    {
      field: 'c4',
      header: 'C4',
      style: { minWidth: '3rem' },
      editor: (options) => cellEditor(options),
      body: cellTemplate,
    },
    {
      field: 'd',
      header: 'D',
      editor: (options) => cellEditor(options),
      body: cellTemplate,
    },
  ];

  const spanColsCount = generalInformation?.productType === 'Goods' ? 3 : 5;
  const disabledColsCount = SystemBoundaryGridColumns.length - 1 - spanColsCount;
  const footerGroup = (
    <ColumnGroup>
      <Row>
        <Column footer="Specific data used" />
        <Column
          colSpan={spanColsCount}
          style={{ padding: '2px' }}
          footer={
            <div style={{ display: 'flex', justifyContent: 'space-between', marginRight: '10px', position: 'relative' }}>
              <InputNumber
                style={{ width: '100%', height: '28px' }}
                inputStyle={{ border: 0 }}
                mode="decimal"
                step={1}
                maxFractionDigits={0}
                min={0}
                max={100}
                suffix="%"
                value={boundaryData?.specificDataUsed ?? null}
                onValueChange={(e) => {
                  onChangeEpdSystemBoundaryData('specificDataUsed', e.value);
                }}
              />
              {errors?.systemBoundary?.specificDataUsed && (
                <TooltipErrorIcon content={errors?.systemBoundary?.specificDataUsed || 'Required'} />
              )}
            </div>
          }
        />
        {Array.from({ length: disabledColsCount - 1 }, (_, index) => (
          <Column key={index} footer="-" style={{ backgroundColor: '#FCFCFC' }} />
        ))}
        <Column footer="-" style={{ borderBottom: 0 }} />
      </Row>
      <Row>
        <Column footer="Variation - products" />
        <Column
          colSpan={spanColsCount}
          style={{ padding: '2px' }}
          footer={
            <div style={{ display: 'flex', justifyContent: 'space-between', marginRight: '10px', position: 'relative' }}>
              <InputNumber
                style={{ width: '100%', height: '28px' }}
                inputStyle={{ border: 0 }}
                mode="decimal"
                step={1}
                maxFractionDigits={0}
                min={0}
                max={100}
                suffix="%"
                value={boundaryData?.variationProducts ?? null}
                onValueChange={(e) => {
                  onChangeEpdSystemBoundaryData('variationProducts', e.value);
                }}
              />
              {errors?.systemBoundary?.variationProducts && (
                <TooltipErrorIcon content={errors?.systemBoundary?.variationProducts || 'Required'} />
              )}
            </div>
          }
        />
        {Array.from({ length: disabledColsCount - 1 }, (_, index) => (
          <Column key={index} footer="-" style={{ backgroundColor: '#FCFCFC' }} />
        ))}
        <Column footer="-" style={{ borderBottom: 0 }} />
      </Row>
      <Row>
        <Column footer="Variation - sites" />
        <Column
          colSpan={spanColsCount}
          style={{ padding: '2px' }}
          footer={
            <div style={{ display: 'flex', justifyContent: 'space-between', marginRight: '10px', position: 'relative' }}>
              <InputNumber
                style={{ width: '100%', height: '28px' }}
                inputStyle={{ border: 0 }}
                mode="decimal"
                step={1}
                maxFractionDigits={0}
                min={0}
                max={100}
                suffix="%"
                value={boundaryData?.variationSites ?? null}
                onValueChange={(e) => {
                  onChangeEpdSystemBoundaryData('variationSites', e.value);
                }}
              />
              {errors?.systemBoundary?.variationSites && (
                <TooltipErrorIcon content={errors?.systemBoundary?.variationSites || 'Required'} />
              )}
            </div>
          }
        />
        {Array.from({ length: disabledColsCount - 1 }, (_, index) => (
          <Column key={index} footer="-" style={{ backgroundColor: '#FCFCFC' }} />
        ))}
        <Column footer="-" />
      </Row>
    </ColumnGroup>
  );

  return (
    <BoundaryDataTableStyled
      headerColumnGroup={SystemBoundaryGridHeader}
      footerColumnGroup={footerGroup}
      value={rows}
      dataKey="ord"
      tableStyle={{ minWidth: '49rem' }}
      showGridlines
      editMode="cell"
    >
      {SystemBoundaryGridColumns?.map((x) => (
        <Column key={x.field} {...x} />
      ))}
    </BoundaryDataTableStyled>
  );
};

const BoundaryDataTableStyled = styled(DataTableStyled)`
  .p-datatable-thead > tr > th:last-child,
  .p-datatable-tbody > tr > td:last-child,
  .p-datatable-tfoot > tr > td:last-child {
    background-color: ${(props) => props.theme.colors.elementsFilledInput};
  }

  .p-datatable-thead > tr:first-child > th {
    ${(props) => props.theme.typography.contentTableTitleXs}
    background-color: ${(props) => props.theme.colors.secondaryLightGreen30};
    padding: 0.5rem 1rem;
  }

  .p-datatable-thead > tr > th {
    ${(props) => props.theme.typography.contentTableBodyXs}
    background-color: ${(props) => props.theme.colors.primaryWhite};
    padding: 0.5rem 1rem;
  }
`;

export default SystemBoundaryGrid;

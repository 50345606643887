import React from 'react';

import { PDF_H1, PDF_H2, PDF_P, PDF_PAGE } from './Styles.styled';

const OwnershipPage: React.FunctionComponent<{
  e: any;
}> = ({ e }) => {
  return (
    <PDF_PAGE>
      <PDF_H1>OWNERSHIP AND LIMITATIONS ON USE OF EPD</PDF_H1>
      <PDF_H2>Limitations</PDF_H2>
      <PDF_P>
        EPDs within the same product category but registered in different EPD programmes may not be comparable. For two EPDs
        to be comparable, they shall be based on the same PCR (including the same version number up to the first two digits)
        or be based on fully-aligned PCRs or versions of PCRs; cover products with identical functions, technical
        performances and use (e.g. identical declared/functional units); have equivalent system boundaries and descriptions
        of data; apply equivalent data quality requirements, methods of data collection, and allocation methods; apply
        identical cut-off rules and impact assessment methods (including the same version of characterisation factors); have
        equivalent content declarations; and be valid at the time of comparison.
      </PDF_P>
      <PDF_P>
        Additional limitations according to c-PCR: Comparability between EPDs is only achievable if the following performance
        characteristics are equivalent: declared unit, containment level, level of working width, assumed service life,
        geographic region and fulfilment of the same requirements of the applicable standard (EN 1317, NCHRP 350 or MASH).
      </PDF_P>
      <PDF_P>
        Additional limitations according to c-PCR: Comparability between EPDs is only achievable if the following performance
        characteristics are equivalent: declared unit, containment level, level of working width, assumed service life,
        geographic region and fulfilment of the same requirements of the applicable standard (EN 13964:2014).
      </PDF_P>
      <PDF_P>
        Additional limitations according to c-PCR: Comparability between EPDs is only achievable if the following performance
        characteristics are equivalent: declared unit, containment level, level of working width, assumed service life,
        geographic region and fulfilment of the same requirements of the applicable standard.
      </PDF_P>
      <PDF_P>
        Additional limitations according to c-PCR: Comparability between EPDs based on c-PCR Escalators and moving walks (to
        PCR 2019:14) is only achievable, if the following performance characteristics apply: functional unit (FU), operation
        mode and usage class (UC) are identical and the geographic region is equivalent.
      </PDF_P>
      <PDF_P>
        The estimated impact results are only relative statements, which do not indicate the endpoints of the impact
        categories, exceeding threshold values, safety margins, and/or risks.
      </PDF_P>
      <PDF_H2>Ownership</PDF_H2>
      <PDF_P>The EPD owner has the sole ownership, liability, and responsibility for the EPD.</PDF_P>
    </PDF_PAGE>
  );
};

export default OwnershipPage;

import React from 'react';

import { PDF_H1, PDF_PAGE } from './Styles.styled';
import ProductComponentsGridPdf from 'components/v2/compiler/grids/product-content/ProductComponentsGridPdf';
import PackagingMaterialsGridPdf from 'components/v2/compiler/grids/product-content/PackagingMaterialsGridPdf';
import DangerMaterialsGridPdf from 'components/v2/compiler/grids/product-content/DangerMaterialsGridPdf';
import { EPDPDFModel } from 'types/types';
import PanelContentGridPdf from 'components/v2/compiler/grids/product-content/PanelContentGridPdf';

const ContentDeclarationPage: React.FunctionComponent<{
  e: EPDPDFModel;
}> = ({ e }) => {
  const isPanelContentEnabled = e?.pcrInformation?.cpcr?.name?.includes('c-PCR-014');

  return (
    <PDF_PAGE>
      <PDF_H1>Content declaration</PDF_H1>
      <br />
      <ProductComponentsGridPdf epdVersionId={e.generalInformation.epdVersionId} />
      {isPanelContentEnabled && (
        <>
          <br />
          <br />
          <PanelContentGridPdf epdVersionId={e.generalInformation.epdVersionId} />
        </>
      )}
      <br />
      <br />
      <PackagingMaterialsGridPdf epdVersionId={e.generalInformation.epdVersionId} />
      {e.generalInformation.hasDangerMaterials && (
        <>
          <br />
          <br />
          <DangerMaterialsGridPdf epdVersionId={e.generalInformation.epdVersionId} />
        </>
      )}
    </PDF_PAGE>
  );
};

export default ContentDeclarationPage;

import TooltipErrorIcon from 'components/v2/icons/TooltipErrorIcon';
import TooltipHelpIcon from 'components/v2/icons/TooltipHelpIcon';
import styled from 'styled-components';
import { HeaderCaptionSemiBold } from 'styles/v2/Styles.styled';

import { TextButton } from '../../buttons';
import { ExpandPanelIcon } from '../../icons';

const GridPanelHeaderTemplate = (
  options: any,
  title: string | undefined,
  onMaximize: () => void,
  tooltip?: string | undefined,
  tooltipPosition?: any | undefined,
  error?: any
) => {
  const toggleIcon = options.collapsed ? 'pi pi-chevron-down' : 'pi pi-chevron-up';
  const className = `${options.className} justify-content-start w-full`;

  return (
    <div
      className={className}
      style={options.collapsed ? { borderRadius: '4px' } : { paddingTop: '16px', paddingBottom: '6px', minHeight: '30px' }}
    >
      <ElementsContainer>
        <Title>{title}</Title>
        <div style={{ display: 'flex', alignSelf: 'auto', alignItems: 'Center' }}>
          {tooltip && <TooltipHelpIcon content={tooltip} position={tooltipPosition} />}
          {error && <TooltipErrorIcon content={error} containerStyle={{ position: 'relative' }} />}
          <button className={options.togglerClassName} onClick={options.onTogglerClick}>
            <span className={toggleIcon}></span>
          </button>
        </div>
        <span>
          <TextButton
            className="white-space-nowrap"
            style={{ cursor: 'pointer' }}
            label="Expand table"
            icon={ExpandPanelIcon}
            text
            onClick={onMaximize}
          />
        </span>
      </ElementsContainer>
    </div>
  );
};

const ElementsContainer = styled.div`
  align-items: center;
  width: 100%;
  justify-content: space-between;
  min-height: 38px;
  display: grid;
  grid-template-columns: auto 1fr auto;
  column-gap: 10px;
`;

const Title = styled.div`
  ${(props) => props.theme.typography.headerCaptionSemiBold}
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0.75rem 0rem;
  margin: 0;
`;

export default GridPanelHeaderTemplate;

import { Column, ColumnBodyOptions, ColumnEvent } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { InputNumber } from 'primereact/inputnumber';
import { Row } from 'primereact/row';
import React, { useState } from 'react';
import {
  useCreateEpdScrapInput,
  useCreateEpdShareOfTotalScrapInput,
  useDeleteEpdScrapInput,
  useUpdateEpdScrapInput,
  useUpdateEpdShareOfTotalScrapInput,
} from 'services/api/mutations';
import { useGetEpdScrapInputs, useGetEpdShareOfTotalScrapInput } from 'services/api/queries';
import { ScrapInputModel } from 'types/types';

import { cellTextEditor } from '../GridCellEditors';
import { headerTemplate, mandatoryBodyTemplate } from '../GridService';
import { DataTableStyled } from '../StyledGrid';

const ContributionOfScrapInputsGrid: React.FunctionComponent<{ epdVersionId: string; errors?: any }> = ({
  epdVersionId,
  errors,
}) => {
  const [selectedRows, setSelectedRows] = useState<ScrapInputModel[] | null>(null);

  const shareOfTotalScrapInput = useGetEpdShareOfTotalScrapInput(epdVersionId!).data;
  const createShareOfTotalScrapInput = useCreateEpdShareOfTotalScrapInput();
  const updateShareOfTotalScrapInput = useUpdateEpdShareOfTotalScrapInput();

  const rows = useGetEpdScrapInputs(epdVersionId!).data;
  const createScrapInputMutation = useCreateEpdScrapInput();
  const updateScrapInputMutation = useUpdateEpdScrapInput();
  const deleteMutation = useDeleteEpdScrapInput();

  const handleAddRow = () => {
    createScrapInputMutation.mutate({ epdVersionId });
  };

  const handleDeleteSelected = async () => {
    if (!selectedRows) return;
    await Promise.all(selectedRows.map((x) => deleteMutation.mutateAsync(x.id!)));
    setSelectedRows(null);
  };

  const onCellEditComplete = (e: ColumnEvent) => {
    let { rowData, newValue, field, originalEvent: event } = e;
    rowData[field] = newValue;
    updateScrapInputMutation.mutate(rowData);
  };

  const mandatoryCellTemplate = (value: ScrapInputModel, options: ColumnBodyOptions) =>
    mandatoryBodyTemplate((value as any)?.[options?.field], errors?.[`row[${options?.rowIndex}]`]?.[options?.field]);

  const mandatoryCellTemplateFormatted = (value: ScrapInputModel, options: ColumnBodyOptions) =>
    mandatoryBodyTemplate(
      formatScrapInputValue((value as any)?.[options?.field]),
      errors?.[`row[${options?.rowIndex}]`]?.[options?.field]
    );

  const isTableFilledIn = rows?.every((r) => r.name) && rows?.every((r) => r.value);

  const isTableDisabled = !selectedRows?.length || deleteMutation.isPending;

  const header = headerTemplate(
    isTableFilledIn,
    'Scrap inputs data',
    handleAddRow,
    handleDeleteSelected,
    isTableDisabled,
    errors?.general || (errors && Object.keys(errors).length > 0 && 'Has errors')
  );

  const onFooterCellEditComplete = (e: any) => {
    const value = e.target.value?.replace(' %', '');
    if (!shareOfTotalScrapInput?.id) {
      createShareOfTotalScrapInput.mutate({ epdVersionId, sharePercent: value! ?? null });
      return;
    }
    updateShareOfTotalScrapInput.mutate({ ...shareOfTotalScrapInput, sharePercent: value! ?? null });
  };

  const footerNumberEditor = () => (
    <InputNumber
      style={{ minWidth: '3rem', height: '32px', border: 0, width: '100%' }}
      inputStyle={{ minWidth: '3rem', width: '100%', height: '32px' }}
      value={shareOfTotalScrapInput?.sharePercent}
      suffix=" %"
      onBlur={onFooterCellEditComplete}
      mode="decimal"
      step={0.01}
      maxFractionDigits={2}
    />
  );

  const footerGroup = (
    <ColumnGroup>
      <Row style={{ borderTop: 'none' }}>
        <Column />
        <Column
          footer={<div>The share of the total scrap input that was assumed to come with an environmental burden</div>}
        />
        <Column footer={footerNumberEditor} colSpan={4} />
      </Row>
    </ColumnGroup>
  );

  const formatScrapInputValue = (value: number | undefined) => (!!value ? `${value}, kg CO2 eq./kW` : null);

  return (
    <DataTableStyled
      dataKey="id"
      header={header}
      footerColumnGroup={footerGroup}
      value={rows}
      editMode="cell"
      selectionMode="checkbox"
      selection={selectedRows!}
      onSelectionChange={(e: any) => setSelectedRows(e.value as ScrapInputModel[])}
      showGridlines
    >
      <Column selectionMode="multiple" headerStyle={{ width: '3rem' }} />
      <Column
        field="name"
        header="Material scrap name"
        editor={(options) => cellTextEditor(options)}
        onCellEditComplete={onCellEditComplete}
        body={mandatoryCellTemplate}
      />
      <Column
        field="value"
        header="Material scrap value"
        body={mandatoryCellTemplateFormatted}
        editor={(options) => cellTextEditor(options)}
        onCellEditComplete={onCellEditComplete}
      />
    </DataTableStyled>
  );
};

export default ContributionOfScrapInputsGrid;

import UploadFile, { docxOrPdfType } from 'components/form/UploadFile';
import FilesList from 'components/v2/file-row/FilesList';
import FileUpload from 'components/v2/file-upload/FileUpload';
import { ButtonUploadIcon } from 'components/v2/icons';
import TooltipErrorIcon from 'components/v2/icons/TooltipErrorIcon';
import React, { ChangeEvent } from 'react';
import { ActionState, EPDModel } from 'services/EpdClient';
import { VisibilityType } from 'services/EpdClient';
import { addEpdFile, useChangeEPDVerificationReport } from 'services/api/mutations';
import { useAddEpdVerificationReportFile, useRemoveEpdVerificationReportFile } from 'services/api/mutations';
import { useEpdVerificationReportInfo } from 'services/api/queries';
import { HyperLink } from 'styles/Styles.styled';
import { ActionFieldPanelFullWidth, FieldPanelFullWidth, FieldPanelHeader } from 'styles/v2/Styles.styled';
import { formatAsUTCDate } from 'util/utils';

import DatePicker from '../copy/DatePicker';
import WizardCalendarControlled from '../epd-wizard-inputs/WizardCalendarControlled';
import WizardTextInputComponent from '../epd-wizard-inputs/WizardTextInput';
import { HelpBox } from '../help-boxes';

const Step4: React.FunctionComponent<{
  epdId: string;
  epdVersionId: string;
  validationState: any;
}> = ({ epdId, epdVersionId, validationState }) => {
  const addLcaResultFileMutation = useAddEpdVerificationReportFile(epdVersionId);
  const removeLcaResultFileMutation = useRemoveEpdVerificationReportFile(epdVersionId);
  var verificationReportInfo = useEpdVerificationReportInfo(epdVersionId).data;
  const updateEPDVerificationReport = useChangeEPDVerificationReport(epdVersionId);
  const onChangeVerificationDates = async (propertyName: string, val: any) => {
    updateEPDVerificationReport.mutate({
      epdId: epdId,
      versionId: epdVersionId,
      propertyName: propertyName,
      newValue: val,
    });
  };

  const getNameBlockVisibility = () => {
    var status: ActionState = validationState?.fieldsState?.verificationNameBlock ?? ActionState.Hidden;
    if (!status) {
      return ActionState.Hidden;
    }
    return status;
  };

  const getDatesBlockVisibility = () => {
    var status: ActionState = validationState?.fieldsState?.verificationDatesBlock ?? ActionState.Hidden;
    if (!status) {
      return ActionState.Hidden;
    }
    return status;
  };

  const getReportBlockVisibility = () => {
    var status: ActionState = validationState?.fieldsState?.verificationReportBlock ?? ActionState.Hidden;
    if (!status) {
      return ActionState.Hidden;
    }
    return status;
  };

  const showUploadBlock = () => {
    if (
      getReportBlockVisibility() == ActionState.Enabled ||
      getReportBlockVisibility() == ActionState.Disabled ||
      getReportBlockVisibility() == ActionState.Locked
    ) {
      return ActionState.Enabled;
    }
    return ActionState.Hidden;
  };

  const showStub = () => {
    return getReportBlockVisibility() == ActionState.Stub ? ActionState.Enabled : ActionState.Hidden;
  };

  const showStubVerifier = () => {
    return getReportBlockVisibility() == ActionState.StubVerifier ? ActionState.Enabled : ActionState.Hidden;
  };

  const uploadFile = async (e: ChangeEvent<HTMLInputElement>, propertyName: string) => {
    const target = e.target as HTMLInputElement;
    let file = target && target.files && target.files[0];
    if (!file) {
      return null;
    }

    await addEpdFile({ epdId, propertyName, file }, addLcaResultFileMutation);
  };

  const onRemoveLcaResultFile = async (removeCommandAlias: string, fileId: string) => {
    removeLcaResultFileMutation.mutate(fileId);
  };
  const showDate = (date: any) => {
    if (date) {
      return formatAsUTCDate(date);
    }
    return null;
  };

  return (
    <>
      <ActionFieldPanelFullWidth actionState={getNameBlockVisibility()} style={{ marginTop: 0 }}>
        <WizardTextInputComponent label="Verifier" name="verifierName" disabled value={verificationReportInfo?.name} />
      </ActionFieldPanelFullWidth>
      <ActionFieldPanelFullWidth actionState={showStubVerifier()}>
        <FieldPanelHeader>Verification report</FieldPanelHeader>
        <HelpBox>
          The verifier will check that the uploaded EPD and filled information in Step 1, Step 2 and Step 3 correspond with
          the verified information in the LCA report and EPD document.
          <br />
          <br />
          After the review, the verifier will upload and approve the EPD. You will be able to see the uploaded verification
          report in this section.
        </HelpBox>
      </ActionFieldPanelFullWidth>
      <ActionFieldPanelFullWidth actionState={showStub()}>
        <FieldPanelHeader>Verification report</FieldPanelHeader>
        <HelpBox>
          In Step 4, the appointed verifier will verify the information that was added in the previous steps
          <br />
          <br />
          After completing the verification, the verifier will upload the verification report and approve the EPD.
          <br /> The verification report will be accessible at this step.
        </HelpBox>
      </ActionFieldPanelFullWidth>
      <ActionFieldPanelFullWidth actionState={showUploadBlock()}>
        <div style={{ display: 'flex', position: 'relative' }}>
          <FieldPanelHeader>Verification report </FieldPanelHeader>{' '}
          {validationState &&
            validationState.errors &&
            validationState.errors.verificationReports &&
            (verificationReportInfo?.verificationReports.length ?? 0) == 0 && (
              <TooltipErrorIcon content={validationState.errors.verificationReports} />
            )}
        </div>{' '}
        {getReportBlockVisibility() == ActionState.Enabled && (
          <HelpBox>
            Please use the latest version of the verification template in either DOCX or PDF format for your verification.
            <span>
              Several documents can be uploaded.{' '}
              <HyperLink
                target="_blank"
                rel="noopener noreferrer"
                href="https://environdec.com/resources/documentation#epddevelopmentandverificationtemplates"
              >
                Choose template here
              </HyperLink>
            </span>
          </HelpBox>
        )}
        {getReportBlockVisibility() == ActionState.Enabled && (
          <FileUpload
            label={'Upload'}
            icon={ButtonUploadIcon}
            accept=".docx,.pdf"
            validExtensions={['docx', 'pdf']}
            name={'VerificationReport'}
            onFileUpload={uploadFile}
            disabled={getReportBlockVisibility() != ActionState.Enabled}
          />
        )}
        <FilesList
          files={verificationReportInfo?.verificationReports}
          onRemoveFile={onRemoveLcaResultFile}
          disabled={getReportBlockVisibility() != ActionState.Enabled}
        />
      </ActionFieldPanelFullWidth>
      <ActionFieldPanelFullWidth actionState={getDatesBlockVisibility()}>
        <WizardCalendarControlled
          label="Version date"
          required
          disabled={getDatesBlockVisibility() == ActionState.Disabled}
          tooltip="The version date is the date when the verification report is signed by the verifier and the EPD is approved. An EPD becomes valid as of its version date. The validity period can be up to a maximum of five years from the version date, with shorter validity periods also being accepted."
          name="approvalDate"
          value={showDate(verificationReportInfo?.approvalDate)}
          onChanged={onChangeVerificationDates}
          error={validationState && validationState.errors && validationState.errors.approvalDate}
        />
        <WizardCalendarControlled
          label="Validity date"
          required
          disabled={getDatesBlockVisibility() == ActionState.Disabled}
          tooltip="This date is set during verification and shall be the same as stated in the verification report. Normally set to +5 years (depending on rules in PCR) from the finalization of verification/date of the verification report. The date in the EPD portal can be manually edited by the Secretariat upon publication."
          name="validityDate"
          value={showDate(verificationReportInfo?.validityDate)}
          onChanged={onChangeVerificationDates}
          error={validationState?.errors?.validityDate}
        />
      </ActionFieldPanelFullWidth>
    </>
  );
};

export default Step4;

import menuIcon from 'assets/images/icons/button/menu.svg';
import ErrorFallback from 'components/error-fallback/ErrorFallback';
import OverlaySpinner from 'components/spinners/OverlaySpinner';
import TitleAndBack from 'components/v2/TitleAndBack';
import { ActionImageGreenButton, ActionPrimaryButton, CompilerActionSecondaryButton } from 'components/v2/buttons';
import StepPanel from 'components/v2/compiler/StepPanel';
import useCompilerLogic from 'components/v2/compiler/useCompilerLogic';
import { confirmAlert } from 'components/v2/confirm-dialog/ConfirmAlert';
import { confirmDelete } from 'components/v2/confirm-dialog/ConfirmDelete';
import { confirmQuestion } from 'components/v2/confirm-dialog/ConfirmQuestion';
import { HelpBox } from 'components/v2/help-boxes';
import { toaster } from 'components/v2/toast';
import { ProcessStatus } from 'constants/constants';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Menu } from 'primereact/menu';
import { MenuItem } from 'primereact/menuitem';
import { RadioButtonChangeEvent } from 'primereact/radiobutton';
import React, { ReactNode, useRef, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { EpdLinks } from 'routes/EpdRoutes';
import { ActionState } from 'services/EpdClient';
import EpdStateService from 'services/EpdStateService';
import {
  useApproveEditorial,
  useApproveVerification,
  useCancelEpdDeregistration,
  useCancelUpdateEpd,
  useCancelVerification,
  useDeregisterEpd,
  useLicenseeAdminVerificationApprove,
  useLicenseeAdminVerificationDecline,
  useNeedsVerification,
  usePublishEpd,
  useRegisterEditorial,
  useEditorialUpdating,
  useCancelRegisterEditorial,
  useRegisterEpd,
  useStartVerification,
  useUnlockEpd,
  useUnpublishEpd,
} from 'services/api/mutations';
import styled from 'styled-components';
import { Container } from 'styles/Styles.styled';
import { EPDGeneralInformationModel } from 'types/types';

import StatusBox from '../../../components/v2/compiler/StatusBox';
import {
  CheckBoxPanel,
  ErrorTextBox,
  RadioButtonStyled,
  WizardHeaderButtonBox,
  WizardHeaderGrid,
} from '../../../styles/v2/Styles.styled';
import { withGreyBackground } from '../WithGreyBackground';

const EpdCompiler: React.FunctionComponent = () => {
  const actionMenuRef = useRef<Menu>(null);
  const [isRegisterUpdateDialogOpened, setIsRegisterUpdateDialogOpened] = useState<boolean>(false);
  const [editorialUpdateNotes, setEditorialUpdateNotes] = useState<string | undefined>(undefined);
  const [status, setStatus] = useState<ProcessStatus>(ProcessStatus.None);
  const { t } = useTranslation();
  const {
    history,
    steps,
    activeStepIndex,
    setActiveStepIndex,
    epdQuery,
    epdGeneralInformationQuery,
    actionsStates,
    serverValidationResult,
  } = useCompilerLogic();

  const epdGeneralInformation = (epdGeneralInformationQuery?.data ?? {
    actionMessage: null,
    declarationName: null,
  }) as EPDGeneralInformationModel;

  const cancelUpdateEpdMutation = useCancelUpdateEpd(epdGeneralInformation?.id!);
  const startVerificationMutation = useStartVerification(epdGeneralInformation?.id!);
  const cancelVerificationMutation = useCancelVerification(epdGeneralInformation?.id!);
  const registerEpdMutation = useRegisterEpd(epdGeneralInformation?.id!);
  const deregisterEpdMutation = useDeregisterEpd(epdGeneralInformation?.id!);
  const cancelEpdDeregistrationMutation = useCancelEpdDeregistration(epdGeneralInformation?.id!);
  const unlockEpdMutation = useUnlockEpd(epdGeneralInformation?.id!);
  const approveVerificationMutation = useApproveVerification(epdGeneralInformation?.id!);
  const licenseeAdminVerificationApproveMutation = useLicenseeAdminVerificationApprove(epdGeneralInformation?.id!);
  const publishEpdMutation = usePublishEpd(epdGeneralInformation?.epdVersionId!);
  const unpublishEpdMutation = useUnpublishEpd(epdGeneralInformation?.id!);
  const needsVerificationMutation = useNeedsVerification(epdGeneralInformation?.id!);
  const licenseeAdminVerificationDeclineMutation = useLicenseeAdminVerificationDecline(epdGeneralInformation?.id!);
  const registerEditorialMutation = useRegisterEditorial();
  const editorialUpdatingMutation = useEditorialUpdating();
  const cancelRegisterEditorialMutation = useCancelRegisterEditorial();
  const approveEditorialMutation = useApproveEditorial(epdGeneralInformation?.id!);

  const [isUpdateDialogOpened, setIsUpdateDialogOpened] = useState<boolean>(false);
  const [epdUpdateType, setEpdUpdateType] = useState<string>('update');

  const registerEpdState =
    actionsStates?.register === ActionState.Enabled && !epdQuery.data?.serviceAgreementStatus
      ? ActionState.Disabled
      : actionsStates?.register;

  const onGoBack = () => {
    confirmQuestion({
      caption: 'Do you want to leave the editing process?',
      description: (
        <div>
          All entered data will be saved.
          <p style={{ marginTop: '0.5rem' }}>You can access your EPD from the dashboard.</p>
        </div>
      ),
      acceptLabel: 'Leave',
      rejectLabel: 'Cancel',
      onConfirm: () => {
        history.push(EpdLinks.dashboard());
      },
      closable: false,
      contentStyle: { paddingTop: '2.5rem' },
    });
  };

  const onAcceptCancelUpdateEpd = async () => {
    try {
      setStatus(ProcessStatus.Fetching);
      await cancelUpdateEpdMutation.mutateAsync();
      setStatus(ProcessStatus.Success);
      toaster({
        severity: 'success',
        summary: 'Success',
        details: t('messages.epdCancelled') as string,
      });
    } catch (error) {
      setStatus(ProcessStatus.Error);
      toaster({
        severity: 'error',
        summary: 'Error',
        details: 'EPD update cancellation has not been accomplished due to unexpected error.',
      });
    }
  };

  const onCancelUpdateEpd = () =>
    confirmAlert({
      caption: `Are you sure you want to cancel the ${
        epdGeneralInformation?.fullIdentificationNumber ?? epdGeneralInformation?.shortIdentificationNumber
      } ${epdGeneralInformation?.declarationName} update?`,
      description: <div>All updated changes will be lost.</div>,
      acceptLabel: t('Submit'),
      rejectLabel: t('Cancel'),
      onConfirm: onAcceptCancelUpdateEpd,
    });

  const onAcceptDeleteEpd = async () => {
    try {
      setStatus(ProcessStatus.Fetching);
      await EpdStateService.delete(epdGeneralInformation.id);
      setStatus(ProcessStatus.Success);
      toaster({
        severity: 'success',
        summary: 'Success',
        details: t('messages.epdDeleted', {
          epdNumber: epdGeneralInformation?.fullIdentificationNumber ?? epdGeneralInformation?.shortIdentificationNumber,
        }) as string,
      });
      history.push(EpdLinks.dashboard());
    } catch (error) {
      setStatus(ProcessStatus.Error);
      toaster({ severity: 'error', summary: 'Error', details: 'EPD has not been deleted due to unexpected error.' });
      console.error(error);
    }
  };

  const onDeleteEpd = () =>
    confirmDelete({
      caption: `Are you sure you want to delete the EPD "${
        epdGeneralInformation?.fullIdentificationNumber ?? epdGeneralInformation?.shortIdentificationNumber
      } ${epdGeneralInformation?.declarationName}"?`,
      description: <div>The EPD data will be lost and you will not be able to restore it.</div>,
      acceptLabel: 'Delete',
      rejectLabel: 'Cancel',
      onConfirm: onAcceptDeleteEpd,
    });

  const onStartVerification = async () => {
    try {
      setStatus(ProcessStatus.Fetching);
      await startVerificationMutation.mutateAsync();
      setStatus(ProcessStatus.Success);
      toaster({
        severity: 'success',
        summary: 'Success',
        details: t('messages.startVerification') as string,
      });
    } catch (error) {
      setStatus(ProcessStatus.Error);
      toaster({
        severity: 'error',
        summary: 'Error',
        details: 'EPD verification has not been started due to unexpected error.',
      });
    }
  };

  const onCancelVerification = async () => {
    try {
      setStatus(ProcessStatus.Fetching);
      await cancelVerificationMutation.mutateAsync();
      setStatus(ProcessStatus.Success);
      toaster({
        severity: 'success',
        summary: 'Success',
        details: t('messages.cancelVerification') as string,
      });
    } catch (error) {
      setStatus(ProcessStatus.Error);
      toaster({
        severity: 'error',
        summary: 'Error',
        details: 'EPD verification has not been canceled due to unexpected error.',
      });
    }
  };

  const onRegister = async () => {
    try {
      setStatus(ProcessStatus.Fetching);
      await registerEpdMutation.mutateAsync();
      setStatus(ProcessStatus.Success);
      toaster({
        severity: 'success',
        summary: 'Success',
        details: (
          <div>
            <b>{'Thank you for registering your EPD with the International EPD System!'}</b>
            <p style={{ marginBottom: 0 }}>
              {
                'We will attend to it as soon as possible. We aim to publish the EPD within 1-3 days. If you have any questions feel free to contact us in the Secretariat at support@environdec.com.'
              }
            </p>
            <p style={{ marginBottom: 0 }}>
              {
                'If any information is missing or if any irregularities are recognized in the submitted material the support team will contact you.'
              }
            </p>
          </div>
        ) as ReactNode,
      });
    } catch (error) {
      setStatus(ProcessStatus.Error);
      toaster({
        severity: 'error',
        summary: 'Error',
        details: 'EPD has not been registered due to unexpected error.',
      });
    }
  };

  const onConfirmDeregisterEpd = async () => {
    try {
      setStatus(ProcessStatus.Fetching);
      await deregisterEpdMutation.mutateAsync();
      setStatus(ProcessStatus.Success);
      history.go(0);
      toaster({
        severity: 'success',
        summary: 'Success',
        details: t('messages.epdDeregister', {
          epdNumber: epdGeneralInformation?.fullIdentificationNumber ?? epdGeneralInformation?.shortIdentificationNumber,
        }) as string,
      });
    } catch (error) {
      setStatus(ProcessStatus.Error);
      toaster({
        severity: 'error',
        summary: 'Error',
        details: 'EPD has not been deregistered due to unexpected error.',
      });
    }
  };

  const onDeregisterEpd = () =>
    confirmDelete({
      caption: t('confirmModal.deregisterEPD'),
      acceptLabel: t('deregister'),
      rejectLabel: t('cancel'),
      onConfirm: onConfirmDeregisterEpd,
    });

  const onCancelEpdDeregistration = async () => {
    try {
      setStatus(ProcessStatus.Fetching);
      await cancelEpdDeregistrationMutation.mutateAsync();
      setStatus(ProcessStatus.Success);
      toaster({
        severity: 'success',
        summary: 'Success',
        details: t('messages.cancelDeregistration') as string,
      });
    } catch (error) {
      setStatus(ProcessStatus.Error);
      toaster({
        severity: 'error',
        summary: 'Error',
        details: 'Cancelation of EPD deregistration has not been accomplished due to unexpected error.',
      });
    }
  };

  const onStartUpdatingEpd = async () => {
    setIsUpdateDialogOpened(true);
  };

  const startUpdatingHandler = async () => {
    try {
      setStatus(ProcessStatus.Fetching);

      if (epdUpdateType === 'update') {
        await unlockEpdMutation.mutateAsync();
      } else if (epdUpdateType === 'editorialUpdate') {
        await editorialUpdatingMutation.mutateAsync({ epdId: epdGeneralInformation.id });
      }
      setStatus(ProcessStatus.Success);
    } catch (error) {
      setStatus(ProcessStatus.Error);
      toaster({
        severity: 'error',
        summary: 'Error',
        details: 'Updating of EPD has not been started due to unexpected error.',
      });
    }
  };

  const registerEditorialHandler = async () => {
    try {
      setStatus(ProcessStatus.Fetching);
      await registerEditorialMutation.mutateAsync({ epdId: epdGeneralInformation.id });

      setStatus(ProcessStatus.Success);
    } catch (error) {
      setStatus(ProcessStatus.Error);
      toaster({
        severity: 'error',
        summary: 'Error',
        details: 'Updating of EPD has not been started due to unexpected error.',
      });
    }
  };
  const onApproveEpd = async () => {
    try {
      setStatus(ProcessStatus.Fetching);
      const approveWithoutEmailNotification = false;
      const approveModel = new FormData();
      approveModel.append('WithoutEmailNotification', approveWithoutEmailNotification.toString());
      await approveVerificationMutation.mutateAsync(approveModel);
      setStatus(ProcessStatus.Success);
      toaster({
        severity: 'success',
        summary: 'Success',
        details: t('messages.epdApproved', {
          epdNumber: epdGeneralInformation?.fullIdentificationNumber ?? epdGeneralInformation?.shortIdentificationNumber,
        }) as string,
      });
    } catch (error) {
      setStatus(ProcessStatus.Error);
      toaster({
        severity: 'error',
        summary: 'Error',
        details: 'Approval of EPD has not been accomplished due to unexpected error.',
      });
    }
  };

  const onLicenseeAdminApproveEpd = async () => {
    try {
      setStatus(ProcessStatus.Fetching);
      await licenseeAdminVerificationApproveMutation.mutateAsync();
      setStatus(ProcessStatus.Success);
      toaster({
        severity: 'success',
        summary: 'Success',
        details: t('messages.approveEpdChanges') as string,
      });
    } catch (error) {
      setStatus(ProcessStatus.Error);
      toaster({
        severity: 'error',
        summary: 'Error',
        details: 'Approval of EPD by licensee admin has not been accomplished due to unexpected error.',
      });
    }
  };

  const onPublishEpd = async () => {
    try {
      setStatus(ProcessStatus.Fetching);
      await publishEpdMutation.mutateAsync(epdGeneralInformation.epdVersionId);
      setStatus(ProcessStatus.Success);
      toaster({
        severity: 'success',
        summary: 'Success',
        details: t('messages.epdPublished', {
          epdNumber: epdGeneralInformation?.fullIdentificationNumber ?? epdGeneralInformation?.shortIdentificationNumber,
        }) as string,
      });
    } catch (error) {
      setStatus(ProcessStatus.Error);
      toaster({
        severity: 'error',
        summary: 'Error',
        details: 'Publication of EPD has not been accomplished due to unexpected error.',
      });
    }
  };

  const onUnpublishEpd = async () => {
    try {
      setStatus(ProcessStatus.Fetching);
      await unpublishEpdMutation.mutateAsync();
      setStatus(ProcessStatus.Success);
      toaster({
        severity: 'success',
        summary: 'Success',
        details: t('messages.epdUnpublished', { epdNumber: epdGeneralInformation?.fullIdentificationNumber }) as string,
      });
    } catch (error) {
      setStatus(ProcessStatus.Error);
      toaster({
        severity: 'error',
        summary: 'Error',
        details: 'Unpublish of EPD has not been accomplished due to unexpected error.',
      });
    }
  };

  const onDeclineEpd = async () => {
    try {
      setStatus(ProcessStatus.Fetching);
      if (epdQuery.data?.versionState == 'Registered editorial') {
        await cancelRegisterEditorialMutation.mutateAsync({ epdId: epdGeneralInformation.id });
      } else {
        await needsVerificationMutation.mutateAsync();
      }
      setStatus(ProcessStatus.Success);
      toaster({
        severity: 'success',
        summary: 'Success',
        details: t('messages.declineEpd') as string,
      });
    } catch (error) {
      setStatus(ProcessStatus.Error);
      toaster({
        severity: 'error',
        summary: 'Error',
        details: 'Decline of EPD has not been accomplished due to unexpected error.',
      });
    }
  };

  const onLicenseeAdminDeclineEpd = async () => {
    try {
      setStatus(ProcessStatus.Fetching);
      await licenseeAdminVerificationDeclineMutation.mutateAsync();
      setStatus(ProcessStatus.Success);
      toaster({
        severity: 'success',
        summary: 'Success',
        details: t('messages.declineEpd') as string,
      });
    } catch (error) {
      setStatus(ProcessStatus.Error);
      toaster({
        severity: 'error',
        summary: 'Error',
        details: 'Decline of EPD by licensee admin has not been accomplished due to unexpected error.',
      });
    }
  };

  const onApproveEditorialUpdate = async () => {
    try {
      setStatus(ProcessStatus.Fetching);
      await approveEditorialMutation.mutateAsync();
      setStatus(ProcessStatus.Success);
      toaster({
        severity: 'success',
        summary: 'Success',
        details: t('messages.approveEpdChanges') as string,
      });
    } catch (error) {
      setStatus(ProcessStatus.Error);
      toaster({
        severity: 'error',
        summary: 'Error',
        details: 'EPD editorial update has not been approved due to unexpected error.',
      });
    }
  };

  const actionMenuItems: MenuItem[] = [
    {
      label: 'Unpublish EPD',
      command: onUnpublishEpd,
      visible: actionsStates?.unpublish !== ActionState.Hidden,
    },
    {
      label: 'Deregister EPD',
      command: onDeregisterEpd,
      visible: actionsStates?.deregister !== ActionState.Hidden,
    },
    {
      label: 'Download EPD',
      url: EpdLinks.epdVersionDocumentPreview(epdGeneralInformation.epdVersionId),
      target: '_blank',
    },
  ];

  return (
    <Container>
      <ConfirmDialog />
      {status === ProcessStatus.Fetching && <OverlaySpinner />}
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <WizardHeaderGrid>
          <TitleAndBack
            title={epdGeneralInformation?.declarationName ?? 'New EPD'}
            backTo={'Back to the dashboard'}
            onBack={onGoBack}
          />
          <WizardHeaderButtonBox>
            <CompilerActionSecondaryButton
              actionState={actionsStates?.cancelUpdate}
              label="Cancel update"
              onClick={onCancelUpdateEpd}
              outlined
              type="reset"
            />
            <CompilerActionSecondaryButton
              actionState={actionsStates?.deleteDraft}
              label="Delete EPD"
              onClick={onDeleteEpd}
              outlined
              type="reset"
            />
            <ActionPrimaryButton
              style={{ marginLeft: '16px' }}
              label="Start verification"
              onClick={onStartVerification}
              actionState={actionsStates?.startVerification}
            />
            <ActionPrimaryButton
              style={{ marginLeft: '16px' }}
              label="Cancel verification"
              onClick={onCancelVerification}
              actionState={actionsStates?.cancelVerification}
            />
            <ActionPrimaryButton
              style={{ marginLeft: '16px' }}
              label="Register EPD"
              onClick={onRegister}
              actionState={registerEpdState}
            />
            <ActionPrimaryButton
              style={{ marginLeft: '16px' }}
              label="Cancel Deregistration"
              onClick={onCancelEpdDeregistration}
              actionState={actionsStates?.cancelDeregistration}
            />
            <ActionPrimaryButton
              style={{ marginLeft: '16px' }}
              label="Update EPD"
              onClick={onStartUpdatingEpd}
              actionState={actionsStates?.unlock}
            />{' '}
            <ActionPrimaryButton
              style={{ marginLeft: '16px' }}
              label="Register editorial"
              onClick={registerEditorialHandler}
              actionState={actionsStates?.registerEditorial}
            />
            <ActionPrimaryButton
              style={{ marginLeft: '16px' }}
              label="Approve EPD"
              onClick={onApproveEpd}
              actionState={actionsStates?.approve}
            />
            <ActionPrimaryButton
              style={{ marginLeft: '16px' }}
              label="Publish EPD"
              onClick={onPublishEpd}
              actionState={actionsStates?.publish}
            />
            <ActionPrimaryButton
              style={{ marginLeft: '16px' }}
              label="Decline EPD"
              onClick={onDeclineEpd}
              actionState={actionsStates?.needsVerification}
            />
            <ActionPrimaryButton
              style={{ marginLeft: '16px' }}
              label="Approve"
              onClick={onLicenseeAdminApproveEpd}
              actionState={actionsStates?.licenseeAdminVerification}
            />
            <ActionPrimaryButton
              style={{ marginLeft: '16px' }}
              label="Decline"
              onClick={onLicenseeAdminDeclineEpd}
              actionState={actionsStates?.licenseeAdminVerification}
            />
            <ActionPrimaryButton
              style={{ marginLeft: '16px' }}
              label="Approve updated EPD"
              onClick={onApproveEditorialUpdate}
              actionState={actionsStates?.approveEditorial}
            />
            <ActionImageGreenButton
              style={{ marginLeft: '16px' }}
              onClick={(e: any) => actionMenuRef.current?.toggle(e)}
              actionState={ActionState.Enabled}
            >
              <img alt="menu" src={menuIcon} />
            </ActionImageGreenButton>
            <Menu ref={actionMenuRef} model={actionMenuItems} popupAlignment={'right'} popup />
            <ConfirmDialog
              header={<>Update EPD</>}
              closable={false}
              visible={isUpdateDialogOpened}
              style={{ width: '35rem' }}
              onHide={() => setIsUpdateDialogOpened(false)}
              acceptLabel="Submit"
              rejectLabel="Cancel"
              accept={startUpdatingHandler}
              className="custom-dialog"
              message={
                <StyledContentContainer>
                  <HelpBox>
                    <HeaderText>This EPD was approved by the verifier.</HeaderText>
                    <div>
                      You are allowed to make only the editorial changes to the verified EPD, such as correction of spelling
                      errors without verification.
                    </div>
                    <div>
                      If the EPD information needs an update, either the product or LCA, you shall verify the EPD again.
                    </div>
                  </HelpBox>
                  <CheckBoxPanel>
                    <RadioButtonStyled
                      inputId={'updateMode_update'}
                      value="update"
                      name="updateMode"
                      checked={epdUpdateType === 'update'}
                      onChange={(e) => {
                        setEpdUpdateType(e.value);
                      }}
                    />
                    <label htmlFor={'updateMode_update'} style={{ textTransform: 'none', marginLeft: '0.5rem' }}>
                      The EPD needs a verification, I will update the EPD information.
                    </label>
                  </CheckBoxPanel>
                  <CheckBoxPanel style={{ marginTop: '0.5rem' }}>
                    <RadioButtonStyled
                      inputId={'updateMode_editorialUpdate'}
                      value="editorialUpdate"
                      name="updateMode"
                      checked={epdUpdateType === 'editorialUpdate'}
                      onChange={(e: RadioButtonChangeEvent) => {
                        setEpdUpdateType(e.value);
                      }}
                    />
                    <label htmlFor={'updateMode_editorialUpdate'} style={{ textTransform: 'none', marginLeft: '0.5rem' }}>
                      The EPD verification is not needed, I will make the editorial update.
                    </label>
                  </CheckBoxPanel>
                </StyledContentContainer>
              }
            />
            {/* ToDo: implement dialog on register after editorial update */}
            {/* <Dialog
              className="custom-dialog"
              header={<>Register editorial update</>}
              footer={registerUpdateFooter}
              closable={false}
              visible={isRegisterUpdateDialogOpened}
              onHide={() => setIsRegisterUpdateDialogOpened(false)}
            >
              <DialogContainer>
                <InformationContainer>
                  {
                    'Please give a short description of the editorial changes, it will be reviewed before registering the EPD update.'
                  }
                </InformationContainer>
                <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                  <SmartLabel
                    label="Changes reason"
                    required
                    boxStyle={{ marginTop: 20 }}
                    hasValue={!!editorialUpdateNotes}
                  />
                  <StyledTextArea
                    placeholder="Type here"
                    onChange={(e: any) => setEditorialUpdateNotes(e.target.value)}
                    disabled={false}
                  />
                </div>
              </DialogContainer>
            </Dialog> */}
          </WizardHeaderButtonBox>
        </WizardHeaderGrid>

        <WizardContentsGrid>
          <LeftPanel>
            <StatusBox
              status={actionsStates?.status}
              statusMessage={actionsStates?.statusMessage}
              requiredActionMessage={actionsStates?.requiredActionMessage}
            />

            {/* TODO: extract to component */}
            <StepsPanelContainer>
              {steps.map((step, index) => (
                <React.Fragment key={index}>
                  <StepPanel
                    key={index}
                    onClick={() => setActiveStepIndex(index + 1)}
                    label={step.label}
                    name={step.name}
                    description={step.description}
                    isSelected={index + 1 === activeStepIndex}
                    isValid={step.isValid(serverValidationResult?.errors)}
                  />
                </React.Fragment>
              ))}
            </StepsPanelContainer>
          </LeftPanel>
          <MainViewFull>
            <React.Fragment key={epdGeneralInformation?.declarationName}>
              {steps[activeStepIndex - 1].render()}
            </React.Fragment>
          </MainViewFull>
        </WizardContentsGrid>
      </ErrorBoundary>
    </Container>
  );
};

const StyledContentContainer = styled.div`
  padding: 1rem 2rem 2rem 2rem;
  ${(props) => props.theme.fonts.textSmall}
`;

const HeaderText = styled.div`
  ${(props) => props.theme.typography.bodyBody14Semibold}
`;

const StepsPanelContainer = styled.div`
  padding: 0;
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.04);
`;

const WizardContentsGrid = styled.div`
  display: grid;
  width: 100%;
  margin-bottom: 40px;
  align-items: start;
  grid-template-columns: 1fr;
  grid-column-gap: 2rem;
  border: 2 solid green;

  ${(props) => props.theme.media.tablet} {
    grid-template-columns: 3fr 10fr;
  }
`;

const MainViewFull = styled.div`
  flex: 0 0 100%;

  ${ErrorTextBox} {
    word-break: break-word;
  }
`;

const LeftPanel = styled.div``;

const StyledTextArea = styled.textarea`
  padding: 0.75rem 1rem;
  border: 1px solid ${(props) => props.theme.colors.elementsInputBorder};
  border-radius: 2px;
  margin: 0;
  resize: vertical;
  min-height: 67px;
  color: ${(props) => (props.disabled ? props.theme.colors.primaryBlack70 : props.theme.colors.primaryBlack)};

  &:focus {
    border-color: ${(props) => props.theme.colors.primaryOrange};
  }

  &:focus-visible {
    outline: 2px solid ${(props) => props.theme.colors.primaryOrange};
  }

  &:hover {
    border-color: ${(props) => props.theme.colors.primaryOrange};
  }

  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export default withGreyBackground(EpdCompiler);

import React from 'react';
import { VerifierModel } from 'services/EpdClient';
import { EPDVerifierModel } from 'types/types';
import CertificationBodyCard from './CertificationBodyCard';

interface CertificationBodiesListProps {
  certificationBody?: EPDVerifierModel | undefined;
  bodies: VerifierModel[];
  selectedVerifier: VerifierModel | undefined;
  onSelectVerifier: any;
  onRequestCollaboration: any;
  onAddVerifier: any;
  buttonsAreDisabled?: boolean;
}

const CertificationBodiesList: React.FunctionComponent<CertificationBodiesListProps> = ({
  certificationBody,
  bodies,
  selectedVerifier,
  onSelectVerifier,
  onRequestCollaboration,
  onAddVerifier,
  buttonsAreDisabled,
}) => (
  <div>
    {bodies.map((body) => (
      <CertificationBodyCard
        key={body.id}
        data={body}
        onSelectBody={() => onSelectVerifier((prev: VerifierModel | undefined) => (prev?.id === body.id ? undefined : body))}
        onRequestCollaboration={onRequestCollaboration}
        onAddBody={onAddVerifier}
        expanded={selectedVerifier?.id === body.id}
        buttonsAreDisabled={
          body.id === certificationBody?.companyId ||
          body.information === certificationBody?.companyName ||
          buttonsAreDisabled
        }
      />
    ))}
  </div>
);

export default CertificationBodiesList;

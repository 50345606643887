import LcaResultsGridAdditionalConstructionPdf from 'components/v2/compiler/grids/lca-results/LcaResultsGridAdditionalConstructionPdf';
import LcaResultsGridAdditionalMandatoryPdf from 'components/v2/compiler/grids/lca-results/LcaResultsGridAdditionalMandatoryPdf';
import LcaResultsGridCoreConstructionPdf from 'components/v2/compiler/grids/lca-results/LcaResultsGridCoreConstructionPdf';
import LcaResultsGridOutputFlowConstructionPdf from 'components/v2/compiler/grids/lca-results/LcaResultsGridOutputFlowConstructionPdf';
import LcaResultsGridResourceConstructionPdf from 'components/v2/compiler/grids/lca-results/LcaResultsGridResourceConstructionPdf';
import LcaResultsGridWasteConstructionPdf from 'components/v2/compiler/grids/lca-results/LcaResultsGridWasteConstructionPdf';
import React from 'react';
import { INDICATOR_GROUP_CODES } from 'services/api/constants';

import { PDF_H1, PDF_H2, PDF_PAGE } from './Styles.styled';

const LCAResultsPage: React.FunctionComponent<{
  e: any;
}> = ({ e }) => {
  return (
    <PDF_PAGE className="landscape">
      <PDF_H1>Results of the environmental performance indicators</PDF_H1>
      <br />
      <PDF_H2>Mandatory environmental performance indicators according to EN 15804</PDF_H2>
      <br />
      <LcaResultsGridCoreConstructionPdf
        rows={e?.filter((x: any) => x.indicatorGroupCode === INDICATOR_GROUP_CODES.CORE15804)}
      />
      <PDF_H2 style={{ pageBreakBefore: 'always' }}>Additiontal mandatory environmental performance indicators</PDF_H2>
      <LcaResultsGridAdditionalMandatoryPdf
        rows={e?.filter((x: any) => x.indicatorGroupCode === INDICATOR_GROUP_CODES.ADDITIONALMANDATORY)}
      />
      <br />
      <PDF_H2>Additional voluntary environmental performance indicators according to EN 15804</PDF_H2>
      <LcaResultsGridAdditionalConstructionPdf
        rows={e?.filter((x: any) => x.indicatorGroupCode === INDICATOR_GROUP_CODES.ADDITIONALVOLUNTARY15804)}
      />
      <PDF_H2 style={{ pageBreakBefore: 'always' }}>Resource use indicators according to EN 15804</PDF_H2>
      <LcaResultsGridResourceConstructionPdf
        rows={e?.filter((x: any) => x.indicatorGroupCode === INDICATOR_GROUP_CODES.RESOURCE15804)}
      />
      <PDF_H2 style={{ pageBreakBefore: 'always' }}>WASTE INDICATORS ACCORDING TO EN 15804</PDF_H2>
      <LcaResultsGridWasteConstructionPdf
        rows={e?.filter((x: any) => x.indicatorGroupCode === INDICATOR_GROUP_CODES.WASTE15804)}
      />
      <br />
      <PDF_H2>OUTPUT FLOW INDICATORS ACCORDING TO EN 15804</PDF_H2>
      <LcaResultsGridOutputFlowConstructionPdf
        rows={e?.filter((x: any) => x.indicatorGroupCode === INDICATOR_GROUP_CODES.OUTPUTFLOW15804)}
      />
    </PDF_PAGE>
  );
};

export default LCAResultsPage;

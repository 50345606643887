import { DataTableValueArray } from 'primereact/datatable';

import LcaResultsGrid from './LcaResultsGrid';
import { LcaResultsGridColumnsNoCategory } from './LcaResultsGridColumns';

type TProps = {
  rows: DataTableValueArray | undefined;
};

const LcaResultsGridOutputFlowConstruction = ({ rows }: TProps) => {
  const notes = [
    {
      note: 'Acronyms',
      description:
        'CRU = Components for re-use; MFR = Materials for recycling; MER = Materials for energy recovery; EEE = Exported electrical energy; EET = Exported thermal energy.',
    },
  ];

  return <LcaResultsGrid value={rows} columns={LcaResultsGridColumnsNoCategory} notes={notes} />;
};

export default LcaResultsGridOutputFlowConstruction;

import { LabelHTMLAttributes } from 'react';

import { LabelGroup } from '../form/styled';
import { StatusAttentionIcon } from '../icons';
import TooltipErrorIcon from '../icons/TooltipErrorIcon';
import TooltipHelpIcon from '../icons/TooltipHelpIcon';

export type TSmartLabelProps = {
  label: string | undefined;
  tooltip?: string | undefined;
  tooltipPosition?: any | undefined;
  labelStyle?: any;
  boxStyle?: any;
  required?: boolean;
  hasValue?: boolean;
  htmlFor?: any;
  error?: boolean | string | JSX.Element;
};

const SmartLabel = ({
  label,
  tooltip,
  tooltipPosition,
  required,
  hasValue,
  labelStyle,
  boxStyle,
  htmlFor,
  error,
}: TSmartLabelProps) => {
  return (
    <LabelGroup style={boxStyle}>
      <label htmlFor={htmlFor} style={labelStyle}>
        {label}
      </label>
      {tooltip && <TooltipHelpIcon content={tooltip} position={tooltipPosition} />}
      {(error || (required && !hasValue)) && <TooltipErrorIcon content={error || 'Required'} />}
    </LabelGroup>
  );
};

export default SmartLabel;
